import { Title, SubTitle } from '../pages/Title';
import { ContentDescription } from '../trainings/';
// import logoHead from '../pages/img/fachtage.jpg';
import Symposiums from './symposiums';
import { Paragraph } from '../core';
import { useEffect } from 'react';
import HeadLogo from "../core/headLogo";
import myLogo from "../../images/fachtage.png";

export function SymposiumsPage() {
	
	useEffect(() => {
		document.title = 'Fachtage | Fortbildung Berlin';
	}, []);
	
	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<HeadLogo
					logo={myLogo}
					logoText={'Bild ein Konferenz'}
					text="Fachtage in Berlin"
					colorBackground="#F1EEED"
					color="#a80000"
					height={400}
					posLogo={-30}
					posText={+30}
					heightLogo={100}
					textSize={5}
					fontWeight={900}
				/>
				{/* <Image src={logoHead} alt="Logo im Kopf der Seite" fluid /> */}
			</header>
			<main>
				<SubTitle title="Fachtage" align="center" />
				<ContentDescription>
					<Paragraph>
						Fachtage sind ganztägige Fortbildungen zu einem speziellen Themenkomplex. Sie dienen dem
						fachlichen Austausch und der Venetzung der Kollegen/-innen.
					</Paragraph>
					<Paragraph>
						Beachten Sie vor Anmeldung die angegebene Zielgruppe des jeweiligen Fachtags.{' '}
					</Paragraph>
					<Paragraph>
						Melden Sie sich bei einem Fachtag sowohl für die Einführungsveranstaltung als auch für
						genau einen Workshop in den geplanten Workshop-Phasen an.
					</Paragraph>
				</ContentDescription>
				<div style={{ padding: '1rem' }}>
					<Symposiums />
				</div>
			</main>
		</>
	);
}
