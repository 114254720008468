import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import useNotification from '../../../hooks/useNotification';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { BsBookmarkDashFill } from 'react-icons/bs';
import {calcEventDuration, getErrorMessage, getFormattedDateString} from '../../../common';
import { Col, Container, Row } from 'react-bootstrap';
import {BookmarkItemHeader} from "./BookmarkItemHeader";
import EnrollmentService from "../../../services/enrollments.service";
import {useMutation, useQueryClient} from "react-query";


const showDate = (dateString) => {
	if (dateString !== '0000-00-00' && dateString !== undefined) {
		const weekday = ['So., ', 'Mo., ', 'Di., ', 'Mi., ', 'Do., ', 'Fr., ', 'Sa., '];
		const date = new Date(dateString);
		return weekday[date.getDay()] + getFormattedDateString(date);
	}
	return ' (kein Fortbildungsdatum angegeben!) ';
};

const getStartTime = (events) => {
	if (events.length !== 0 && events.length >= 28) return events.split(';')[0].split(',')[2];
	else return null;
};

const getDateTime = (date, events) => {
	return (
		<>
			{showDate(date)}
			{getStartTime(events) ? ' um ' + getStartTime(events) + ' Uhr ' : ''}
		</>
	);
};

const getFormattedNextDate = (date) => {
	return (
		<>
			{showDate(date.date)}
			{' um ' + date.timeBegin + ' Uhr '}
		</>
	);
};

export const BookmarkItem = (props) => {
	const { setInfo, setError } = useNotification();
	const location = useLocation();
	const queryClient = useQueryClient();
	const { mutate: delBookmark } = useMutation(
		(data) => EnrollmentService.delBookmark(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["home"]);
				queryClient.invalidateQueries(["bookmarks"]);
			},
			onError: (error) => setError(getErrorMessage(error))
		}
	)

	const handleClipboard = () => {
		navigator.clipboard
			.writeText(`${window.location.origin}/training/${props.bookmark.eventId}`)
			.then(
				function () {
					setInfo(
						'Der Link zu dieser Fortbildung wurde in Ihre Zwischenablage kopiert. Sie können den Link verwenden, um andere über diese Fortbildung zu informieren.'
					);
				},
				function () {
					setError('Ihr Browser unterstützt leider das Kopieren in die Zwischenablage nicht.');
				}
			);
	};


	return (
		<Container
			fluid
			as="li"
			className="px-0"
			style={{
				borderTop: '0.1mm solid #aaaaaa',
				paddingTop: '1rem',
				paddingBottom: '1rem'
			}}
		>
			<Row>
				<Col xs="auto" style={{ width: '2rem' }}>
					{props.count}.
				</Col>
				<Col as={BookmarkItemHeader}>
					<Link
						to={`/training/${props.bookmark.eventId}`}
						state={{ backgroundLocation: location }}
					>
						{props.bookmark.title + ' [' + props.bookmark.eventNumber + ']'}
					</Link>
				</Col>
				<Col xs="auto">
					<BsBookmarkDashFill
						style={{ cursor: 'pointer', color: 'green' }}
						onClick={() => delBookmark(props.bookmark.eventId)}
						className="mx-1"
						title="Bookmark für diese Fortbildung entfernen"
						tabIndex="1"
					></BsBookmarkDashFill>
					<HiOutlineClipboardCopy
						style={{ cursor: 'pointer', marginRight: '0.75em' }}
						onClick={handleClipboard}
						title="Den Link zur Fortbildung kopieren."
					></HiOutlineClipboardCopy>
				</Col>
			</Row>
			<Row>
				<Col style={{ marginLeft: '2rem' }}>
					{props.bookmark.nextDate
						? getFormattedNextDate(props.bookmark.nextDate)
						: getDateTime(props.bookmark.firstDate, props.bookmark.events)}
					<span style={{ whiteSpace: 'nowrap' }}>
						( &sum; = {calcEventDuration(props.bookmark.events)} Min. )
					</span>
				</Col>
			</Row>
		</Container>
	);
};
