import React, {useEffect} from 'react';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import './EvaluationForm.css';
import {useQuery, useMutation, useQueryClient} from "react-query";
import {getErrorMessage} from "../../common";
import EvaluationService from "../../services/evaluation.service";
import useNotification from "../../hooks/useNotification";
import useUser from "../../hooks/useUser";

const EvaluationForm = (props) => {
    const navigate= useNavigate();
    const queryClient = useQueryClient();
    const { setInfo, setError } = useNotification();
    const { user } = useUser();
    const { isLoading, error, data: formData } = useQuery('Quest', EvaluationService.getFormData);

    const { mutate: saveFeedback } = useMutation(
        (data) => EvaluationService.saveFeedback(data),
        {
            onSuccess: (data) => {
                switch (data) {
                    case -6: setError('Die Evaluation konnte nicht vermerkt werden, es wurden keine Daten gespeichert.'); break;
                    case -5: setError('Eine Evaluation ist nicht mehr möglich und wurde nicht gespeichert.'); break;
                    case -4: setError('Ihre Evaluation wurde schon abgegeben!'); break;
                    case -3: setInfo( 'Sie haben ein Formular ohne Bewertungen zu den einzelnen Fragen abgegeben.'); break;
                    case -2: setError('Der Teilnehmer oder die Fortbildung ist nicht vorhanden.'); break;
                    case -1: setError('Es wurde kein Evaluation-Formular übermittelt!'); break;
                    default:
                        setInfo(`Ihr Feedback zur Fortbildung — ${props.title} — wurde dankend angenommen. Sie haben die Fortbildung insgesamt mit ${data} von 100 Punkten bewertet.`);
                        break;
                }
                queryClient.invalidateQueries(['enrollments', user.username]);
                navigate("/profile/enrollments");
            },
            onError: (error) => setError(getErrorMessage(error))
        }
    );

    useEffect(() => {
        document.title = 'Feedback | Fortbildung Berlin';
        window.scrollTo(0, 0);
    }, []);


    if (isLoading)  return 'Daten werden geladen...';
    if (error)      return 'Beim Laden ist der folgende Fehler aufgetreten: ' + getErrorMessage(error);

    const validationSchema = Yup.object({
        ...Object.fromEntries(
            Object.values(formData).flatMap(({ questions }) =>
                questions.map(({ id }) => [String(id), Yup.string().required('Diese Auswahl ist erforderlich!')])
            )
        ),
        textField: Yup.string().matches(/^(?!.*?\b(SELECT|DELETE|INSERT|DROP|WHERE|FROM|--|=)\b).*$/i, 'Ungültige Zeichen oder Wörter im Textfeld!'),
    });

    const RadioInput = ({ label, value, name }) => {
        const { setFieldValue, values } = useFormikContext();

        return (
            <div className="radio-container">
                <label>{label}</label>
                <div className={`radio ${values[name] === value ? 'selected' : ''}`}
                     onClick={() => setFieldValue(name, value)}>
                </div>
            </div>
        );
    };


    return (
        <>
            <h1 style={{fontSize: '1.7em', marginBottom: '1em', color: 'black', textAlign: 'center'}}>{props.title}</h1>
            <div className="form-container">
                <Formik
                    initialValues={{
                        ...Object.fromEntries(
                            Object.values(formData).flatMap(({questions}) =>
                                questions.map(({id}) => [String(id), ''])
                            )
                        ),
                        textField: '',
                        eventId: props.eventId || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit= { async (values)=> {
                            const res = confirm(`Sie können Ihr Feedback zur Fortbildung \n ${props.title} \nnun abgeben.\n\nVielen Dank!`);
                            if(res) saveFeedback(values);
                        }
                    }
                >
                    <Form>
                        {Object.values(formData).map(({headline, questions}) => (
                            <div className="section" key={headline} >
                                <h2 style={{fontSize: '1.5em', color: 'black', borderBottom: '1px solid black'}}>
                                    {headline}
                                </h2>
                                {questions.map(({id, question}) => (
                                    <div className="question" key={id} style={{ borderBottom: '1px solid lightgray' }}>
                                        <div className="label-container">
                                            <label>{question}:</label>
                                        </div>
                                        <div className="options">{
                                            [
                                                {value: 4, label: '++'},
                                                {value: 3, label: '+'},
                                                {value: 2, label: '–'},
                                                {value: 1, label: '– –'},
                                                {value: 0, label: 'Keine Aussage'}
                                            ].map(({value, label }) => (
                                                <RadioInput key={value} name={String(id)} value={value} label={label}/>
                                            ))}
                                            <ErrorMessage className="error-message" name={String(id)} component="div"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <div className="textarea">
                            <h2 style={{fontSize: '1.5em', color: 'black'}}>Raum für Ihre Anmerkungen:</h2>
                            <Field as="textarea" name="textField"/>
                            <ErrorMessage className="error-message" name="textField"/>
                        </div>
                        <Field type="hidden" name="eventId" />
                        <button type="submit">Feedback abgeben</button>
                    </Form>
                </Formik>
            </div>
        </>
    )
};

export default EvaluationForm;