import React, {useEffect} from "react";
import EvaluationForm from "./evaluationForm";
import { useParams } from 'react-router-dom';
import {Title} from "../pages/Title";
import logoHead from "../pages/img/feedback.png";


const TrainingFeedback = () =>  {
    const { eventId, title } = useParams();
    useEffect(() => {
        document.title = 'Login | Fortbildung Berlin';
    }, []);

    return (
        <>
            <header>
                <Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
                <div style={{textAlign: 'center', marginBottom: '2em'}}>
                    <img src={logoHead} alt="Klingelknopf für Feedback als Logo" style={{maxWidth: '45%', alignContent: 'center'}}/>
                </div>
            </header>

            <EvaluationForm eventId={eventId} title={title} />
        </>
    );
}

export default TrainingFeedback;
