import React from 'react';
import forge from 'node-forge';
import { useLocation } from 'react-router-dom';
import {Title} from "./Title";
import {Image} from "react-bootstrap";
import logoRight from "./img/checklist.png";


const confirmationKey = process.env.REACT_APP_CONFIRM_KEY.replace(/\\n/g, '\n');
const key = forge.pki.privateKeyFromPem(confirmationKey);

const errorMessage = (text) => {
    return (
        <>
            <header>
                <Title title="Nachweis von Fortbildungszeiten" />
            </header>
            <main style={{ paddingLeft: '2rem', paddingRight: '2rem', marginBottom: '3rem' }}>
                <div style={{textAlign: 'center'}}>
                    <h2>{text}</h2>
                </div>
            </main>
        </>
    )
}

const Confirmation = () => {
    const location= useLocation();

    // Get qrCode
    const encryptedMessage = new URLSearchParams(location.search).get("qr");
    if( encryptedMessage === "" ) return errorMessage("Keine QR-Code mitgeliefert!");

    // URL decodieren
    const urlDecoded = decodeURIComponent(encryptedMessage);
    const encryptedBytes = forge.util.decode64(urlDecoded);

    // try to decrypt
    let decryptedBytes;
    try {
        decryptedBytes = key.decrypt(encryptedBytes);
    } catch (err) {
        if (err.message.includes('Encrypted message length is invalid')) {
            errorMessage('Die Entschlüsselung ist fehlgeschlagen, weil die Länge der verschlüsselten Daten ungültig ist.');
        } else if (err.message.includes('invalid key')) {
            errorMessage('Die Entschlüsselung ist fehlgeschlagen, da der angegebene Schlüssel ungültig ist.');
        } else if (err.message.includes('Invalid encryption')) {
            errorMessage('Die Entschlüsselung ist fehlgeschlagen, da die verschlüsselten Daten ungültig sind.');
        } else {
            errorMessage("Ein unerwarteter Fehler ist aufgetreten: {err}");
        }
    }

    // in utf8 codierten Text umwandeln
    const decryptedMessage = forge.util.decodeUtf8(decodeURIComponent(decryptedBytes));

    let summary =  null;
    try {
        summary = JSON.parse(decryptedMessage);
    } catch (err) {
        if (err.message.includes("Unexpected identifier 'undefined'")) {
            errorMessage('Fehler beim Parsen von JSON. Wahrscheinlich wurde der QR-Code verändert.');
        } else if(summary === null) {
            return errorMessage("Wahrscheinlich wurde der QR-Code verändert!");
        } else {
            errorMessage("Ein unerwarteter Fehler ist aufgetreten: {err}");
        }
    }

    return (
        <>
            <header>
                <Title title="Nachweis von Fortbildungszeiten" />
            </header>
            <main style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                <div style={{textAlign: 'center'}}>
                    Vergleichen Sie mit den  Angaben auf dem Dokument:
                </div>
                <div style={{ float: 'left', width: '50%', textAlign: 'right'}}>
                    <Image src={logoRight} alt="Logo Checkliste auf der linken Seite" fluid />
                </div>
                <div style={{ float: 'left', width: '49%', marginTop: '1rem'}}>
                    <ul style={{listStyle: 'none', marginLeft: '-1rem'}}>
                        <li style={{paddingBottom: '0.75rem'}}>
                            Name: <br />{summary.name}
                        </li>
                        <li style={{paddingBottom: '0.75rem'}}>
                            Schuljahr: <br />{summary.schoolyear}
                        </li>
                        <li style={{paddingBottom: '0.75rem'}}>
                            Fortbildungszeiten: <br />{summary.totalTime} Min.
                        </li>
                        <li style={{paddingBottom: '0.75rem'}}>
                            Anzahl Fortbildungen: <br />{summary.enventNumber}
                        </li>
                        <li style={{paddingBottom: '0.75rem'}}>
                            Bis zum: {summary.date}
                        </li>
                    </ul>
                </div>
                <div style={{ clear: 'all', width: '1%'}}>&nbsp;</div>
            </main>
        </>

    );
};

export default Confirmation;
