import { useQuery, useIsFetching } from 'react-query';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { LoadingScreen } from './loadingScreen';
import { Field, useFormikContext } from 'formik';

/**
 * Base component for a select with multiple layers and backend-calls
 *
 * @author Janik Hilser
 * @version 0.0.1
 */
export function LayerSelector({ id, layers, ariaDescribedBy }) {
	// Low-level Bug: Müsste eigentlich auf die QueryKeys eingeschränkt werden,
	// die in dieser Komponente verwendet werden. Allerdings bekomme ich das gerade
	// nicht hin. Die Konsequenz ist, dass der Ladescreen hier angezeigt wird, obwohl
	// vielleicht gerade nicht Daten für diese Komponente geladen werden. Das halte ich
	// akutell für vernachlässigbar, weshalb ich das so bewusst erst mal lasse.
	const isFetching = useIsFetching();

	return (
		<>
			<LoadingScreen isLoading={isFetching !== 0}>
				<div id={id} aria-describedby={ariaDescribedBy}>
					{layers?.map((item, index) => (
						<Item
							key={index}
							{...item}
							index={index}
							previousIds={layers.slice(0, index).map((item) => item.id)}
							nextIds={layers.slice(index + 1).map((item) => item.id)}
						></Item>
					))}
				</div>
			</LoadingScreen>
		</>
	);
}

function Item({
	id,
	dataFn,
	dataKey,
	displayFormatterFn,
	valueFormatterFn,
	description,
	errorMessage,
	previousIds,
	nextIds
}) {
	const { values } = useFormikContext();

	function previousValuesAreValid() {
		return previousIds.every((id) => values[id] && values[id] > 0);
	}

	const { data } = useQuery({
		queryKey: dataKey(previousIds.map((id) => values[id])),
		queryFn: () => dataFn(previousIds.map((id) => values[id])),
		enabled: previousValuesAreValid()
	});

	return (
		<Field
			validate={(value) => {
				if (!value || value === -1) {
					return errorMessage;
				}
			}}
			name={id}
		>
			{({ field, form, meta }) => {
				return (
					<Form.Group className="mb-3" controlId={id}>
						<Form.Label visuallyHidden>{description}</Form.Label>
						<Form.Select
							required
							disabled={!previousValuesAreValid()}
							isInvalid={!!meta.error}
							{...field}
							onChange={(e) => {
								nextIds.forEach((id) => form.setFieldValue(id, -1));
								field.onChange(e);
							}}
							aria-describedby={`${id}-error`}
						>
							<option selected value={-1} disabled>Bitte wählen</option>
							{data?.map((item, index) => (
								<option key={index} value={valueFormatterFn ? valueFormatterFn(item) : item}>
									{displayFormatterFn ? displayFormatterFn(item) : item}
								</option>
							))}
						</Form.Select>
						<Form.Control.Feedback id={`${id}-error`} type="invalid">
							{meta.error}
						</Form.Control.Feedback>
					</Form.Group>
				);
			}}
		</Field>
	);
}

LayerSelector.propTypes = {
	/** The layers for the selection */
	layers: PropTypes.arrayOf(
		PropTypes.shape({
			/** An id for the selection control */
			id: PropTypes.string.isRequired,
			/** A description of the selection */
			description: PropTypes.string.isRequired,
			/** The error message if the field is not valid */
			errorMessage: PropTypes.string.isRequired,
			/** The function for getting this layer values */
			dataFn: PropTypes.func.isRequired,
			/** The function for getting the key for this layer */
			dataKey: PropTypes.func.isRequired,
			/** The function for formatting the displayed value */
			displayFormatterFn: PropTypes.func,
			/** The function for formatting the value */
			valueFormatterFn: PropTypes.func
		}).isRequired
	).isRequired,
	/** Gets called when the final values changes */
	onChange: PropTypes.func,
	/** If its not empty, the last select is marked as error */
	errors: PropTypes.any
};
