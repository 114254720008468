import React from 'react';
import { EnrollmentItem } from './EnrollmentItem';
import styled from 'styled-components';
import { NoneStyledOrderedList } from '../../core';

const EnrollmentListHeader = styled.h2`
	margin-top: 1em;
	margin-bottom: 0px;
	border-left: 2.5mm solid;
	border-color: #0080ff;
	padding-left: 1.5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: white;
	font-size: 110%;
	font-weight: bold;
`;

export const EnrollmentList = ({ enrollments, title }) => {
	let count = 1;

	if (Object.keys(enrollments).length === 0) return;

	return (
		<>
			<EnrollmentListHeader>{title}</EnrollmentListHeader>
			<NoneStyledOrderedList>
				{enrollments?.map((enrollment) => (
					<EnrollmentItem enrollment={enrollment} key={count} count={count++} />
				))}
			</NoneStyledOrderedList>
		</>
	);
};
