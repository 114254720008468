import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import { LoadingScreen } from '../core';
import useUser from '../../hooks/useUser';
import { getErrorMessage } from '../../common';

export default function Login({ onLoggedIn, onCanceled, onResetPassword }) {
	const [loginErrorMessage, setLoginErrorMessage] = useState();

	const { login } = useUser();

	const defaultFormData = {
		username: '',
		password: ''
	};

	const [formData, setFormData] = useState(defaultFormData);
	const [isLoading, setIsLoading] = useState(false);

	const getData = (key) => {
		return Object.prototype.hasOwnProperty.call(formData, key) ? formData[key] : '';
	};
	const setData = (key, value) => {
		return setFormData({ ...formData, [key]: value });
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		setIsLoading(true);
		login(formData.username, formData.password).then(
			() => {
				onLoggedIn?.();
				setIsLoading(false);
			},
			(error) => {
				setLoginErrorMessage(getErrorMessage(error));
				setIsLoading(false);
			}
		);
	};

	const handleResetPassword = (e) => {
		e.preventDefault();
		onResetPassword();
	};

	return (
		<>
			<LoadingScreen isLoading={isLoading}>
				<small>Loggen Sie sich bitte mit Ihrer bei uns registrierten E-Mail-Adresse ein:</small>
				<p></p>

				<Form>
					<Form.Group style={{ marginBottom: '30px' }} controlId="email">
						<Form.Label>
							<strong>E-Mail-Adresse</strong>
						</Form.Label>
						<Form.Control
							required
							type="email"
							name="username"
							autoComplete="email"
							placeholder="Ihre E-Mail-Adresse bitte"
							value={getData('username')}
							onChange={(e) => {
								setData('username', e.target.value);
							}}
						/>
					</Form.Group>

					<Form.Group controlId="password">
						<Form.Label>
							<strong>Passwort</strong>
						</Form.Label>
						<Form.Control
							required
							type="password"
							name="password"
							autoComplete="current-password"
							placeholder="Ihr Passwort bitte"
							value={getData('password')}
							onChange={(e) => {
								setData('password', e.target.value);
							}}
						/>
					</Form.Group>
					{loginErrorMessage && <small className="text-danger">{loginErrorMessage}</small>}

					<hr style={{ marginTop: '40px' }}></hr>
					<Container>
						<Row className="justify-content-center">
							<Col xs={12} sm="auto">
								<Button
									variant="outline-success my-2"
									style={{ width: 'inherit' }}
									type="submit"
									onClick={handleSubmit}
								>
									Login fortbildungen.berlin
								</Button>
							</Col>
							<Col xs={12} sm="auto">
								<Button
									variant="outline-dark my-2"
									style={{ width: 'inherit' }}
									onClick={onCanceled}
								>
									Abbrechen
								</Button>
							</Col>
						</Row>
					</Container>
					<hr></hr>
					<small>
						Haben Sie Ihr Passwort vergessen?{' '}
						<a href="/" onClick={handleResetPassword}>
							Passwort zurücksetzen
						</a>
					</small>
				</Form>
			</LoadingScreen>
		</>
	);
}
