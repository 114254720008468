import React from 'react';
import parse from 'html-react-parser';
import { AiOutlineMail } from 'react-icons/ai';
import { HiOutlineClipboardCopy, HiOutlineExternalLink } from 'react-icons/hi';
import { IoLocationOutline, IoHomeOutline } from 'react-icons/io5';
import { FaWheelchair } from "react-icons/fa";
import { Paragraph } from '../core';
import useNotification from '../../hooks/useNotification';
import { getFormattedDateString, eventDayTimeSum } from '../../common';
import styled from 'styled-components';
import {GiVideoConference} from "react-icons/gi";

const TrainingHeader = styled.h3`
	font-size: var(--bs-body-font-size);
	font-weight: bold;
`;

const TrainingSection = styled.section``;

const TrainingParagraph = styled.p`
	padding-left: 1rem;
`;

const Indented = styled.div`
	padding-left: 1rem;
	margin-bottom: 1rem;
`;

export default function TrainingDetailView({ data }) {
	const { setInfo, setError } = useNotification();

	const getDateTimeString = (event) => {
		const showDate = (dateString, from, to) => {
			const weekday = ['So., ', 'Mo., ', 'Di., ', 'Mi., ', 'Do., ', 'Fr., ', 'Sa., '];
			const date = new Date(dateString);
			return (
				weekday[date.getDay()] +
				getFormattedDateString(date) +
				', ' +
				from +
				' Uhr - ' +
				to +
				' Uhr'
			);
		};
		// const date = getFormattedDateString(new Date(event.date));
		// return `${event.weekDay}, ${date}, von ${event.timeBegin} Uhr bis ${event.timeEnd} Uhr`;
		return showDate(event.date, event.timeBegin, event.timeEnd);
	};

	const getEvents = () => {
		if (Array.isArray(data.dates) && data.dates[0])
			return (
				<>
					<TrainingSection>
						<TrainingHeader>Termin(e):</TrainingHeader>
						<ul>
							{data.dates.map((event, count) => (
								<li key={count}>{getDateTimeString(event)}</li>
							))}
						</ul>
					</TrainingSection>
					<TrainingSection>
						<TrainingHeader>Zeitdauer:</TrainingHeader>
						<TrainingParagraph>{eventDayTimeSum(data.dates)} Minuten insgesamt</TrainingParagraph>
					</TrainingSection>
				</>
			);
	};

	const handleClipboard = (event, param) => {
		navigator.clipboard.writeText(param).then(
			function () {
				setInfo(
					`Der Code ${param} für den Konferenzraum wurde kopiert. Sie können nun durch Klick in den BBB-Konferenzraum wechseln und den benötigten Code dort einfügen.`
				);
			},
			function () {
				setError('Ihr Browser unterstützt leider das Kopieren in die Zwischenablage nicht. Sorry!');
			}
		);
	};

	const BBBLink = () => {
		if (typeof data.bbbLink === 'string' && data.bbbLink.trim().length === 0) {
			return '';
		} else {
			const bbbLink = data.bbbLink;
			const bbbcode = data.bbbCode ? ' Konferenzraum-Code: ' + data.bbbCode : '';
			// const bbbRoom = data.bbbCode ? bbbLink + '?access_code=' + data.bbbCode : bbbLink;
			const bbbRoom = bbbLink;
			return (
				<Paragraph>
					<a href={bbbRoom} target="bbb">
						<GiVideoConference style={{
							float: 'left',
							fontSize: '2.5rem',
							marginRight: '0.5rem',
							color: 'green',
							marginTop: '0.2rem'}}
						/>
					</a>
					<a href={bbbRoom} target="bbb">
						{' '}
						Konferenzraum <HiOutlineExternalLink />
					</a>
					<br />
					{bbbcode}{' '}
					{bbbcode ? (
						<HiOutlineClipboardCopy
							onClick={(event) => handleClipboard(event, data.bbbCode)}
							title="Code für Konferenzraum kopieren."
						></HiOutlineClipboardCopy>
					) : (
						'Kein Code notwendig.'
					)}
				</Paragraph>
			);
		}
	};


	/**
	 * @return google maps link to the address
	 */
	const mapLink = () => {
		const location = data.location;
		if (!location) return null;
		if (!location.einrichtungen_strasse || !location.einrichtungen_plz) return null;
		const link =
			'https://maps.google.com/?q=' +
			location.einrichtungen_strasse +
			', ' +
			location.einrichtungen_plz +
			' Berlin, Germany';
		return (
			<Paragraph>
				<a href={link} title="Ort in Google-Map anzeigen" target="Map">
					<IoLocationOutline /> Ort in Google-Maps anzeigen
				</a>
			</Paragraph>
		);
	};

	/**
	 * @return osm link to the address
	 */
	const osmLink = () => {
		const location = data.location;
		const zoomLevel = 10;
		if (!location) return null;
		if (!location.einrichtungen_strasse || !location.einrichtungen_plz) return null;

		// OpenStreetMap URL-Struktur
		const link =
			'https://www.openstreetmap.org/search?query=' +
			encodeURIComponent(
				location.einrichtungen_strasse + ', ' +
				location.einrichtungen_plz + ' Berlin, Germany'
			) + `#map=${zoomLevel}/52.5200/13.4050`;

		return (
			<Paragraph>
				<a href={link} title="Ort in OpenStreetMap anzeigen" target="Map">
					<IoLocationOutline /> Ort in OpenStreetMap anzeigen
				</a>
			</Paragraph>
		);
	};

	/**
	 * @return asm link to the address
	 */
	const mapLinkApple = () => {
		const location = data.location;
		if (!location) return null;
		if (!location.einrichtungen_strasse || !location.einrichtungen_plz) return null;
		const link =
			'http://maps.apple.com/?q=' +
			location.einrichtungen_strasse +
			', ' +
			location.einrichtungen_plz +
			' Berlin, Germany';
		return (
			<Paragraph>
				<a href={link} title="Ort in Apple-Map anzeigen" target="Map">
					<IoLocationOutline /> Ort in Apple-Maps anzeigen
				</a>
			</Paragraph>
		);
	};

	const getLocation = () => {
		const location = data.location;
		const room = data.room ? (", " + data.room) : "";
		const address = location.einrichtungen_strasse +", "+ location.einrichtungen_plz  +" "+  location.einrichtungen_ort;

		return (
			<TrainingSection>
				<TrainingHeader>Fortbildungsort: </TrainingHeader>
				<Indented>
					<Paragraph>
						{!location.einrichtungen_link ? (
							location.einrichtungen_name
						) : (
							<a href={location.einrichtungen_link} target="Home" title="Homepage des Fortbildungsortes öffnen"><IoHomeOutline /> {location.einrichtungen_name} </a>
						)}
						{location.einrichtungen_barrierefrei ? (<FaWheelchair/>) : ""}
					</Paragraph>
					<Paragraph>
						{(location && location.einrichtungen_strasse && location.einrichtungen_plz) ? ( address + room ) : ("")}
						{mapLink()}
						{osmLink()}
						{mapLinkApple()}
						{BBBLink()}
					</Paragraph>
				</Indented>
			</TrainingSection>
		);
	};

	const getSpeakers = () => {
		if (!data.speakers) return '';
		return (
			<TrainingSection>
				<TrainingHeader>Referenten/-innen:</TrainingHeader>
				<TrainingParagraph>{data.speakers}</TrainingParagraph>
			</TrainingSection>
		);
	};

	const getInstructor = () => {
		return (
			<TrainingSection className="mb-3">
				<TrainingHeader style={{ display: 'inline' }}>Leitung:&nbsp;</TrainingHeader>
				<p style={{ display: 'inline' }}>
					{!data.instructorMail ? (
						data.instructorName
					) : (
						<a href={'mailto:' + data.instructorMail}>
							{data.instructorName} <AiOutlineMail />
						</a>
					)}
				</p>
			</TrainingSection>
		);
	};

	const getNotice = () => {
		if (!data.notice) return '';
		return (
			<TrainingSection>
				<TrainingHeader>Hinweise:</TrainingHeader>
				<TrainingParagraph>{parse(data.notice)}</TrainingParagraph>
			</TrainingSection>
		);
	};

	const getLiaison = () => {
		if (!data.liaison) return '';
		return (
			<TrainingSection>
				<TrainingHeader>Zusammenarbeit:</TrainingHeader>
				<TrainingParagraph>{parse(data.liaison)}</TrainingParagraph>
			</TrainingSection>
		);
	};

	const getResponsibility = () => {
		const generateEmail = () => {
			const part1 = 'Petra.Gerlach';
			const part2 = 'senbjf';
			const part3 = 'berlin';
			const part4 = 'de';
			return `${part1}@${part2}.${part3}.${part4}`;
		};

		if (data.regionId!=="13") return '';
		return (
			<TrainingSection>
				<TrainingHeader>Verantwortung:</TrainingHeader>
				<TrainingParagraph>
					Die vorliegende Fortbildung wird verantwortet von IV B RF 23  {generateEmail()}.
				</TrainingParagraph>
			</TrainingSection>
		)
	}

	return (
		<>
			<TrainingSection className="mb-3">{parse(data.content)}</TrainingSection>
			{getNotice()}
			{getEvents()}
			{getLocation()}
			{getSpeakers()}
			{getInstructor()}
			{getLiaison()}
			{getResponsibility()}
		</>
	);
}
