const colors = {
	lightOrange: '#ffc107',
	orange: '#FFA500',
	darkOrange: '#cc7000',

	lightRed: '#ff6161',
	red: '#ff0000',
	darkRed: '#a80000',

	lightGreen: '#21B570',
	green: '#008000',

	darkBlue: '#00008b',

	snow: '#FFFAFA',

	lightGray: '#dee2e6',
	gray: '#AAAAAA',
	darkGray: '#212529',

	black: '#000000'
};

export default {
	// Header
	primaryHeader: colors.darkRed,
	secondaryHeader: colors.darkGray,
	primarySubHeader: colors.darkRed,
	secondarySubHeader: colors.darkGray,

	// Navigation
	secondaryNavigation: colors.darkRed,

	// Layout
	primaryDivider: colors.darkRed,
	groupBar: colors.snow,

	// Notifications
	notificationHeader: colors.black,
	notificationText: colors.black,
	notificationInfo: colors.lightGreen,
	notificationWarning: colors.lightOrange,
	notificationError: colors.lightRed,

	// Button
	secondaryButtonBackground: colors.darkRed,

	// Icon
	primaryIcon: colors.darkGray,
	secondaryIcon: colors.darkBlue,
	successIcon: colors.green,
	warningIcon: colors.darkOrange,
	errorIcon: colors.darkRed,

	// Text
	primaryText: colors.black,
	errorText: colors.darkRed,

	placeholderText: colors.darkGray,

	// Borders
	primaryBorder: colors.lightGray,
	successBorder: colors.green,
	warningBorder: colors.darkOrange,
	errorBorder: colors.darkRed,
	noticeBorder: colors.gray

	// Examples
	// primaryBackground: colors.mango,
	// secondaryBackground: colors.bertaBlue,
	// secondaryText: colors.nero,
	// primaryButtonBackground: colors.bloodOrgan,
	// primaryButtonText: colors.schoolBusYellow
};
