import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';

const HeadLogo = ({
                      logo,
                      text,
                      colorBackground,
                      color,
                      height,
                      posLogo,
                      posText,
                      heightLogo,
                      textSize,
                      fontWeight
                  }) => {
    const [containerHeight, setContainerHeight] = useState(height);
    const [dynamicTextSize, setDynamicTextSize] = useState(textSize);

    const updateContainerHeight = () => {
        const screenWidth = window.innerWidth;
        // Berechne die neue Höhe basierend auf der Bildschirmbreite
        const newHeight = (height / 1920) * screenWidth; // 1920 ist eine Beispielbasisbreite
        setContainerHeight(newHeight);

        // Berechne die neue Schriftgröße basierend auf der Bildschirmbreite
        const newTextSize = (textSize / 1920) * screenWidth; // 1920 ist eine Beispielbasisbreite
        setDynamicTextSize(newTextSize);
    };

    useEffect(() => {
        updateContainerHeight();
        window.addEventListener('resize', updateContainerHeight);
        return () => window.removeEventListener('resize', updateContainerHeight);
        // eslint-disable-next-line
    }, [height, textSize]);

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colorBackground,
        color,
        height: `${containerHeight}px`,
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
        position: 'relative', // Notwendig für absolute Positionierung der Kinder
        transition: 'height 0.2s ease-in-out', // Sanfte Übergänge bei Größenänderungen
        overflow: 'visible', // Macht Überläufe sichtbar
    };

    const calculatePosition = (pos) => {
        const screenWidth = window.innerWidth;
        return (screenWidth / 2) + ((screenWidth / 2) * (pos / 100));
    };

    const logoContainerStyle = {
        position: 'absolute',
        left: `${calculatePosition(posLogo)}px`,
        transform: 'translateX(-50%)', // Zentriert das Logo horizontal
        height: `${heightLogo}%`, // Setze die Logohöhe proportional zur Containerhöhe
        maxHeight: 'none', // Hebt die maximale Höhe auf
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
    };

    const textContainerStyle = {
        position: 'absolute',
        left: `${calculatePosition(posText)}px`,
        transform: 'translateX(-50%)', // Zentriert den Text horizontal
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
        height: '100%',
    };

    const logoStyle = {
        height: '100%',
        width: 'auto',
        objectFit: 'contain',
    };

    const textStyle = {
        fontSize: `${dynamicTextSize}em`,
        fontWeight,
        whiteSpace: 'nowrap',
    };

    return (
        <div style={containerStyle}>
            <div style={logoContainerStyle}>
                <Image src={logo} alt="Logo" style={logoStyle} fluid />
            </div>
            {text && (
                <div style={textContainerStyle}>
                    <span style={textStyle}>{text}</span>
                </div>
            )}
        </div>
    );
};

export default HeadLogo;
