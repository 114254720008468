import * as Yup from 'yup';

export const firstName = Yup.string()
	.matches(
		'[a-zA-Z]',
		'Der Vorname darf nicht nur aus einem Sonderzeichen oder einer Zahl bestehen'
	)
	.required('Der Vorname darf nicht leer sein');

export const lastName = Yup.string()
	.matches(
		'[a-zA-Z]',
		'Der Nachname darf nicht nur aus einem Sonderzeichen oder einer Zahl bestehen'
	)
	.required('Der Nachname darf nicht leer sein');

export const tel = Yup.number()
	.typeError('Die Telefonnummer muss eine Nummer sein')
	.positive('Die Telefonnummer kann keine negative Zahl sein')
	.integer();

export const password = Yup.string()
	.min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein')
	.matches('[a-z]', 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten')
	.matches('[A-Z]', 'Das Passwort muss mindestens einen Großbuchstaben enthalten')
	.matches('[0-9]', 'Das Passwort muss mindestens eine Zahl enthalten')
	.matches('[!@#$%^&*.]', 'Das Passwort muss mindestens ein Sonderzeichen enthalten')
	.required('Bitte geben Sie ein Passwort ein, das den Kriterien entspricht');

export const passwordConfirm = (passwordFieldName) =>
	Yup.string()
		.oneOf([Yup.ref(passwordFieldName)], 'Die Passwörter stimmen nicht über ein')
		.required('Bitte wiederholen Sie Ihr Passwort');

export const mail = Yup.string()
	.email('Bitte geben Sie eine gültige E-Mail-Adresse an')
	.required('Die E-Mail-Adresse darf nicht leer sein');
