import styled from 'styled-components';
import { HorizontalDivider } from '../core';

const Description = styled.div`
	font-size: 1.25rem;
`;

const ContentDescription = (props) => {
	return (
		<>
			<div style={{ textAlign: 'center', marginLeft: '1em', marginRight: '1em' }}>
				<Description>{props.children}</Description>
				<HorizontalDivider />
			</div>
		</>
	);
};
export { ContentDescription };
