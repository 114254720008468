import { Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../common';
import { QueryKey } from '../../../data/QueryKey';
import useNotification from '../../../hooks/useNotification';
import useUser from '../../../hooks/useUser';
import AuthService from '../../../services/auth.service';
import ProfileService from '../../../services/profile.service';
import { HorizontalDivider, LoadingScreen, ModalFooter, Paragraph, RoutingModal } from '../../core';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { mail } from '../../../common/validations';

const schema = Yup.object().shape({
	mail
});

export const Mail = () => {
	const queryClient = useQueryClient();
	const { user } = useUser();
	const navigate = useNavigate();
	const { setInfo, setError } = useNotification();

	const { isLoading, data: profile } = useQuery(
		[QueryKey.userProfile, user?.username],
		ProfileService.getProfile
	);

	const { mutate: mutateMail, isLoading: isMutating } = useMutation(
		(values) => {
			return AuthService.updateUserAsync({ email: values.mail });
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(QueryKey.userProfile);

				setInfo(
					'Die dienstliche E-Mail-Adresse wurde erfolgreich auf ' +
						variables.mail +
						' geändert. Überprüfen Sie, ob die dienstliche E-Mail-Adresse für Sie erreichbar ist. Ansonsten erhalten Sie keine Mitteilungen von der Fortbildungsverwaltung. Bitte ändern Sie die dienstliche E-Mail-Adresse, falls Sie einen Fehler entdecken.'
				);
				navigate(-1);
			},
			onError: (error) => setError(getErrorMessage(error), 'updateMailError')
		}
	);

	return (
		<RoutingModal title="E-Mail-Adresse ändern">
			<LoadingScreen isLoading={isLoading || isMutating}>
				{profile && (
					<Formik
						onSubmit={mutateMail}
						validationSchema={schema}
						initialValues={{
							mail: profile.email
						}}
					>
						{({
							handleSubmit,
							values,
							handleChange,
							handleBlur,
							dirty,
							isValid,
							touched,
							errors
						}) => (
							<form noValidate onSubmit={handleSubmit} aria-describedby="updateMailError">
								<Paragraph id="extraText">
									Hinweis: Bitte verwenden Sie Ihre dienstliche E-Mail-Adresse.
								</Paragraph>
								<HorizontalDivider />
								<Form.Group className="mb-3" controlId="email">
									<Form.Label>E-Mail-Adresse</Form.Label>
									<Form.Control
										required
										autoComplete="email"
										name="mail"
										type="email"
										aria-describedby="extraText"
										placeholder="E-Mail-Adresse"
										value={values.mail}
										onChange={handleChange}
										onBlur={handleBlur}
										autoFocus
										isInvalid={touched.mail && errors.mail}
									/>
									<Form.Control.Feedback type="invalid">{errors.mail}</Form.Control.Feedback>
								</Form.Group>

								<ModalFooter submitDisabled={!isValid || !dirty} />
							</form>
						)}
					</Formik>
				)}
			</LoadingScreen>
		</RoutingModal>
	);
};
