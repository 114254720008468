import useBookmarks from '../../../hooks/useBookmarks';
import { LoadingScreen } from '../../core';
import { BookmarkList } from './BookmarkList';
import ProfileService from "../../../services/profile.service";
import {BsPrinter} from "react-icons/bs";
import Button from "react-bootstrap/Button";
import React from "react";

export const Bookmarks = () => {
	const { isLoading: loadingBookmarks, bookmarks } = useBookmarks();
	const handleData = async () => {
		const newURL = await ProfileService.getBookmarkoverview();
		const url = newURL.service + '://' + newURL.domain + '/' + newURL.path + newURL.fileName;
		window.open(url, '_self', 'noopener,noreferrer');
	};

	return (
		<LoadingScreen isLoading={loadingBookmarks}>
			<div
				style={{
					paddingLeft: '0.5rem',
					paddingRight: '0.5rem',
					paddingTop: '0rem',
					paddingBottom: '1rem'
				}}
			>
				{!bookmarks || bookmarks.length === 0 ? (
					<p>Sie haben bisher keine Bookmarks auf Fortbildungen gesetzt!</p>
				) : (
					<div>
						Hier finden Sie eine Liste der Fortbildungen, die Sie mit einem Bookmark versehen haben. <br />
						Diese Liste ermöglicht Ihnen einen schnellen Zugriff auf interessante Fortbildungen und erlaubt es, eine Übersicht zu erstellen und anderen zur Verfügung zu stellen. <br />
						Klicken Sie auf den grünen Button, um eine PDF-Datei der Fortbildungen, die Sie mit einem Lesezeichen markiert haben, herunterzuladen.
						<div
							style={{
								marginTop: '0.5em',
								marginBottom: '1em',
								textAlign: 'center'
							}}
						>
							<Button
								onClick= {() => handleData()}
								key		="bookmarkButton"
								variant	="success"
								size	="sm"
								title   ="Druckbare Übersicht aller Bookmarks"
								style	={{
									width: '9.5rem',
									marginRight: '1rem',
									marginBottom: '0.5rem',
									minWidth: '7rem',
									padding: '0.5em'
								}}
							>
								<BsPrinter style={{ marginRight: '0.5em' }}></BsPrinter> Bookmarkliste
							</Button>
						</div>
						<div>
							<BookmarkList
								bookmarks={bookmarks}
								title="Aktuell gesetzte Bookmarks"
							/>
						</div>
					</div>
				)}
			</div>
		</LoadingScreen>
	);
};
