import React from 'react';
import Button from 'react-bootstrap/Button';
import {BsPrinter} from 'react-icons/bs';
import { calcEventDuration } from '../../../common';
import ProfileService from "../../../services/profile.service";

const getYear = (eventNumber) => {
	const halfYearIdentification = eventNumber.split('-')[0].split('.');

	const year = parseInt(halfYearIdentification[0]);
	const halfYear = halfYearIdentification[1];

	if(halfYear === "1")
		return `${year - 1}/${year}`;
	else if(halfYear === "2")
		return `${year}/${year + 1}`;
	else
		throw new Error("Invalid value for half year.");


};

const calcYearSums = (trainings) => {
	const yearSum = [];

	// replace with trainings?.group() as soon as all browser supports it
	trainings?.forEach((training) => (yearSum[getYear(training.eventNumber)] = 0));

	trainings?.forEach((training) => {
		yearSum[getYear(training.eventNumber)] += training.wasPresent && calcEventDuration(training.events);
	});

	return yearSum;
};

export const TrainingSumBadges = ({ trainings }) => {
	const counts = calcYearSums(trainings);

	const handleData = async (schoolyear) => {
		const newURL = await ProfileService.getOverview(schoolyear);
		const url = newURL.service + '://' + newURL.domain + '/' + newURL.path + newURL.fileName;
		window.open(url, '_self', 'noopener,noreferrer');
	};

	if (counts) {
		const countArray = Object.entries(counts);

		return (
			<>
				{countArray.sort().map((count) => (
					(count[1]>0) ?
						<Button
							onClick= {() => handleData(count[0])}
							key		={count[0]}
							variant	="success"
							size	="sm"
							title   ={"Druckbare Übersicht aller Fortbildungen im Schuljahr " + count[0]}
							style	={{
								width: '9.5rem',
								marginRight: '1rem',
								marginBottom: '0.5rem',
								minWidth: '7rem',
								padding: '0.5em'
							}}
						>
							<BsPrinter style={{ marginRight: '0.5em' }}></BsPrinter>
							{count[1] !== 0 ? `${count[0]} : ${count[1]} min.` : null}
						</Button>
						: ""
				))}
			</>
		);
	}
	return null;
};