import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import TrainingsService from '../../services/trainings.service';
import { useQuery } from 'react-query';
import { Title } from './Title';
import HeadLogo from "../core/headLogo";
import myLogo from "./img/hints.png";

const Hints = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const { data, isLoading } = useQuery(
		'instructionsforuse',
		TrainingsService.getInstructionsForUse
	);

	useEffect(() => {
		document.title = 'Hinweise | Fortbildung Berlin';
	}, []);
	// const {data, isLoading} = useQuery('instructions', TrainingsService.getCurrentHints);
	// const {data, isLoading} = useQuery('instructions', TrainingsService.getInformationHints);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<HeadLogo
					logo={myLogo}
					logoText={'Hinweise zur Benutzung'}
					text="Hinweise zur Benutzung"
					colorBackground="#F1EEED"
					color="#a80000"
					height={400}
					posLogo={-35}
					posText={30}
					heightLogo={115}
					textSize={5}
					fontWeight={900}
				/>
			</header>

			<main style={{ marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
				{!isLoading && parse(data)}
			</main>
		</>
	);
};
export default Hints;
