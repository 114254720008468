import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { GiVideoConference } from 'react-icons/gi';
import ConferenceLinkModal  from './conferenceLinkModal';

export const calculateAverage = (jsonAverages) => {
    let averagesObj;
    try {
        // Versucht, den JSON-String zu einem Objekt zu parsen
        averagesObj = JSON.parse(jsonAverages);
    } catch (error) {
        console.error('Fehler beim Parsen von averages:', error);
        return 0;
    }

    // Holt die Werte des Objekts und filtert alle 0-Werte heraus und berechnet die Summe
    const filteredValues = Object.values(averagesObj).filter(value => value !== 0);
    const sum = filteredValues.reduce((acc, current) => acc + current, 0);

    return filteredValues.length > 0 ? sum / filteredValues.length : 0;
};

export const convertToGermanDate = (isoDate, long = true) => {
    const date = new Date(isoDate);
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();

    // Array von Wochentagen in Kurzform
    const weekdays = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."];
    // Hole den Wochentag als Zahl (0-6) und dann den entsprechenden Namen aus dem Array
    const weekdayShort = weekdays[date.getDay()];

    return long ? `${weekdayShort}, den ${day}.${month}.${year}` : `${weekdayShort} ${day}.${month}.${year}`;
};

export const activity = (first, second ) => {
    return (
        <div style={{fontWeight: 'bold'}}>
            {first} / {second}
        </div>
    );
}

export const ManageConferenceLink = (props) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const codeText = props.code !== null ? " Code: " + props.code : "";

    if(props.link)
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <GiVideoConference
                        style={{fontSize: '2rem', marginRight: '0.5rem', color: 'green'}}
                    />
                    <Button
                        variant="outline-success"
                        size="sm"
                        href={props.link}
                        title={`Link ${props.link} mit ${codeText} der Konferenz in neuem Tab öffnen, um ihn zu prüfen`}
                        target={"Konferenz"}
                        style={{ width: '6rem' }}
                    >
                        prüfe Link
                    </Button>
                    <Button
                        variant="outline-primary"
                        size="sm"
                        title={`Link ${props.link} mit ${codeText} der Konferenz ändern`}
                        style={{ width: '6rem' }}
                        onClick={handleOpenModal}
                    >
                        ändere Link
                    </Button>
                </div>
                <div>
                    <ConferenceLinkModal
                        show={showModal}
                        onHide={handleCloseModal}
                        id={props.id}
                        url={props.link}
                        code={props.code}
                        displayNumber={props.displayNumber}
                        title={props.title}
                    />
                </div>
            </>
        );
    else
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <GiVideoConference
                        style={{fontSize: '2rem', marginRight: '0.5rem', color: '#dc3545'}}
                    />
                    <Button
                        variant="outline-danger"
                        size="sm"
                        title={"Link zur Konferenz angeben, bisher nicht gesetzt"}
                        style={{ width: '12rem' }}
                        onClick={handleOpenModal} // Modal öffnen
                    >
                        Konferenzlink nicht angelegt
                    </Button>
                </div>
                <div>
                    <ConferenceLinkModal
                        show={showModal}
                        onHide={handleCloseModal}
                        id={props.id}
                        displayNumber={props.displayNumber}
                        title={props.title}
                    />
                </div>
            </>
        )
}



export const registrationColor = (registrationCount, max) => {
    if(max === 0) return { bg: 'secondary', text: 'white' };
    if(registrationCount < max) return { bg: 'success', text: 'white' };
    else if(registrationCount === max) return { bg: 'danger', text: 'dark' };
    else return { bg: 'warning', text: 'dark' };
};