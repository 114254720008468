import { useMutation, useQueryClient } from 'react-query';

import useEnrollments from './useEnrollments';
import useNotification from './useNotification';
import useUser from './useUser';
import EnrollmentsService from '../services/enrollments.service';
import { getErrorMessage } from '../common';

/**
 * Custom Hook for specific enrollment by trainingId.
 *
 * @author Janik Hilser
 *
 * Params:
 *  - trainingId: The id for the enrollments
 *
 * Returns:
 *  - enrollment: The matching enrollment
 *  - enroll: Function for enroll to training
 *  - cancel: Function for cancel training
 *  - reenroll: Function for reenroll to training
 */
export default function useEnrollment(trainingId) {
	const { enrollments, isLoading } = useEnrollments();
	const { setInfo, setWarning, setError } = useNotification();
	const { user } = useUser();

	const queryClient = useQueryClient();

	const matchingEnrollment = enrollments?.find(
		(item) => parseInt(item.eventId) === parseInt(trainingId)
	);

	const enrollMutation = useMutation(() => EnrollmentsService.enroll(trainingId), {
		onMutate: async (isWaiting) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries('enrollments');

			// Snapshot the previous value
			const previousEnrollments = queryClient.getQueryData(['enrollments', user.username]);

			// Optimistically update to the new value
			queryClient.setQueryData(['enrollments', user.username], (old) => [
				...old,
				{
					trainingId,
					isWaiting
				}
			]);

			// Return a context object with the snapshotted value
			return { previousEnrollments };
		},

		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, newEnrollment, context) => {
			setError(getErrorMessage(err));
			queryClient.setQueryData(['enrollments', user.username], context.previousEnrollments);
		},

		onSuccess: (data) => {
			data?.forEach((element) => {
				if (element.type === 0) setInfo(element.text);
				if (element.type === 1) setWarning(element.text);
				if (element.type === 2) setError(element.text);
			});
		},

		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries('enrollments');
		}
	});

	const cancelMutation = useMutation(() => EnrollmentsService.cancel(matchingEnrollment.id), {
		onMutate: async () => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries('enrollments');

			// Snapshot the previous value
			const previousEnrollments = queryClient.getQueryData(['enrollments', user.username]);

			// Optimistically update to the new value
			queryClient.setQueryData(['enrollments', user.username], (old) =>
				old.map((item) =>
					item.id === matchingEnrollment.id
						? {
								...item,
								isCancelledByUser: true
						  }
						: item
				)
			);

			// Return a context object with the snapshotted value
			return { previousEnrollments };
		},

		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, newEnrollment, context) => {
			setError(getErrorMessage(err));
			queryClient.setQueryData(['enrollments', user.username], context.previousEnrollments);
		},

		onSuccess: (data) => {
			setInfo(data);
		},

		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries('enrollments');
		}
	});

	const reenrollMutation = useMutation(() => EnrollmentsService.reenroll(matchingEnrollment.id), {
		onMutate: async (isWaiting) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries('enrollments');

			// Snapshot the previous value
			const previousEnrollments = queryClient.getQueryData(['enrollments', user.username]);

			// Optimistically update to the new value
			queryClient.setQueryData(['enrollments', user.username], (old) =>
				old.map((item) =>
					item.id === matchingEnrollment.id
						? {
								...item,
								isWaiting,
								isCancelledByUser: false
						  }
						: item
				)
			);

			// Return a context object with the snapshotted value
			return { previousEnrollments };
		},

		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, newEnrollment, context) => {
			setError(getErrorMessage(err));
			queryClient.setQueryData(['enrollments', user.username], context.previousEnrollments);
		},

		onSuccess: (data) => {
			setInfo(data);
		},

		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries('enrollments');
		}
	});

	return {
		enrollment: matchingEnrollment,
		enroll: enrollMutation.mutate,
		cancel: cancelMutation.mutate,
		reenroll: reenrollMutation.mutate,
		isLoading
	};
}
