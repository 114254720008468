import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import ProfileService from '../../../services/profile.service';
import { TrainingFeedback } from '../../trainings';
import useNotification from '../../../hooks/useNotification';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { TbCertificate } from 'react-icons/tb';
import { BsFillBellFill } from 'react-icons/bs';
import { BiCalendarCheck, BiUserVoice} from 'react-icons/bi';
import colors from '../../../common/colors';
import { calcEventDuration, getFormattedDateString } from '../../../common';
import { EnrollmentItemHeader } from './EnrollmentItemHeader';
import { Col, Container, Row } from 'react-bootstrap';


const showDate = (dateString) => {
	if (dateString !== '0000-00-00' && dateString !== undefined) {
		const weekday = ['So., ', 'Mo., ', 'Di., ', 'Mi., ', 'Do., ', 'Fr., ', 'Sa., '];
		const date = new Date(dateString);
		return weekday[date.getDay()] + getFormattedDateString(date);
	}
	return ' (kein Fortbildungsdatum angegeben!) ';
};

const getStartTime = (events) => {
	if (events.length !== 0 && events.length >= 28) return events.split(';')[0].split(',')[2];
	else return null;
};

const getDateTime = (date, events) => {
	return (
		<>
			{showDate(date)}
			{getStartTime(events) ? ' um ' + getStartTime(events) + ' Uhr ' : ''}
		</>
	);
};

const getFormattedNextDate = (date) => {
	return (
		<>
			{showDate(date.date)}
			{' um ' + date.timeBegin + ' Uhr '}
		</>
	);
};

const daysBetween = (first, last) => {
	return Math.ceil((first.getTime() - last.getTime()) / (1000 * 3600 * 24));
};

const lastDate = (appointments) => {
	if(appointments.length !== 0 && appointments.length >= 28) {
		const appointmentArray = appointments.split(";");
		// Remove any empty strings that might be produced by splitting at the end of the original string.
		const filteredAppointmentArray = appointmentArray.filter(appointment => appointment);
		// Extract the date from the last appointment.
		const lastAppointment = filteredAppointmentArray[filteredAppointmentArray.length - 1];
		return lastAppointment.split(",")[0];
	} else return false;
}

const checkDateInterval = (checkDate, days) => {
	if(checkDate) {
		const today = new Date();
		const eventDate = new Date(checkDate);
		const diff = daysBetween(today, eventDate);
		return !!(diff <= days && diff >= 0);
	} else return false;
};

const ShowICalendarEvent = (props) => {
	const today = new Date();
	const events = props.enrollment.events;
	const firstEventTime = props.startTime(events) !== null ? props.startTime(events) + ':00' : '00:00:00';
	const eventFirstDate = new Date(props.enrollment.firstDate + 'T' + firstEventTime);
	const showICalendar = !!(today <= eventFirstDate && events.length !== 0 && events.length >= 28);

	const handleICalendarEvent = () => {
		const url = [
			'BEGIN:VCALENDAR',
			'VERSION:2.0',
			'BEGIN:VEVENT',
			'DTSTART:' +
			events.substring(0, 4) +
			events.substring(5, 7) +
			events.substring(8, 10) +
			'T' +
			events.substring(17, 19) +
			events.substring(20, 22) +
			'00',
			'DTEND:' +
			events.substring(0, 4) +
			events.substring(5, 7) +
			events.substring(8, 10) +
			'T' +
			events.substring(23, 25) +
			events.substring(26, 28) +
			'00',
			'SUMMARY: Fortbildungen.berlin',
			`DESCRIPTION: ${props.enrollment.title.trim()} \\nAlle weiteren Informationen zur Fortbildung finden Sie unter: \\n${
				window.location.origin
			}/training/${props.enrollment.eventId}`,
			'LOCATION:',
			'BEGIN:VALARM',
			'TRIGGER:-PT1440M',
			'REPEAT:1',
			'DURATION:PT15M',
			'ACTION:DISPLAY',
			'DESCRIPTION:Reminder',
			'END:VALARM',
			'END:VEVENT',
			'END:VCALENDAR'
		].join('\n');

		const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

		if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
			// Open/Save link in IE and Edge
			window.navigator.msSaveBlob(blob, 'TerminFortbildungenBerlin.ics');
		} else {
			// Open/Save link in Modern Browsers
			window.open(
				encodeURI('data:text/calendar;charset=utf8,' + url),
				'_self',
				'noopener,noreferrer'
			);
		}
	};

	return (
		<>
			{showICalendar ? (
				<BiCalendarCheck
					size="1em"
					color={colors.secondaryIcon}
					style={{ cursor: 'pointer', marginRight: '0.75em' }}
					onClick={handleICalendarEvent}
					title="Termin der Fortbildung im Kalender speichern."
				></BiCalendarCheck>
			) : (
				''
			)}
		</>
	);
};

const getNextDateEvent = (firstDate) => {
	const today = new Date();
	const eventFirstDate = new Date(firstDate);
	const diff = daysBetween(eventFirstDate, today);
	return !!(diff <= 14 && diff >= 0);
};

export const EnrollmentItem = (props) => {
	const { setInfo, setError } = useNotification();
	const location = useLocation();

	const handleClipboard = () => {
		navigator.clipboard
			.writeText(`${window.location.origin}/training/${props.enrollment.eventId}`)
			.then(
				function () {
					setInfo(
						'Der Link zu dieser Fortbildung wurde in Ihre Zwischenablage kopiert. Sie können den Link verwenden, um andere über diese Fortbildung zu informieren.'
					);
				},
				function () {
					setError('Ihr Browser unterstützt leider das Kopieren in die Zwischenablage nicht.');
				}
			);
	};

	const handleCert = async () => {
		const newURL = await ProfileService.getCert(props.enrollment.eventId);
		const url = newURL.service + '://' + newURL.domain + '/' + newURL.path + newURL.fileName;
		window.open(url, '_self', 'noopener,noreferrer');
	};

	const markNextEvents =
		getNextDateEvent(props.enrollment.firstDate) && props.enrollment.isConfirmed;

	return (
		<Container
			fluid
			as="li"
			className="px-0"
			style={{
				borderTop: '0.1mm solid #aaaaaa',
				paddingTop: '1rem',
				paddingBottom: '1rem'
			}}
		>
			<Row>
				<Col xs="auto" style={{ width: '2rem' }}>
					{props.count}.
				</Col>
				<Col as={EnrollmentItemHeader}>
					<Link
						to={`/training/${props.enrollment.eventId}`}
						state={{ backgroundLocation: location }}
					>
						{props.enrollment.title + ' [' + props.enrollment.eventNumber + ']'}
					</Link>
				</Col>
				<Col xs="auto">
					{
						props.enrollment.isConfirmed
						// && props.enrollment.isOver
						&& props.enrollment.isEvaluationPossible
						&& checkDateInterval( lastDate(props.enrollment.events), 14)
						? (
							<Link to={`/trainingFeedback/${props.enrollment.eventId}/${encodeURIComponent(props.enrollment.title)}`}>
								<BiUserVoice
									style={{
										cursor: 'pointer',
										color: colors.errorIcon,
										marginRight: '0.55em',
										fontSize: '1.2em'
									}}
									title="Bitte geben Sie noch Ihr Feedback jetzt ab. "
								></BiUserVoice>
							</Link>
						) : ('')
					}
					<ShowICalendarEvent
						enrollment={props.enrollment}
						startTime={getStartTime}
					></ShowICalendarEvent>
					{props.enrollment.wasPresent && props.enrollment.isCertificatePossible ? (
						<TbCertificate
							style={{
								cursor: 'pointer',
								color: colors.successIcon,
								marginRight: '0.75em'
							}}
							onClick={handleCert}
							title="Teilnahmebescheinigung zu dieser Fortbildung"
						></TbCertificate>
					) : (
						''
					)}
					<HiOutlineClipboardCopy
						style={{ cursor: 'pointer', marginRight: '0.75em' }}
						onClick={handleClipboard}
						title="Den Link zur Fortbildung kopieren."
					></HiOutlineClipboardCopy>
					|&nbsp;&nbsp;
					<TrainingFeedback trainingId={props.enrollment.eventId}></TrainingFeedback>
				</Col>
			</Row>
			<Row
				style={{
					paddingTop: '0.2em',
					clear: 'both',
					color: markNextEvents ? colors.errorText : colors.primaryText
				}}
			>
				<Col style={{ marginLeft: '2rem' }}>
					{markNextEvents ? (
						<BsFillBellFill style={{ marginRight: '0.35em' }}></BsFillBellFill>
					) : (
						''
					)}
					{props.enrollment.nextDate
						? getFormattedNextDate(props.enrollment.nextDate)
						: getDateTime(props.enrollment.firstDate, props.enrollment.events)}
					<span style={{ whiteSpace: 'nowrap' }}>
						( &sum; = {calcEventDuration(props.enrollment.events)} Min. )
					</span>
				</Col>
			</Row>
		</Container>
	);
};
