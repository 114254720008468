import useEnrollments from '../../../hooks/useEnrollments';
import { LoadingScreen } from '../../core';
import { TrainingSumBadges } from './TrainingSumBadges';
import { EnrollmentList } from './EnrollmentList';

export const Enrollments = () => {
	const { isLoading: loadingEnrollments, enrollments } = useEnrollments();

	const compare = () => {
		return (a, b) => {
			if (a.nextDate.date < b.nextDate.date) return -1;
			if (a.nextDate.date > b.nextDate.date) return 1;
			return 0;
		};
	};

	return (
		<LoadingScreen isLoading={loadingEnrollments}>
			<div
				style={{
					paddingLeft: '0.5rem',
					paddingRight: '0.5rem',
					paddingTop: '0rem',
					paddingBottom: '1rem'
				}}
			>
				{!enrollments || enrollments.length === 0 ? (
					<p>Sie haben sich bisher für keine Fortbildung angemeldet.</p>
				) : (
					<div>
						Hier finden Sie die Liste Ihrer Fortbildungen der letzten fünf Jahre. Klicken Sie auf einen der grünen Buttons, um eine PDF-Datei als Bestätigung zu erhalten.<br /> Nachgewiesene
						Anwesenheitszeiten pro Schuljahr:
						<div
							style={{
								marginTop: '0.5em',
								marginBottom: '1em',
								textAlign: 'center'
							}}
						>
							<TrainingSumBadges trainings={enrollments}></TrainingSumBadges>
						</div>
						<EnrollmentList
							enrollments={enrollments
								.filter((enrollment) => !enrollment.isOver)
								.sort(compare())}
							title="Laufende und künftige Fortbildungen, aktuelle zuerst"
						/>
						<EnrollmentList
							enrollments={enrollments.filter((enrollment) => enrollment.isOver)}
							title="Vergangene Fortbildungen, letzte zuerst"
						/>
					</div>
				)}
			</div>
		</LoadingScreen>
	);
};
