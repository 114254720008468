import { Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../common';
import { QueryKey } from '../../../data/QueryKey';
import useNotification from '../../../hooks/useNotification';
import useUser from '../../../hooks/useUser';
import AuthService from '../../../services/auth.service';
import ProfileService from '../../../services/profile.service';
import { LoadingScreen, ModalFooter, RoutingModal } from '../../core';
import { InstitutionSelector } from '../../domain.core';
import { Form } from 'react-bootstrap';

export const Institution = () => {
	const queryClient = useQueryClient();
	const { user } = useUser();
	const navigate = useNavigate();
	const { setInfo, setError } = useNotification();

	const { isLoading, data: profile } = useQuery(
		[QueryKey.userProfile, user?.username],
		ProfileService.getProfile
	);

	const { mutate: mutateMail, isLoading: isMutating } = useMutation(
		(values) => AuthService.updateUserAsync({ institutionId: values.institutions }),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QueryKey.userProfile);
				setInfo('Bildungseinrichtung wurde erfolgreich geändert.');
				navigate(-1);
			},
			onError: (error) => setError(getErrorMessage(error), 'updateInstitutionError')
		}
	);

	return (
		<RoutingModal title="Bildungseinrichtung ändern">
			<LoadingScreen isLoading={isLoading || isMutating}>
				{profile && (
					<Formik
						onSubmit={mutateMail}
						initialValues={{
							institutions: profile.institution?.id
						}}
					>
						{({ handleSubmit, dirty, isValid }) => (
							<form noValidate onSubmit={handleSubmit} aria-describedby="updateInstitutionError">
								<Form.Group className="mb-3">
									<InstitutionSelector
										title="Bildungseinrichtung"
										ariaDescribedBy="institutionHelpBlock"
									/>

									<Form.Text id="institutionHelpBlock">
										Falls Ihre Bildungseinrichtung nicht vorhanden ist, wenden Sie sich bitte an{' '}
										<a href="mailto:rf@senbjf.berlin.de">unseren Kontakt</a>.
									</Form.Text>
								</Form.Group>

								<ModalFooter
									submitDisabled={!isValid || !dirty}
								/>
							</form>
						)}
					</Formik>
				)}
			</LoadingScreen>
		</RoutingModal>
	);
};
