import { useEffect } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ModalHeader } from '../core';
import { SubTitle, Title } from '../pages/Title';

export function TraingInProgressView({ text, displayNumber, isModal }) {
	const navigate = useNavigate();

	const handleHide = () => {
		navigate(-1);
	};

	useEffect(() => {
		document.title = displayNumber + ' | Fortbildung Berlin';
	}, [displayNumber]);

	if (isModal)
		return (
			<Modal
				size="lg"
				show={true}
				scrollable={true}
				onHide={handleHide}
				dialogClassName="modal-98w"
			>
				<Modal.Header closeButton closeLabel="Schließen" closeVariant="white">
					<Modal.Title id="example-custom-modal-styling-title">
						<ModalHeader>{displayNumber}</ModalHeader>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>{text}</Modal.Body>
			</Modal>
		);

	return (
		<Container className="justify-content-md-center p-3">
			<Title title="FORTBILDUNG" />
			<SubTitle title={text}></SubTitle>
		</Container>
	);
}
