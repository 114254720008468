import api from './api';

class RegisterService {
	async getInstitutionDistricts() {
		const { data } = await api.get(`/fortbildungenonlinerest/institution-districts`);
		return data;
	}

	async getInstitutionTypes(district) {
		const { data } = await api.get(
			`/fortbildungenonlinerest/institution-types?district_id=${district}`
		);
		return data;
	}

	async getInstitutions(district, type) {
		const { data } = await api.get(
			`/fortbildungenonlinerest/institutions?district_id=${district}&type_id=${type}`
		);
		return data;
	}

	async getSubjects() {
		const { data } = await api.get(`/fortbildungenonlinerest/subjects`);
		return data;
	}
}
export default new RegisterService();
