import { useNavigate } from 'react-router-dom';

import Register from './Register';

export function DefaultRegister() {
	const navigate = useNavigate();

	const handleRegistered = () => {
		navigate(-1);
	};

	return <Register onRegistered={handleRegistered}></Register>;
}
