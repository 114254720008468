import { useLocation, useNavigate } from 'react-router-dom';

import Login from './login';

export function DefaultLogin() {
	const navigate = useNavigate();
	const location = useLocation();

	const from = location.state?.from?.pathname;

	const handleLoggedIn = () => {
		if (from) navigate(from, { replace: true });
		else navigate('/profile');
	};

	const handleCanceled = () => {
		navigate(-1);
	};

	const handleResetPassword = () => {
		navigate('/resetPassword');
	};

	return (
		<Login
			onLoggedIn={handleLoggedIn}
			onCanceled={handleCanceled}
			onResetPassword={handleResetPassword}
		></Login>
	);
}
