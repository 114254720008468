import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Stack } from 'react-bootstrap';

export function Wizard({ children, submitText, onSubmit, initialValues }) {
	const [activeStepNumber, setActiveStepNumber] = useState(1);
	const [snapshot, setSnapshot] = useState(initialValues);

	const steps = React.Children.toArray(children);

	const activeStep = steps[activeStepNumber - 1];
	const isLastStep = activeStepNumber === steps.length;

	const next = (values) => {
		setSnapshot(values);
		setActiveStepNumber(activeStepNumber + 1);
	};

	const previous = (values) => {
		setSnapshot(values);
		setActiveStepNumber(activeStepNumber - 1);
	};

	const handleSubmit = async (values, bag) => {
		if (activeStep.props.onSubmit) {
			await activeStep.props.onSubmit(values, bag);
		}
		if (isLastStep) {
			return onSubmit(values, bag);
		} else {
			bag.setTouched({});
			next(values);
		}
	};

	return (
		<>
			<Formik
				initialValues={snapshot}
				onSubmit={handleSubmit}
				validationSchema={activeStep.props.validationSchema}
			>
				{(formik) => (
					<Form noValidate>
						{activeStep}
						<Stack style={{ marginTop: '1rem' }} direction="horizontal" gap={5}>
							<Button
								disabled={activeStepNumber < 2}
								onClick={() => {
									previous(formik.values);
								}}
								type="button"
								variant="outline-secondary"
							>
								Zurück
							</Button>
							{!isLastStep ? (
								<Button disabled={formik.isSubmitting} type="submit" variant="primary ms-auto">
									Weiter
								</Button>
							) : (
								<Button disabled={formik.isSubmitting} type="submit" variant="success ms-auto">
									{submitText}
								</Button>
							)}
						</Stack>
					</Form>
				)}
			</Formik>
		</>
	);
}

Wizard.propTypes = {
	/** The steps of the wizard */
	children: PropTypes.arrayOf(function (propValue, key, componentName, location, propFullName) {
		if (!/WizardStep/.test(propValue[key].type.name)) {
			return new Error(
				'Invalid prop `' +
					propFullName +
					'` supplied to' +
					' `' +
					componentName +
					'`. Validation failed.'
			);
		}
	}).isRequired,
	/** The function for submitting */
	onSubmit: PropTypes.func.isRequired,
	/** The text for the submit button */
	submitText: PropTypes.string.isRequired,
	/** The initial values for the form */
	initialValues: PropTypes.object.isRequired
};
