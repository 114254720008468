import { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Reaptcha from 'reaptcha';

import AuthService from '../../services/auth.service';
import { LoadingScreen, Paragraph, ParagraphWithSpace } from '../core';
import { Title } from '../pages/Title';
import useNotification from '../../hooks/useNotification';
import { getErrorMessage } from '../../common';
import colors from '../../common/colors';
import { Field, Formik } from 'formik';
import { mail } from '../../common/validations';
import * as Yup from 'yup';

const schema = Yup.object().shape({
	resetMail: mail
});

export function ResetPassword() {
	const { setInfo, setError } = useNotification();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = 'Passwort zurücksetzen | Fortbildung Berlin';
	}, []);

	const resetPassword = useMutation(
		(values) => AuthService.resetPasswordAsync(values.resetMail, captchaValue),
		{
			onSuccess: (data) => {
				setInfo(data);
				navigate('../');
			},
			onError: (error) => setError(getErrorMessage(error))
		}
	);

	const [captchaValue, setCaptchaValue] = useState();

	return (
		<>
			<Container as="main" className="justify-content-md-center p-3" style={{ maxWidth: '500px' }}>
				<Title title="PASSWORT ZURÜCKSETZEN" />
				<Paragraph>
					Bitte geben Sie hier Ihre zu Ihrem Profil zugehörige E-Mail-Adresse ein.
				</Paragraph>
				<ParagraphWithSpace>
					Wir senden Ihnen anschließend einen Link zu, der{' '}
					<strong>
						<font color={colors.errorText}>30 Minuten </font>
					</strong>
					gültig ist, um ihr Passwort zurücksetzen zu können.
				</ParagraphWithSpace>
				<LoadingScreen isLoading={resetPassword.isLoading}>
					<Formik
						onSubmit={resetPassword.mutate}
						validationSchema={schema}
						initialValues={{ resetMail: '' }}
						validateOnMount={true}
					>
						{({ handleSubmit, isValid }) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Field name="resetMail">
									{({ field, form, meta }) => (
										<Form.Group className="mb-3" controlId={field.name}>
											<Form.Label>
												<strong>E-Mail-Adresse:</strong>
											</Form.Label>
											<Form.Control
												{...field}
												required
												autoComplete="email"
												type="email"
												placeholder="name@example.com"
												isInvalid={meta.error}
											/>
											<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
										</Form.Group>
									)}
								</Field>

								<Row className="justify-content-center">
									<Col xs={12} sm="auto">
										{captchaValue ? (
											<Button
												variant="primary"
												style={{ width: 'inherit' }}
												type="submit"
												disabled={!isValid || resetPassword.isSuccess}
												aria-label="Passwort zurücksetzen"
											>
												Abschicken!
											</Button>
										) : (
											<Reaptcha
												sitekey="6LeIexAgAAAAANuMjhoxmjlKs0Mnc3eKC7hAt4lv"
												onVerify={setCaptchaValue}
											></Reaptcha>
										)}
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</LoadingScreen>
			</Container>
		</>
	);
}
