import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { HorizontalDivider, NoneStyledUnorderedList } from '../core';
import { SubTitle, Title } from './Title';
// import logoHead from './img/sitemap.jpg';
import myLogo from './img/wegweisen.png';
import HeadLogo from "../core/headLogo";

const Sitemap = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = 'Sitemap | Fortbildung Berlin';
	}, []);

	const location = useLocation();

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<HeadLogo
					logo={myLogo}
					logoText={'Personen weisen den Weg'}
					text="Struktur der Website"
					colorBackground="#F1EEED"
					color="#a80000"
					height={400}
					posLogo={-30}
					posText={+35}
					heightLogo={115}
					textSize={5}
					fontWeight={900}
				/>
			</header>

			<main
				style={{
					paddingLeft: '1rem',
					paddingRight: '1rem',
					marginBottom: '2rem',
					marginTop: '1rem'
				}}
			>
				<SubTitle title="Sitemap" />

				<HorizontalDivider />

				<Container>
					<Row>
						<Col as="section" md={4}>
							<h2>Allgemein</h2>
							<NoneStyledUnorderedList>
								<li>
									<Link to={`/`}>Startseite</Link>
								</li>
								<li>
									<Link to={`/login`}>Login</Link>
								</li>
								<li>
									<Link to={`/register`} state={{ backgroundLocation: location }}>
										Registrieren
									</Link>
								</li>
								<li>
									<Link to={`/resetPassword`}>Passwort vergessen</Link>
								</li>
							</NoneStyledUnorderedList>
						</Col>

						<Col as="section" md={4}>
							<h2>Fortbildungen</h2>
							<NoneStyledUnorderedList>
								<li>
									<Link to={`/find`}>Suche</Link>
								</li>
								<li>
									<Link to={`/schif`}>Schulinterne Fortbildungen</Link>
								</li>
								<li>
									<Link to={`/conferences`}>Regionalkonferenzen</Link>
								</li>
								<li>
									<Link to={`/symposiums`}>Fachtage</Link>
								</li>
								<li>
									<Link to={`/leadership`}>Leadership.lab</Link>
								</li>
								<li>
									<Link to={`/berufsschulen`}>Berufliche Schulen</Link>
								</li>
								<li>
									<Link to={`/extern`}>Externe Fortbildungen</Link>
								</li>
							</NoneStyledUnorderedList>
						</Col>

						<Col as="section" md={4}>
							<h2>Profil</h2>
							<NoneStyledUnorderedList>
								<li>
									<Link to={`/profile/settings`}>Profil verwalten</Link>
								</li>
								<li>
									<Link to={`/profile/enrollments`}>Anmeldungen zu Fortbildungen</Link>
								</li>
								<li>
									<Link to={`/profile/bookmarks`}>Bookmarks auf Fortbildungen verwalten</Link>
								</li>
								<li>
									<Link to={`/profile/statistics`}>Informationen für Dozenten</Link>
								</li>
							</NoneStyledUnorderedList>
						</Col>
					</Row>

					<Row>
						<Col as="section" md={4}>
							<h2>Infos</h2>
							<NoneStyledUnorderedList>
								<li key="faq">
									<Link to={`/faq`}>Fragen und Antworten</Link>
								</li>
								<li key="hints">
									<Link to={`/hints`}>Hinweise zur Benutzung</Link>
								</li>
								<li key="antrag_freistellung">
									<Link
										to="/pdf/antrag_freistellung.pdf"
										title="Freistellungsantrag"
										target="Blank"
										reloadDocument
									>
										Freistellungsantrag
									</Link>
								</li>
								<li key="contact">
									<Link to={`/contact`}>Ansprechpartner/-innen</Link>
								</li>
								<li key="barrierfree">
									<Link to={`/barrierfree`}>Erklärung zur digitalen Barrierefreiheit</Link>
								</li>
								<li key="privacy">
									<Link to={`/privacy`}>Datenschutzerklärung</Link>
								</li>
								<li key="imprint">
									<Link to={`/imprint`}>Impressum</Link>
								</li>
								<li key="sitemap">
									<Link to={`/sitemap`}>Sitemap</Link>
								</li>
							</NoneStyledUnorderedList>
						</Col>

						<Col as="section" md={4}>
							<h2>Kooperationen</h2>
							<NoneStyledUnorderedList>
								<li key="LISUM">
									<a href="https://lisum.berlin-brandenburg.de/lisum" target="LISUM">
										LISUM
									</a>
								</li>
								<li key="SFBB">
									<a
										href="https://sfbb.berlin-brandenburg.de"
										target="SFBB"
									>
										SFBB
									</a>
								</li>
								<li key="VHS">
									<a href="https://www.berlin.de/vhs/" target="VHS">
										VHS
									</a>
								</li>
							</NoneStyledUnorderedList>
						</Col>
					</Row>
				</Container>
			</main>
		</>
	);
};

export default Sitemap;
