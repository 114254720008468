import { Field } from 'formik';
import { Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { mail, password, passwordConfirm } from '../../common/validations';

export const accessSchema = Yup.object().shape({
	email: mail,
	password,
	passwordRepetition: passwordConfirm('password'),
	conditions: Yup.bool().oneOf([true], 'Um sich zu registrieren, müssen Sie die AGBs akzeptieren')
});

export default function Access({ onChange, initValues }) {
	return (
		<fieldset>
			<legend className="visually-hidden">Zugangsdaten</legend>
			<Field name="email">
				{({ field, form, meta }) => (
					<Form.Group className="mb-3" controlId={field.name}>
						<Form.Label>
							<strong>E-Mail</strong>
						</Form.Label>
						<Form.Control
							{...field}
							required
							autoComplete="email"
							type="email"
							placeholder="Dienstliche E-Mail-Adresse"
							isInvalid={!!meta.error}
						/>
						<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
					</Form.Group>
				)}
			</Field>
			<Row className="mb-3">
				<Field name="password">
					{({ field, form, meta }) => (
						<Form.Group className="mb-3" controlId={field.name}>
							<Form.Label>
								<strong>Passwort</strong>
							</Form.Label>
							<Form.Control
								{...field}
								required
								autoComplete="new-password"
								type="password"
								placeholder="Passwort"
								isInvalid={!!meta.error}
								aria-describedby="passwordHelpBlock"
							/>
							<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
						</Form.Group>
					)}
				</Field>
				<Field name="passwordRepetition">
					{({ field, form, meta }) => (
						<Form.Group className="mb-3" controlId={field.name}>
							<Form.Label>
								<strong>Passwort wiederholen</strong>
							</Form.Label>
							<Form.Control
							{...field}
								required
								autoComplete="new-password"
								type="password"
								placeholder="Passwort wiederholen"
								isInvalid={!!meta.error}
							/>
							<Form.Control.Feedback type="invalid">
								{meta.error}
							</Form.Control.Feedback>
						</Form.Group>
					)}
				</Field>
				<Form.Text id="passwordHelpBlock" muted>
					Ihr Passwort muss Klein- und Großbuchstaben, Zahlen und Sonderzeichen enthalten und
					mindestens 8 Zeichen lang sein. Erlaubte Sonderzeichen sind nur:{' '}
					<strong>! @ # $ % ^ & * </strong>
				</Form.Text>
			</Row>
			<Field name="conditions">
				{({ field, form, meta }) => (
					<Form.Group className="mb-3" controlId={field.name}>
						<Form.Check
							{...field}
							style={{ fontSize: '14px' }}
							checked={field.value}
							type="switch"
							required
							label="Hiermit gebe ich meine Einwilligung im Sinne des Art. 6 Abs. 1a der Datenschutzgrundverordnung (DSGVO) zur Verarbeitung meiner Daten für den Zweck meiner Teilnahme an Angeboten der Fortbildung Berlin. Ich kann diese Einwilligung jederzeit widerrufen (siehe Datenschutzerklärung auf dieser Webseite)."
							isInvalid={!!meta.error}
							feedback={meta.error}
							feedbackType="invalid"
						/>
					</Form.Group>
				)}
			</Field>
		</fieldset>
	);
}
