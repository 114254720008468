import PropTypes from 'prop-types';

export const WizardStep = ({ children }) => children;

WizardStep.propTypes = {
	/** The content of the wizard step */
	children: PropTypes.node.isRequired,
	/** An optional function for submitting */
	onSubmit: PropTypes.func,
	/** An optional function for validating the step */
	validationSchema: PropTypes.any
};
