import { Accordion } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';

import TrainingsService from '../../services/trainings.service';
import { Title, SubTitle } from '../pages/Title';
import { ContentDescription } from '.';
import Trainings from './trainings';
import { InstitutionSelector } from '../domain.core';
import { QueryKey } from '../../data/QueryKey';
import { Paragraph } from '../core';
import { Form, Formik, useFormikContext } from 'formik';
import HeadLogo from "../core/headLogo";
import myLogo from "../../images/schifTeam.png";

const AutoSubmitIfFormValid = () => {
	const { submitForm, isValid, values } = useFormikContext();

	useEffect(() => {
		if (isValid) {
			submitForm();
		}
	}, [isValid, submitForm, values]);

	return null;
};

export function SchifPage() {
	const [institution, setInstitution] = useState(0);
	const {
		isLoading,
		error,
		data: trainings
	} = useQuery([QueryKey.schifs, institution], () => TrainingsService.getSchifs(institution));
	const title = 'Einen anderen Fortbildungssort auswählen ... ';

	useEffect(() => {
		document.title = 'SchiF | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<HeadLogo
					logo={myLogo}
					logoText={'Schule mit eintrenden Lehrkräften'}
					text="Schulinterne Fortbildungen"
					colorBackground="#F1EEED"
					color="#a80000"
					height={400}
					posLogo={-43}
					posText={+25}
					heightLogo={115}
					textSize={5}
					fontWeight={900}
				/>
			</header>

			<main>
				<SubTitle title="SchiF" align="center" />

				<ContentDescription>
					<Paragraph>
						SchiF ist eine schulinterne Fortbildung, die nach einer Ziel- und Auftragsklärung
						passgenau für das gesamte pädagogische Personal einer Schule oder für ausgewählte
						Schulteams angeboten wird. Haben Sie Interesse an einer SchiF, wenden Sie sich bitte an
						die regionalen Ansprechpartner/-innen.
					</Paragraph>
				</ContentDescription>

				<div style={{ padding: '1rem' }}>
					<Trainings trainings={trainings} isLoading={isLoading} error={error} />
					<Accordion as="section" aria-label="Fortbildungsort wählen">
						<Accordion.Item eventKey="1">
							<Accordion.Header>
								<section>
									<Paragraph style={{ fontSize: '1.3rem', marginBottom: '0.5rem' }}>
										{title}
									</Paragraph>
									<Paragraph style={{ fontSize: '0.9rem' }}>
										Hinweis: Wählen Sie nur eine SchiF, die nicht an Ihrer Bildungseinrichtung
										stattfindet, wenn Sie dazu eingeladen wurden.
									</Paragraph>
								</section>
							</Accordion.Header>
							<Accordion.Body>
								<Formik
									onSubmit={(values) => setInstitution(values.institutions)}
									initialValues={{}}
								>
									{() => {
										return (
											<Form>
												<InstitutionSelector />
												<AutoSubmitIfFormValid />
											</Form>
										);
									}}
								</Formik>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
			</main>
		</>
	);
}
