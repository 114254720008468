import React from 'react';
import { Button } from 'react-bootstrap';

import { useActions } from './useActions';

export function TrainingButtons(props) {
	const actions = useActions(props.training);

	const getVariant = (action) => {
		return action.type + ' my-1 w-100';
	};

	const getButtons = () => {
		return actions?.map((action, count) =>
			React.createElement(
				props.as,
				{ ...props, key: count },
				<Button variant={getVariant(action)} disabled={!action.enabled} onClick={action.action}>
					{action.text}
				</Button>
			)
		);
	};

	return getButtons();
}
