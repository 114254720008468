import React from 'react';
import { Spinner } from 'react-bootstrap';

export function LoadingScreen({ children, isLoading }) {
	// padding 40px
	const spinnerStyle = {
		position: 'absolute',
		padding: '3rem',
		zIndex: 5,
		top: '50%',
		left: '50%',
		marginLeft: '-2.5rem',
		marginTop: '-2.5rem'
	};

	const divStyle = isLoading
		? {
				filter: 'blur(8px)',
				minHeight: '200px'
		  }
		: {};

	return (
		<>
			<div style={{ position: 'relative' }}>
				{isLoading && <Spinner style={spinnerStyle} animation="border" role="status" />}
				<div style={divStyle}>{children}</div>
			</div>
		</>
	);
}
