import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import logoHead from './img/privacy-policy.jpg';
import { Title, SubTitle } from './Title';


const PageParagraph = ({ title, padding, children }) => {
	const paddingStyle = padding ? { padding } : {};

	return (
		<>
			<h4>{title}</h4>
			<hr style={{ marginTop: '-0.25em', color: '#777'}}/>
			<p style={paddingStyle}>{children}</p>
		</>
	);
};

function CryptedMailLink(name, domain, tld) {
	const handleClick = (e) => {
		e.preventDefault();
		window.location.href = `mailto:${name}@${domain}.${tld}?subject=Datenschutzanfrage zu fortbildungen.berlin`;
	};

	return (
		<a href="#" onClick={handleClick} className="myCryptedMail">
			{name}@{domain}.{tld}
		</a>
	);
}


const Privacy = () => {
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		document.title = 'Datenschutz | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
			</header>

			<main style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>

				<SubTitle title="Datenschutzerklärung" />

				<PageParagraph title='Datenschutz' padding="0.5em">
					Die Senatsverwaltung für Bildung, Jugend und Familie, als Verantwortliche für diesen Internetauftritt, legt großen Wert auf den Schutz Ihrer
					persönlichen Daten. Wir möchten, dass Sie sich beim Besuch unserer Fortbildungswebsite https://fortbildungen.berlin sicher fühlen.<br />
					Im Zuge der Registrierung auf unserer Plattform bitten wir Sie, folgende persönliche Informationen anzugeben: <br />
					<strong>Anrede, Vorname, Nachname, Ihre Schule und E-Mail-Adresse</strong>. <br />
					Diese Angaben sind notwendig, um ein Benutzerkonto für Sie anzulegen und Ihnen dadurch Zugang zu unseren Fortbildungsangeboten zu ermöglichen.
					<br />
					Zusätzlich zur Basisdatenerfassung verarbeiten wir auch essenzielle Metadaten, die sowohl bei der Anmeldung als auch bei der Organisation unserer Fortbildungen notwendig sind.
					Wir bieten Ihnen zudem die Möglichkeit, freiwillig Informationen über eine eventuelle Schwerbehinderung zu hinterlegen.
					Diese Daten helfen uns, Ihnen bei bestimmten Veranstaltungen gegebenenfalls eine bevorzugte Teilnahme zu ermöglichen.
					Es ist uns wichtig zu betonen, dass wir ausschließlich die für die Durchführung der Fortbildungen erforderlichen personenbezogenen Daten erheben.
					<br />
					Wir versichern Ihnen, dass Ihre Daten nicht an Dritte weitergegeben werden. Bitte beachten Sie jedoch, dass die Datenübertragung im
					Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann und ein vollständiger Schutz der Daten vor dem Zugriff durch Unbefugte
					nicht gewährleistet werden kann.
				</PageParagraph>

				<PageParagraph title='Verantwortlich' padding='0.5rem'>
					Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU- Datenschutzgrundverordnung (DSGVO), ist:<br />
					Senatsverwaltung für Bildung, Jugend und Familie<br />
					Anja Herpell (II E)<br />
					Referatsleiterin<br />
					Bernhard-Weiß-Straße 6<br />
					10178 Berlin-Mitte<br />
					Tel: 030 902275123<br />
					Fax: 030 902275468<br />
					E-Mail: {CryptedMailLink("anja.herpell","senbjf.berlin", "de")}
				</PageParagraph>

				<PageParagraph title='Behördlicher Datenschutzbeauftragter' padding='0.5rem'>
					Ümit Teke<br />
					Senatsverwaltung für Bildung, Jugend und Familie<br />
					Bernhard-Weiß-Str. 6<br />
					10178 Berlin<br />
					E-Mail: {CryptedMailLink("Uemit.Teke","senbjf.berlin", "de")}
				</PageParagraph>

				<PageParagraph title='Ihre Betroffenenrechte' padding='0.5rem 0.5em 0em 0.5em'>
					Unter den angegebenen Kontaktdaten der Senatsverwaltung können Sie jederzeit folgende Rechte ausüben:
					<ul>
						<li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Rechtsgrundlage: Art. 15 DSGVO),</li>
						<li>Berichtigung unrichtiger personenbezogener Daten (Rechtsgrundlage: Art. 16 DSGVO),</li>
						<li>Löschung Ihrer bei uns gespeicherten Daten (Rechtsgrundlage: Art. 17 DSGVO),</li>
						<li>Berichtigung unrichtiger personenbezogener Daten (Rechtsgrundlage: Art. 16 DSGVO),</li>
						<li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Rechtsgrundlage: Art. 18
							DSGVO),
						</li>
						<li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Rechtsgrundlage: Art. 21 (1) DSGVO) und</li>
						<li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben
							oder einen Vertrag mit uns abgeschlossen haben (Rechtsgrundlage: Art. 20
							DSGVO).
						</li>
						<li>Ihre Daten zu einer Fortbildung werden fünf Jahre nach dem Fortbildungstermin automatisch gelöscht.</li>
					</ul>

					Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen (Rechtsgrundlage: Art. 21 (1) DSGVO).
					Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden:<br />
					<p style={{ padding: '1rem' }}>
						Berliner Beauftragte für Datenschutz und Informationsfreiheit <br />
						Friedrichstr. 219<br />
						Besuchereingang: Puttkamerstr. 16 – 18 (5. Etage)<br />
						10969 Berlin<br />
						Telefon: 030 13889-0<br />
						Telefax: 030 2155050<br />
						E-Mail: {CryptedMailLink("mailbox","datenschutz-berlin", "de")}
					</p>
				</PageParagraph>

				<PageParagraph title='Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte' padding='0.5rem'>
					Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken.
					Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt.
					Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn: <br />
					<ul>
						<li> Sie Ihre ausdrückliche Einwilligung dazu erteilt haben (Rechtsgrundlage: Art. 6 (1) a) DSGVO),</li>
						<li> die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist (Rechtsgrundlage: Art. 6 (1) b) DSGVO),</li>
						<li> die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist (Rechtsgrundlage: Art. 6 (1) c) DSGVO),</li>
						<li> die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht,
							 dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben ((Rechtsgrundlage: Art. 6 (1) f) DSGVO).
						</li>
					</ul>
				</PageParagraph>

				<PageParagraph title='Löschung bzw. Sperrung der Daten' padding='0.5rem'>
					Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange,
					wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen
					(Rechtsgrundlage: Art. 17 (1) a) DSGVO). Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig
					und entsprechend den gesetzlichen Vorschriften gelöscht.
				</PageParagraph>

				<PageParagraph title='Erfassung allgemeiner Informationen beim Besuch unserer Website (Server-Log-Files)' padding='0.5rem'>
					<p>
						Usere Server im Konrad-Zuse-Institut erheben beim Besuch unserer Seiten automatisch Informationen und speichert sie in so genannten Server-Log Files,
						die Ihr Browser automatisch an uns übermittelt.<br />
						Dies sind:
						<ul>
							<li>Browsertyp und Browserversion</li>
							<li>verwendetes Betriebssystem</li>
							<li>Referrer URL</li>
							<li>Hostname des zugreifenden Rechners</li>
							<li>Uhrzeit der Serveranfrage</li>
						</ul>
						Diese Daten sind nicht bestimmten Personen zuzuordnen. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
						Wir behalten jedoch uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
					</p>
					<p>
						Gründe für die Speicherung von Log-Dateien durch Serveranbieter gemäß DSGVO<br />
						<ul>
							<li><strong>Sicherheit und Netzwerkintegrität</strong>: Erkennung und Reaktion auf Sicherheitsverletzungen, ungewöhnliche Aktivitäten und Angriffe.</li>
							<li><strong>Fehlerbehebung und Systemwartung</strong>: Wichtige Informationen für die Wartung und Fehlerbehebung des Servers.</li>
							<li><strong>Rechtliche Compliance und Beweisführung</strong>: Erfüllung gesetzlicher Vorschriften und Bereitstellung von Beweisen bei Cyberkriminalität oder rechtlichen Auseinandersetzungen.</li>
							<li><strong>DSGVO-Konformität</strong>: Rechtfertigung der Datenverarbeitung, Transparenz und Sicherheit im Umgang mit personenbezogenen Daten.</li>
							<li><strong>Datensparsamkeit und Datenschutz</strong>: Einhaltung des Prinzips der Datensparsamkeit und Schutz personenbezogener Daten durch Pseudonymisierung und Verschlüsselung.</li>
						</ul>
					</p>
				</PageParagraph>

				<PageParagraph title='Informationen über unseren Serverbetreiber (Konrad Zuse Institut Berlin (ZIB))' padding='0.5rem'>
					<p>
						Im Rahmen unserer Verpflichtung zur Transparenz und zum Datenschutz möchten wir Sie über unseren
						Serverbetreiber, das Konrad Zuse Institut Berlin (ZIB), informieren. Das ZIB ist ein renommiertes
						Forschungsinstitut für angewandte Mathematik und Datenverarbeitung in Berlin und spielt eine wichtige
						Rolle bei der Bereitstellung und dem Management unserer Serverinfrastruktur.
					</p>

					<ul>
						<li><strong>Serverbetreiber</strong>: <a href={"https://www.zib.de/"} target={"Zuse"} title={"Link zum Zuse Institut"}>Konrad Zuse Institut Berlin (ZIB)</a>.</li>
						<li><strong>Funktion</strong>: Bereitstellung und Management unserer Serverinfrastruktur.</li>
						<li><strong>Infrastruktur</strong>: Leistungsfähige und sichere Serverinfrastruktur.</li>
						<li><strong>Datenschutz und Sicherheit</strong>: Einhaltung der Datenschutzstandards der DSGVO.</li>
						<li><strong>Forschung und Innovation</strong>: Führendes Forschungsinstitut für angewandte Mathematik und Datenverarbeitung.</li>
						<li><strong>Speicherung und Verarbeitung von Daten</strong>: Auf Servern des ZIB, mit modernen Sicherheitsmaßnahmen geschützt.</li>
						<li><strong>Datenschutzrechte</strong>: Recht auf Auskunft, Berichtigung, Löschung und Einschränkung der Verarbeitung Ihrer Daten.</li>
						<li><strong>Keine Weitergabe an Dritte</strong>: Daten werden nicht ohne Ihre Zustimmung oder gesetzliche Verpflichtung weitergegeben.</li>
					</ul>
				</PageParagraph>

				<PageParagraph title='Verwendung von Cookies auf unserer Website' padding='0.5rem'>
					<p>
						Wenn Sie auf unsere Website zugreifen, werden automatisch mittels Cookies Informationen erfasst.<br />
						Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
						effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
						Wir möchten Ihnen gerne erklären, welche Cookies wir verwenden und warum sie wichtig sind:
					</p>
					<ul>
						<li>_csrf Cookie: Dieser Cookie hilft uns, Ihre Sicherheit zu gewährleisten, wenn Sie auf unserer Website interagieren. Er stellt sicher, dass alle Aktionen, die Sie auf der Website durchführen (wie das Ausfüllen eines Formulars), wirklich von Ihnen stammen und nicht von jemand anderem. Sie können es sich wie einen digitalen Ausweis vorstellen, der bestätigt, dass Ihre Anfragen echt sind.</li>
						<li>_identity Cookie: Dieser Cookie erinnert sich an Sie, wenn Sie unsere Website besuchen. Er speichert eine Art digitales Kennzeichen, das uns sagt, dass Sie es sind. So müssen Sie sich nicht jedes Mal neu anmelden, wenn Sie die Seite besuchen. Dieser Cookie bleibt eine Weile bestehen, also auch wenn Sie die Seite verlassen und später wiederkommen, erkennen wir Sie wieder.</li>
						<li>PHPSESSID: Dieser Cookie ist wie ein kurzfristiges Gedächtnis für Ihre Besuche. Er speichert eine einzigartige Nummer für Ihre Sitzung, damit wir uns an Dinge erinnern können, die Sie auf der Seite tun, während Sie surfen. Zum Beispiel, wenn Sie etwas in Ihren Warenkorb legen und zu einer anderen Seite gehen, sorgt dieser Cookie dafür, dass Ihr Warenkorb nicht verschwindet. Er verschwindet normalerweise, wenn Sie den Browser schließen.</li>
					</ul>
					Diese Cookies sind da, um Ihre Erfahrung auf unserer Website sicherer und benutzerfreundlicher zu gestalten. Sie enthalten keine persönlichen Informationen und helfen uns einfach dabei, die Website für Sie besser zu gestalten.
					Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten ist nur die verantwortliche Stelle.
				</PageParagraph>

				<PageParagraph title='Registrierung auf unserer Webseite' padding='0.5rem'>
					<p>
						Bei der Registrierung für die Nutzung unserer personalisierten Leistungen werden einige personenbezogene Daten erhoben,
						wie Name Schule und E-Mail-Adresse (Rechtsgrundlage: Art. 6 (1) a) DSGVO).
						Sind Sie bei uns registriert, können Sie auf Inhalte und Leistungen zugreifen, die wir nur registrierten Nutzern anbieten.
						<br />
						Fast alle von uns gespeicherten Daten können Sie jederzeit, nach Anmeldung auf unserer Website, in Ihrem Profil einsehen und teilweise verändern.
						Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit Auskunft über die von uns über Sie gespeicherten personenbezogenen Daten.
						Gerne berichtigen bzw. löschen wir diese auch auf Ihren Wunsch, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
						Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die Kontaktmöglichkeiten zur Senatsverwaltung.

					</p>
				</PageParagraph>

				<PageParagraph title='SSL-Verschlüsselung' padding='0.5rem'>
					<p>
						Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
						Inhalte, wie zum Beispiel der Anfragen, die Sie an uns senden, eine SSL-Verschlüsselung.
						Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
						&quot;http://&quot; auf &quot;https://&quot; wechselt und an dem Schloss-Symbol in Ihrer
						Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
						übermitteln, nicht von Dritten mitgelesen werden.
					</p>
				</PageParagraph>


				<PageParagraph title='Kontakt' padding='0.5rem'>
					<p>
						Treten Sie bzgl. Fragen jeglicher Art per E-Mail mit uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung (Rechtsgrundlage: Art. 6 (1) a) DSGVO).
						Hierfür ist die Angabe einer validen E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben.
						Die Angabe weiterer Daten ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.<br />
						Nach Erledigung der von Ihnen gestellten Anfrage werden personenbezogene Daten gelöscht (Rechtsgrundlage: Art. 17 (1) a) DSGVO).
					</p>
				</PageParagraph>

				<PageParagraph title='Änderung unserer Datenschutzbestimmungen' padding='0.5rem'>
					<p>
						Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
					</p>
				</PageParagraph>

				<PageParagraph title='Kontakt zur Senatsverwaltung' padding='0.5rem'>
					<p>
						Bernhard-Weiß-Straße 6<br />
						10178 Berlin-Mitte<br />
						Tel: 030 902275050<br />
						E-Mail: {CryptedMailLink("post","senbjf.berlin", "de")}
					</p>
				</PageParagraph>
			</main>
		</>
	);
};
export default Privacy;
