import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../common';
import { QueryKey } from '../../../data/QueryKey';
import useNotification from '../../../hooks/useNotification';
import useUser from '../../../hooks/useUser';
import AuthService from '../../../services/auth.service';
import ProfileService from '../../../services/profile.service';
import { LoadingScreen, ModalFooter, RoutingModal } from '../../core';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { password, passwordConfirm } from '../../../common/validations';

const schema = Yup.object().shape({
	password: Yup.string().required('Bitte geben Sie Ihr Passwort ein'),
	newPassword: password,
	passwordRepetition: passwordConfirm('newPassword')
});

export const Password = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	const { setInfo, setError } = useNotification();

	const { isLoading, data: profile } = useQuery(
		[QueryKey.userProfile, user?.username],
		ProfileService.getProfile
	);

	const { mutate: mutatePassword, isLoading: isMutating } = useMutation(
		(values) => {
			return AuthService.updateUserAsync(values);
		},
		{
			onSuccess: () => {
				setInfo('Passwort erfolgreich geändert');
				navigate(-1);
			},
			onError: (error) => setError(getErrorMessage(error), 'passwordError')
		}
	);

	return (
		<RoutingModal title="Passwort ändern">
			<LoadingScreen isLoading={isLoading || isMutating}>
				{profile && (
					<Formik
						onSubmit={mutatePassword}
						validationSchema={schema}
						initialValues={{
							passwort: '',
							newPassword: '',
							passwordRepetition: ''
						}}
					>
						{({ handleSubmit, values, handleChange, handleBlur, dirty, isValid, errors }) => (
							<form noValidate onSubmit={handleSubmit} aria-describedby="passwordError">
								<fieldset>
									<legend className="visually-hidden">Passwort ändern</legend>
									<fieldset>
										<legend>Bisheriges Passwort</legend>
										<Form.Group className="pb-2" controlId="currentPassword">
											<Form.Label visuallyHidden>
												<strong>Bisheriges Passwort</strong>
											</Form.Label>
											<Form.Control
												required
												autoComplete="current-password"
												type="password"
												name="password"
												placeholder="Ihr bisheriges Passwort bitte"
												isInvalid={!!errors.password}
												value={values.password}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.password}
											</Form.Control.Feedback>
										</Form.Group>
									</fieldset>
									<fieldset>
										<legend>Neues Passwort</legend>
										<Form.Group className="mb-3" controlId="password">
											<Form.Label>
												<strong>Passwort</strong>
											</Form.Label>
											<Form.Control
												required
												autoComplete="new-password"
												type="password"
												placeholder="Passwort"
												isInvalid={!!errors.newPassword}
												aria-describedby="passwordHelpBlock"
												name="newPassword"
												value={values.newPassword}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.newPassword}
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group className="mb-3" controlId="passwordRepetition">
											<Form.Label>
												<strong>Passwort wiederholen</strong>
											</Form.Label>
											<Form.Control
												required
												autoComplete="new-password"
												type="password"
												placeholder="Passwort wiederholen"
												isInvalid={!!errors.passwordRepetition}
												name="passwordRepetition"
												value={values.passwordRepetition}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.passwordRepetition}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Text id="passwordHelpBlock" muted>
											Ihr Passwort muss Klein- und Großbuchstaben, Zahlen und Sonderzeichen
											enthalten und mindestens 8 Zeichen lang sein. Erlaubte Sonderzeichen sind nur:{' '}
											<strong>! @ # $ % ^ & * </strong>
										</Form.Text>
									</fieldset>
								</fieldset>

								<ModalFooter submitDisabled={!isValid || !dirty} />
							</form>
						)}
					</Formik>
				)}
			</LoadingScreen>
		</RoutingModal>
	);
};
