import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import colors from '../../common/colors';
import useNotification from '../../hooks/useNotification';

export function NotificationToaster() {
	const { notifications, remove } = useNotification();

	const getTypeInformations = (type) => {
		if (type === 'info')
			return {
				bg: colors.notificationInfo,
				header: 'Information'
			};
		else if (type === 'warning')
			return {
				bg: colors.notificationWarning,
				header: 'Warnung'
			};
		else if (type === 'error')
			return {
				bg: colors.notificationError,
				header: 'Fehler'
			};
	};

	return (
		<ToastContainer
			as="aside"
			aria-label="Benachrichtigungen"
			style={{ padding: '2rem' }}
			position="bottom-end"
			containerPosition="fixed"
		>
			{notifications.map((notification) => {
				console.log(notification);
				const typeInfos = getTypeInformations(notification.type);
				return (
					<Toast
						key={notification.createDate}
						bg={typeInfos.variant}
						onClose={() => remove(notification.createDate)}
					>
						<Toast.Header>
							<strong className="me-auto" style={{ color: colors.notificationHeader }}>
								{typeInfos.header}
							</strong>
						</Toast.Header>

						<Toast.Body
							style={{
								backgroundColor: typeInfos.bg,
								color: colors.notificationText
							}}
							id={notification.id}
						>
							{notification.message}
						</Toast.Body>
					</Toast>
				);
			})}
		</ToastContainer>
	);
}

export const info = 'info';
export const warning = 'warning';
export const error = 'error';
