import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const ModalFooter = ({ onSubmit, submitDisabled, size = "sm" }) => {
	const navigate = useNavigate();

	return (
		<>
			<hr />

			<Button
				className="float-begin"
				size={size}
				variant="outline-dark"
				name="cancel"
				id="cancel"
				onClick={() => navigate(-1)}
			>
				Abbrechen
			</Button>

			<Button
				className="float-end"
				size={size}
				variant="outline-success"
				name="change"
				id="change"
				onClick={onSubmit}
				disabled={submitDisabled}
                type="submit"
			>
				Speichern
			</Button>
		</>
	);
};