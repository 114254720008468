import parse from 'html-react-parser';
import { Image, Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';

import TrainingsService from '../services/trainings.service';
import { Title, SubTitle } from './pages/Title';
import logoHead from './pages/img/buch_mobile2.jpg';
import logoRight from './pages/img/handsOn.jpg';
import styled from 'styled-components';
import colors from '../common/colors';
import { useEffect } from 'react';
import { HorizontalDivider } from './core';

const SubHeading = styled.h3`
	font-size: 1.25rem;
`;

export function HomePage() {
	const { data: instructions } = useQuery('instructions', TrainingsService.getInformationHints);
	const { data: cityFocals } = useQuery('cityFocals', TrainingsService.getCityWideFocalPoints);
	const { data: current } = useQuery('current', TrainingsService.getCurrentHints);

	useEffect(() => {
		document.title = 'Startseite | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
			</header>
			<main style={{ padding: '1rem' }}>
				<Row>
					<Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
						<SubTitle title="Willkommen" align="left" />
						<HorizontalDivider />
						<section>{!!current && parse(current)}</section>
						<SubHeading style={{ color: colors.primarySubHeader }}>
							FORTBILDUNGSSCHWERPUNKTE 2024/2025
						</SubHeading>
						Kompetenzen für ein Leben in der digitalen Welt:
						<ul>
							{!!cityFocals && cityFocals.map((focal) => <li key={focal.id}>{focal.text}</li>)}
						</ul>
					</Col>
					<Col as="aside" xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ marginTop: '2rem' }}>
						<Image
							style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
							src={logoRight}
							alt="Logo Verzeichnis"
							fluid
						/>
						{!!instructions && parse(instructions)}
					</Col>
				</Row>
			</main>
		</>
	);
}
