/*
 * Storage for svg-icons we use
 *
 */

import './faqStyle.css';

const myIcon = (name, size, style, title) => {
	let svgIcon = '';
	switch (name) {
		case 'detail':
			svgIcon += `<svg stroke="currentColor" aria-label="${title}" fill="none" stroke-width="0" viewBox="0 0 24 24" height="${size}" width="${size}"  xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><desc></desc>><path fill="none" stroke="#000" stroke-width="2" d="M16,16 L23,23 L16,16 Z M10,18 C14.418278,18 18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 Z M10,15 L10,5 M5,10 L15,10"></path></svg>`;
			break;
		case 'cert':
			svgIcon += `<svg stroke="currentColor" aria-label="${title}" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><desc></desc><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="15" cy="15" r="3"></circle><path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path><path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73"></path><line x1="6" y1="9" x2="18" y2="9"></line><line x1="6" y1="12" x2="9" y2="12"></line><line x1="6" y1="15" x2="8" y2="15"></line></svg>`;
			break;
		case 'copyLink':
			svgIcon += `<svg stroke="currentColor" aria-label="${title}" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><desc></desc><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"></path></svg>`;
			break;
		case 'termin':
			svgIcon += `<svg stroke="currentColor" aria-label="${title}" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" color="darkBlue" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><path d="M19 4h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm.002 16H5V8h14l.002 12z"></path><path d="m11 17.414 5.707-5.707-1.414-1.414L11 14.586l-2.293-2.293-1.414 1.414z"></path></svg>`;
			break;
		case 'registered':
			svgIcon = `<svg stroke="currentColor" aria-label="${title}"  fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><path fill="none" d="M0 0h24v24H0z"></path><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>`;
			break;
		case 'approved':
			svgIcon = `<svg stroke="currentColor" aria-label="${title}"  fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"></path></svg>`;
			break;
		case 'cancelled':
			svgIcon = `<svg stroke="currentColor" aria-label="${title}"  fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><path d="M256 16C123.45 16 16 123.45 16 256s107.45 240 240 240 240-107.45 240-240S388.55 16 256 16zm0 60c99.41 0 180 80.59 180 180s-80.59 180-180 180S76 355.41 76 256 156.59 76 256 76zm-80.625 60c-.97-.005-2.006.112-3.063.313v-.032c-18.297 3.436-45.264 34.743-33.375 46.626l73.157 73.125-73.156 73.126c-14.63 14.625 29.275 58.534 43.906 43.906L256 299.906l73.156 73.156c14.63 14.628 58.537-29.28 43.906-43.906l-73.156-73.125 73.156-73.124c14.63-14.625-29.275-58.5-43.906-43.875L256 212.157l-73.156-73.125c-2.06-2.046-4.56-3.015-7.47-3.03z"></path></svg>`;
			break;
		case 'present':
			svgIcon = `<svg stroke="currentColor" aria-label="${title}"  fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"></path></g></svg>`;
			break;
		case 'feedback' :
			svgIcon = `<svg stroke="currentColor" aria-label="${title}" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><path d="M8 12c2.28 0 4-1.72 4-4s-1.72-4-4-4-4 1.72-4 4 1.72 4 4 4zm0-6c1.178 0 2 .822 2 2s-.822 2-2 2-2-.822-2-2 .822-2 2-2zm1 7H7c-2.757 0-5 2.243-5 5v1h2v-1c0-1.654 1.346-3 3-3h2c1.654 0 3 1.346 3 3v1h2v-1c0-2.757-2.243-5-5-5zm9.364-10.364L16.95 4.05C18.271 5.373 19 7.131 19 9s-.729 3.627-2.05 4.95l1.414 1.414C20.064 13.663 21 11.403 21 9s-.936-4.663-2.636-6.364z"></path><path d="M15.535 5.464 14.121 6.88C14.688 7.445 15 8.198 15 9s-.312 1.555-.879 2.12l1.414 1.416C16.479 11.592 17 10.337 17 9s-.521-2.592-1.465-3.536z"></path></svg>`
			break;
		case 'addBookmark' :
			svgIcon = `<svg stroke="currentColor" aria-label="${title}" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"></path><path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4z"></path></svg>`
			break;
		case 'delBookmark' :
			svgIcon = `<svg stroke="currentColor" aria-label="${title}" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" style="${style}"><title>${title}</title><path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zM6 6a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1H6z"></path></svg>`
			break;
		default:
			break;
	}
	return svgIcon;
};


/*
 * Storage for questions
 *
 */
export const FaqData = [
	{
		id: 0,
		title: 'An wen richtet sich das Fortbildungsangebot?',
		content: `Das Angebot der Fortbildung Berlin richtet sich an das aktive pädagogische Personal der öffentlichen allgemeinbildenden und beruflichen Schulen des Landes Berlin.<br />
      Nach Maßgabe freier Plätze kann das pädagogische Personal in freier Trägerschaft und von Schulen in freier Trägerschaft an den Fortbildungen teilnehmen.<br />
      Das Angebot kann nicht von Lehrkräften im Vorbereitungsdienst (außer bbVD) in Anspruch genommen werden.`
	},
	{
		id: 1,
		title: 'Wie kann ich am Fortbildungsangebot teilnehmen?',
		content: `Ab sofort haben Sie die Möglichkeit, das Angebotsverzeichnis der Fortbildung Berlin einzusehen und sich zu Fortbildungen anzumelden.<br />
      <ol>
        <li>Dazu loggen Sie sich bitte mit Ihrer <strong>dienstlichen E-Mail-Adresse</strong> und Ihrem Passwort unter <a href=${window.location.origin}/login>Login</a> an.</li>
        <li><strong>Hinweis:</strong> Eventuell waren Sie bisher mit Ihrer privaten E-Mail bei uns registiert. <br />Bitte ändern Sie diese gleich nach erfolgreichem Login auf Ihre dienstliche E-Mail-Adresse im <a href=${window.location.origin}/profile>Profil</a> ab.</li>
        <li>Funktioniert der Login nicht, geben Sie Ihre bisher verwendete E-Mail-Adresse an und lassen Sie das Passwort <a href=${window.location.origin}/resetPassword>zurücksetzen</a>.</li> 
        <li>Haben Sie vergessen, mit welcher E-Mail-Adresse Sie sich bisher in Fortbildung Berlin eingeloggt haben, dann müsssen Sie sich leider neu registieren (Button "Registrieren").</li> 
        <li>Sollten Sie noch keinen Account haben oder Ihr Account nicht mehr existieren, registrieren Sie sich bitte neu (Button "Registrieren").</li>
      </ol>`
	},
	{
		id: 2,
		title: 'Wie registriere ich mich?',
		content: `Wenn Sie zum pädagogischen Personal einer Schule in Berlin gehören, dann:
    <ol>
      <li>Klicken Sie rechts oben auf den Button “Registrieren“.</li>
      <li>Geben Sie in den drei nacheinander erscheinenden Fenstern die erforderlichen Angaben gewissenhaft und vollständig ein. Dazu gehört zwingend Ihre E-Mail-Adresse.</li>
      <li>Notieren Sie sich Ihr angegebenes Passwort.</li>
    </ol>
    Nach Abschluss der Registrierung wird an Ihre nun hinterlegte E-Mail-Adresse eine Bestätigungsmail mit Link gesendet. 
    Bitte ggf. auch im Spam-Ordner nachsehen und den Bestätigungslink in der E-Mail anklicken.<br />
    Erst dann wird intern Ihre Registrierung bearbeitet.<br />
    Innerhalb weniger Tage nachdem Sie Ihre E-Mail-Adresse bestätigt haben, verfügen Sie über einen funktionsfähigen Zugang zu Fortbildung Berlin.<br />
    <span style="font-style: italic">Hinweis</span>: Ihre Daten werden mit denen der Personalstelle abgeglichen. Falls Sie neu eingestellt wurden, kann es mitunter vorkommen, dass Ihre Daten noch nicht bei der Personalstelle verzeichnet sind. <br />
    In diesem Fall haben Sie verschiedene Möglichkeiten:
    <ol>
		<li>Sie wiederholen die Registrierung zu einem späteren Zeitpunkt, sofern Sie noch keine Veranstaltungen der Fortbildung Berlin buchen möchten.</li>
		<li>Sie verwenden eine Mailadresse, aus der die Zugehörigkeit zu Ihrer Schule hervorgeht.</li>
		<li>Sie wenden sich an uns, so dass wir Sie auf anderem Weg bei der Buchung von Fortbildungsveranstaltungen unterstützen können: <a href="mailto:rf@senbjf.berlin.de?subject=E-Mail von der Website">rf@senbjf.berlin.de</a>.</li>
	</ol>
    Sofern Sie zu einer Schule in freier Trägerschaft oder zu einem freien Träger gehören, muss aus Ihrer Mailadresse zwingend die Schulzugehörigkeit ersichtlich sein (z.B. max.mustermann@musterfrau-schule.de)<br />
	Bitte verwenden Sie aus datenschutzrechtlichen Gründen keine Funktionspostfächer.
    <br />&nbsp;<br />Detaillierte Informationen finden Sie hier: <a href=${window.location.origin}/pdf/Kurzinfo_Registrieren.pdf target='Info'>Kurzinfo registrieren</a>
    `
	},
	{
		id: 3,
		title: 'Wie lange dauert die Freischaltung nach Registrierung?',
		content: `Freischaltungen erfolgen in der Reihenfolge des Einganges und können im Idealfall innerhalb von 1 Werktag abgeschlossen werden. 
      Zu Hochzeiten kann eine Freischaltung auch einmal 3-5 Werktage dauern. 
      Bitte sehen Sie von Nachfragen (unter 3-5 Tagen) ab, um den Prozess nicht noch weiter zu verzögern. 
      Sollte Ihr Zugang nach 5 Werktagen noch nicht freigeschaltet sein, melden Sie sich bitte bei uns: <a href="mailto:rf@senbjf.berlin.de?subject=Anfrage zur Registrierung">rf@senbjf.berlin.de</a>`
	},
	{
		id: 4,
		title: 'Wer muss sich (neu) registrieren?',
		content: `
    <ol>
      <li>Lehrkräfte, Erzieher/-innen u. a. m. an öffentlichen Schulen, die zum pädagogischen Personal des Landes Berlin gehören und in ihrem bisherigen Account keine E-Mail-Adresse hinterlegt hatten oder eine E-Mail-Adresse hinterlegt haben, auf die sie nicht mehr zugreifen können.</li>
      <li>Das Personal der SenBJF-Außenstellen und der SIBUZ.</li>
      <li>Das pädagogische Personal in freier Trägerschaft an öffentlichen Schulen.</li>
      <li>Das pädagogische Personal an Schulen in privater Trägerschaft.</li>
    </ol>
    Während registrierte Benutzer/-innen zu (1.) sich zu Fortbildungen anmelden können, werden Benutzer/-innen zu (2.), (3.) und (4.) auf eine Warteliste gesetzt und erhalten nach Maßgabe freier Plätze erst kurz vor der Fortbildung eine Zusage. `
	},
	{
		id: 5,
		title: 'Wie finde ich passende Fortbildungen für mich?',
		content: `
    Klicken Sie auf den Button „Suchen“ in der oberen Menüleiste. 
    Im erscheinenden Suchfenster können Sie ein Unterrichtsfach, ein Stichwort, eine Region, eine Schulart ... eingeben.<br />
    Sie können alternativ in Ihrem Profil (Button oben rechts) im Bereich „Meine Interessen“ eine Vorauswahl treffen.<br />
    Wählen Sie dazu wenigstens ein Thema aus, z. B. Ihre Unterrichtsfächer.
    Mehr als drei Themen sollten nicht gewählt werden.<br />
    Diese Auswahl entscheidet darüber, welche Angebote Ihnen automatisch angezeigt werden.
    Unter „Erweiterte Suche von Fortbildungen“ können Sie bei Bedarf die Suche noch weiter einschränken.
    <br />&nbsp;<br />Detaillierte Informationen finden Sie hier: <a href=${window.location.origin}/pdf/Kurzinfo_Fortbildungen.pdf target='Info'>Kurzinfo Fortbildungen</a>
    `
	},
	{
		id: 6,
		title: 'Wie melde ich mich kurzfristig von Fortbildungen ab?',
		content: `
    Klicken Sie in Ihrem Profil auf den Button „Meine Fortbildungen“.<br />
    Dort finden Sie die Liste Ihrer Fortbildungen, auch die, für die Sie sich angemeldet haben.<br />
    Klicken Sie auf die Fortbildungsnummer und dann auf den roten Button „Absagen“.`
	},
	{
		id: 7,
		title: 'Wie bekomme ich meine Teilnahmebescheinigung?',
		content: `
    Teilnahmebescheinigungen für besuchte Veranstaltungen der letzten fünf Jahre werden als PDF-Dokumente bereitgestellt und können gespeichert oder auch gedruckt werden. Nach dem Einloggen wählen Sie im Profil den Karteireiter [Meine Fortbildungen].  Zeilenweise werden geplante oder besuchte Veranstaltungen angezeigt. Im rechten Bereich jeder Zeile gibt es mehrere Buttons. Wenn die Veranstaltung besucht wurde und die Fortbildungsverwaltung die Veranstaltung abschließend bearbeitet hat, erscheint der Button zum Aufrufen der Teilnahmebescheinigung. Siehe auch FAQ: Welche Bedeutung haben die verwendeten Icons?
    <br />&nbsp;<br />Detaillierte Informationen finden Sie hier: <a href=${window.location.origin}/pdf/Kurzinfo_Teilnahmebescheinigungen.pdf target='Info'>Kurzinfo Teilnahmebscheinigungen</a>
    `
	},
	{
		id: 8,
		title: 'Wie werden Betreuungskosten erstattet?',
		content: `
	"Entstehen durch die Teilnahme an Fort- und Weiterbildungsmaßnahmen unvermeidlich erhöhte Kosten für die Betreuung von Kindern unter zwölf Jahren ..., so sind diese Aufwendungen zu erstatten. Falls erforderlich, sollen sich die Fort- und Weiterbildungseinrichtungen um eine Kinderbetreuungsmöglichkeit in den städtischen Kindertagesstätten oder um andere Kinderbetreuungsmöglichkeiten für die Dauer der Maßnahme bemühen." (Paragraf 9 Abs. 6 Landesgleichstellungsgesetz). 
	<br />Sie können einen Antrag auf Erstattung der Kinderbetreuungskosten stellen.
	<br /> <br />
	"Entstehen durch die Teilnahme an Fort- und Weiterbildungsmaßnahmen unvermeidlich erhöhte Kosten für die Betreuung von ... pflegebedürftigen Angehörigen, so sind diese Aufwendungen zu erstatten. ..." (Paragraf 9 Abs. 6 Landesgleichstellungsgesetz). 
	<br />Sie können einen Antrag auf Erstattung der Betreuungskosten stellen.
	<br /> <br />
	Das Antragsformular finden Sie hier: <a href=${window.location.origin}/pdf/Antrag_Betreuungskosten_Fortbildung.pdf target='Info'>Erstattung von Betreuungskosten</a>
    `
	},
	{
		id: 9,
		title: 'Muss ich mich für Regionalkonferenzen, Netzwerk- und Fachtreffen anmelden?',
		content: `
    Eine Anmeldung ist ausnahmslos zu allen Veranstaltungen erforderlich.<br />&nbsp;<br />

    <strong>Regionalkonferenzen</strong> können Sie unter dem Hauptmenüpunkt [Regionalkonferenzen] finden. Stellen Sie sicher, dass in Ihrem Profil unter Interessen das Fach zu dem Sie Regionalkonferenzen als Fachverantwortlicher besuchen möchten, eingetragen ist. Automatisch werden Ihnen dann unter dem  Hauptmenüpunkt [Regionalkonferenzen] passende Regionalkonferenzen des Fachs und der Region angeboten. 
    <br /><strong>Netzwerk- und Fachtreffen</strong> finden Sie unter dem Hauptmenüpunkt [SUCHEN]. Geben Sie den Titel oder die Veranstaltungsnummer oder den Namen der Veranstaltungsleitung in das Suchfeld ein.
    <br />&nbsp;<br />Detaillierte Informationen finden Sie hier: <a href=${window.location.origin}/pdf/Kurzinfo_Regionalkonferenzen.pdf target='Info'>Kurzinfo Regionalkonferenzen</a>
    `
	},
	{
		id: 10,
		title: 'Muss ich mich für schulinterne Fortbildungen anmelden?',
		content: `
    Ja, das ist auch deshalb erforderlich, damit Sie Ihre Teilnahmebescheinigung im Profil herunterladen können.
    <br />&nbsp;<br />Detaillierte Informationen finden Sie hier: <a href=${window.location.origin}/pdf/Kurzinfo_SchiF.pdf target='Info'>Kurzinfo SchiF</a>
    `
	},
	{
		id: 11,
		title: 'Kann ein Smartphone sinnvoll auf fortbildungen.berlin genutzt werden?',
		content: `
    Ja natürlich, teilweise bietet die Web-App einige Vorteile im Vergleich zur Nutzung auf einem Computer.
    <ol>
      <li>Der Zugriff geht schneller, insbesondere, wenn die Web-App zum Startbildschirm des Smartphone hinzugefügt wurde.</li>
      <li>Buchen einer Fortbildung geht “leicht von der Hand”.</li>
      <li>Man kann sich sehr einfach zu einem Fortbildungsort navigieren lassen.</li>
    </ol>
    <p>
    Klicken Sie auf das Video, um sich die oben genannten Nutzungen zeigen zu lassen.
    <div style="text-align: center">
      <video src="https://fortbildungen.berlin/videos/fbb_smartphone.mp4" width="280" controls> 
        Ihr Browser kann dieses Video nicht wiedergeben.<br />
        Dieser Film zeigt eine Demonstration des Video-Elements.
        Sie können ihn unter <a href="https://fortbildungen.berlin/videos/fbb_smartphone.mp4">Link-Addresse</a> abrufen.
        <track
          label="Deutsch"
          kind="subtitles"
          srclang="de"
          src="/captions/german.vtt" />
        <!--<track
          label="Deutsch mit Aktionen"
          kind="subtitles"
          srclang="de"
          src="/captions/german-actions.vtt" />-->
      </video>
    </div>
    </p>
    `
	},
	{
		id: 12,
		title: 'Was ist bei Online- und Blended-Learning-Veranstaltungen zu beachten?',
		content: `
    Fortbildung Berlin unterstützt nur Big-Blue-Button-Videokonferenzen, da diese durch den Berliner Beauftragten für Datenschutz und Informationsfreiheit genehmigt sind. <br />
    Leitungen von Fortbildungen können daher einen BBB-Konferenzraum erhalten und die "Verbindungsdaten" in der Fortbildungsdatenbank angeben. 
    Sie finden, nachdem Sie durch die Fortbildungsverwaltung zur Fortbildung bestätigitgt wurden, die "Verbindungsdaten" bei der entsprechenden Fortbildung in Ihrem Profil.
    <p style="text-align: center"><img src="/img/FAQ_BBB.png" alt="Erläuterung des BBB-Buttons" style="width: 95%; max-width: 95%"></p>
    `
	},
	{
		id: 13,
		title: 'Welche Bedeutung haben die verwendeten Icons?',
		content: `
      Die folgenden Icons und ihre Bedeutungen werden tabellarisch dargestellt. Diese Icons werden an verschiedenen Stellen dieser Anwendung verwendet.  
      
      
      <table class="table">
        <caption>Tabelle der verwendeten Icons mit Aktionen und ihrer Bedeutungen</caption>
        <thead>
          <tr>
            <th scope="col">Icons </th>
            <th scope="col">Durchführung von Aktionen nach Anklicken</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
				${myIcon(
					'detail',
					'1.5em',
					'color: green; cursor: pointer; margin-right: 0.75em;',
					'Icon Lupe Deteilansicht'
				)}
            </td> 
            <td> 
              Die Veranstaltungskarte mit den vollständigen Informationen wird angezeigt.
            </td>
          </tr>
          
          <tr>
            <td>
				${myIcon(
					'copyLink',
					'1.5em',
					'color: black; cursor: pointer; margin-right: 0.75em;',
					'Icon Link zu einer Fortbildung in Ihre Zwischenablage kopieren.'
				)}
            </td> 
            <td> 
              Der Link zu der entsprechenden Fortbildung wird in Ihre Zwischenablage kopiert. Sie können den Link verwenden, um andere über diese Fortbildung zu informieren.
            </td>
          </tr>
          
          <tr> 
            <td>
				${myIcon(
					'termin',
					'1.5em',
					'color: darkBlue; cursor: pointer; margin-right: 0.75em;',
					'Icon Termin der Fortbildung im Kalender speichern.'
				)}
            </td> 
            <td> 
              Der erste Termin der entsprechenden Fortbildung wird als Kalendereintrag heruntergeladen. Die ICS-Datei trägt das Datum, die Uhrzeit, den Titel und den Link zur entsprechenden Fortbildung auf fortbildungen.berlin sowie eine Erinnerung 24 Stunden vor Beginn der Fortbildung in Ihr Kalenderprogramm oder Ihre App ein.             
            </td>
          </tr>
          
          <tr>
            <td>
				${myIcon(
					'cert',
					'1.5em',
					'color: green; cursor: pointer; margin-right: 0.75em;',
					'Icon PDF-Teilnahmebestätigung herunterladen'
				)}
            </td> 
            <td> 
              Falls Ihre Anwesenheit bestätigt ist, wird die Teilnahmebescheinigung als PDF-Datei heruntergeladen.
            </td>
          </tr>
          
          <tr>
            <td >
				${myIcon(
					'feedback',
					'1.5em',
					'color: darkred; cursor: pointer; margin-right: 0.55em;',
					'Feedback zu einer Fortbildung abgeben'
				)}
            </td> 
            <td> 
              Bis zu zwei Wochen nach der letzten Veranstaltung einer Fortbildung können und sollten Sie ein Feedback abgeben.
            </td>
          </tr>
          
          <tr>
            <td style="vertical-align: top; text-align: center;">
				${myIcon(
					'addBookmark',
					'1.5em',
					'color: green; cursor: pointer; margin-right: 0.75em;',
					'Der Fortbildung ein Bookmark hinzufügen'
				)}
            </td> 
            <td> 
             Jeder Fortbildung, die Sie in der Suche finden, kann ein Bookmark hinzugefügt werden. Die Fortbildungen, die Sie mit einem Bookmark versehen haben, finden Sie in Ihrem Profil unter dem Reiter 'Bookmarks'. Dies erleichtert das Wiederfinden interessanter Fortbildungen, da Sie diese direkt in Ihrer Bookmarkliste vorfinden.
            </td>
          </tr>
          
          <tr>
            <td style="vertical-align: top; text-align: center;">
				${myIcon(
					'delBookmark',
					'1.5em',
					'color: green; cursor: pointer; margin-right: 0.75em;',
					'Das gesetzte Bookmark für die Fortbildung löschen'
				)}
            </td> 
            <td> 
              Das gesetzte Bookmark für die Fortbildung wird gelöscht.
            </td>
          </tr>
          
        </tbody>
      </table>

      <table class="table">
        <caption>Tabelle der verwendeten Icons mit verschiedenen Status und ihrer Bedeutungen</caption>
        <thead>
          <tr>
            <th scope="col">Icons </th>
            <th scope="col">Status des Veranstaltungsbesuchs</th>
          </tr>
        </thead>
        
        <tbody>
          <tr> 
            <td>
				${myIcon(
					'registered',
					'1.5em',
					'color: black; margin-right: 0.75em;',
					'Icon Angemeldet für eine Fortbildung.'
				)}
            </td> 
            <td> 
              Sie haben sich zur Fortbildung angemeldet. Die Anmeldung wurde mit einer E-Mail bestätigt. Eine Zusage zum Besuch der Fortbildung ist (noch) nicht erfolgt.
            </td>
          </tr>
          
          <tr> 
            <td>
				${myIcon(
					'approved',
					'1.5em',
					'color: black; margin-right: 0.75em;',
					'Icon Zur Fortbildung zugelassen'
				)}
            </td> 
            <td> 
              Ihre Anmeldung zu einer Fortbildung ist bestätigt.
            </td>
          </tr>
          
          <tr> 
            <td>
				${myIcon(
					'cancelled',
					'1.5em',
					'color:red; margin-right: 0.75em;',
					'Icon Fortbildung durch Teilnehmer abgesagt'
				)}
            </td> 
            <td> 
              Sie haben Ihre Anmeldung zur Fortbildung wieder abgesagt. Die Absage wurde mit einer E-Mail bestätigt.
            </td>
          </tr>
          
          <tr> 
            <td>
              	${myIcon(
					'present',
					'1.5em',
					'color: green; margin-right: 0.75em;',
					'Icon Fortbildung wurde besucht, der Besuch wurde bestätigt und es wurde ein Feedback gegeben.'
				)}
            </td> 
            <td> 
              Sie haben an der Fortbildung teilgenommen und Ihr Feedback eingereicht. Dieses wurde durch die Fortbildungsverwaltung überprüft und Ihre Anwesenheit bestätigt.
            </td>
          </tr>
          
          <tr> 
            <td>
              	${myIcon(
					'present',
					'1.5em',
					'color: darkOrange; margin-right: 0.75em;',
					'Icon Fortbildung wurde besucht, der Besuch wurde bestätigt'
				)}
            </td> 
            <td> 
              Sie haben an der Fortbildung teilgenommen und kein Feedback abgegeben. Dieses wurde durch die Fortbildungsverwaltung überprüft und Ihre Anwesenheit bestätigt.
            </td>
          </tr>
          
        </tbody>
      </table>
    `
	},
	{
		id: 14,
		title: 'Kann man ein Feedback zu einer Fortbildung geben?',
		content: `
		Ja, es ist ab dem Schuljahr 2023/2024 möglich zu jeder Fortbildung ein Feedback abzugeben. <br />
		Zu bemerken ist, dass das dies nur in einem Zeitraum von zwei Wochen nach der letzten Veranstaltung einer Forbildung möglich ist. <br />
	
		<p>Klicken Sie auf das Video, um sich zeigen zu lassen, wie man ein Feedback zu einer Forbildung erstellen abgeben kann..</p>
		<div style="text-align: center">
		  <video src="https://fortbildungen.berlin/videos/FeedbackFortbildung.mp4" width="280" controls> 
			Ihr Browser kann dieses Video nicht wiedergeben.<br />
			Dieser Film zeigt eine Demonstration des Video-Elements.
			Sie können ihn unter <a href="https://fortbildungen.berlin/videos/FeedbackFortbildung.mp4">Link-Addresse</a> abrufen.
			<track
			  label="Deutsch"
			  kind="subtitles"
			  srclang="de"
			  src="/captions/feedback.vtt" 
			/>
		  </video>
		</div>
		</p>
		`
	},
	{
		id: 15,
		title: 'Kann ich Fortbildungen mit einem Bookmark (Lesezeichen) versehen, um sie schneller wiederzufinden?',
		content: `
		<p>Ja, Sie haben die Möglichkeit, Fortbildungen mit einem Bookmark zu markieren. Um ein Bookmark hinzuzufügen, suchen Sie einfach nach der gewünschten Fortbildung und klicken Sie auf das entsprechende Icon ${myIcon('addBookmark', '1em', 'color: green; cursor: pointer;', 'Der Fortbildung ein Bookmark hinzufügen')}. 
		Die so markierte Fortbildung wird dann in Ihrem Profil unter dem Reiter 'Bookmarks' aufgelistet. Dies ermöglicht es Ihnen, für Sie relevante Fortbildungen schnell und unkompliziert wiederzufinden. <br />
		Wenn Sie ein Bookmark wieder entfernen möchten, klicken Sie erneut auf das Bookmark-Icon ${myIcon('delBookmark', '1em', 'color: green; cursor: pointer;', 'Das gestzte Bookmark für Die Fortbildung entfernen')}.<br />
		<b>Wichtig:</b> Bookmarks sind keine Anmeldungen zu Fortbildungen! Sie sind nur für Sie da, um sich gegebenenfalls später zu einer Fortbildung anzumelden.

		</p>
		`
	},
	{
		id: 16,
		title: 'Wie bekomme ich eine Jahresbescheinigung?',
		content: `
			<p>
				Um eine Jahresbescheinigung zu erhalten, navigieren Sie zuerst zu Ihrem Profil und wählen Sie dann den Reiter „Meine Fortbildungen“. Dort finden Sie oben grüne Buttons, die mit den Halbjahreskennzahlen beschriftet sind. Wenn Sie auf einen dieser Buttons klicken, erhalten Sie eine Liste aller Fortbildungen, die Sie in diesem Schuljahr absolviert haben. Diese Liste kann idealerweise per E-Mail an die Schulleitung gesendet oder ausgedruckt und persönlich abgegeben werden.
			</p>
			<p>
				Der abgedruckte QR-Code auf der Jahresbescheinigung dient zur Überprüfung der Echtheit der im PDF-Dokument hinterlegten Angaben. Hierüber können der Name, das Schuljahr und die Gesamtdauer der belegten Fortbildungen verifiziert werden. Zum Überprüfen kann man entweder den QR-Code scannen oder, wenn das PDF-Dokument auf einem Computersystem geöffnet ist, direkt auf den QR-Code klicken. Für Apple-Geräte ist es zurzeit notwendig, eine separate QR-Code-Scanner-App zu verwenden.
			</p>
		`
	},
	{
		id: 17,
		title: 'Kann ich meine hinterlegte Mailadresse ändern?',
		content: `
			<p>
				Ja, dies ist möglich. <br />Gehen Sie dazu in Ihr Profil. Neben der E-Mail-Adresse ist ein Button [Ändern]. 
				<br />Nach Klick auf diesen Button erscheint ein neues Fenster, in das Sie die gewünschte E-Mailadresse eintragen können. 
				<br /><span style="font-weight: bold;">Vorsicht:</span> Die hier angegebene E-Mail-Adresse ist Ihr Anmeldename. Nehmen Sie evtl. Änderungen sehr gewissenhaft vor, da eine Prüfung der Adresse nicht erfolgt.
				<br />&nbsp;<br /><a href=${window.location.origin}/profile/settings>Jetzt E-Mail-Adresse im Profil ändern!</a>
			</p>
		`
	},
];
