import api from './api';

class EnrollmentssService {
	async getAll() {
		const { data } = await api.get(`/enrollments`);
		return data;
	}

	async enroll(trainingId) {
		const { data } = await api.post(`/enrollments`, {
			trainingId
		});
		return data;
	}

	async cancel(enrollmentId) {
		const { data } = await api.patch(`/enrollments/${enrollmentId}`, {
			isCancelledByUser: true
		});
		return data;
	}

	async reenroll(enrollmentId) {
		const { data } = await api.patch(`/enrollments/${enrollmentId}`, {
			isCancelledByUser: false
		});
		return data;
	}

	async getBookmarks() {
		const { data } = await api.get(`/enrollments/bookmarks`);
		return data;
	}

	async addBookmark(trainingId) {
		const { data } = await api.post(`/enrollments/add-bookmark`, {
			trainingId
		});
		return data;
	}

	async delBookmark(trainingId) {
		const { data } = await api.post(`/enrollments/del-bookmark`, {
			trainingId
		});
		return data;
	}

}
export default new EnrollmentssService();
