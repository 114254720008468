import React from 'react';

const center = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	textAlign: 'center'
};

export class TopLevelErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
		console.error(error);
		console.log(errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
					<div style={center}>
						<h2>Leider ist ein unerwarteter Fehler aufgetreten</h2>
						<p>
							Bitte aktualisieren Sie die Seite und kontaktiere Sie{' '}
							<a href="mailto:support@fortbildungen.berlin">unseren Support</a>, sollte der Fehler
							erneut auftreten.
						</p>
					</div>
				</>
			);
		}
		return this.props.children;
	}
}
