import { useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../common';
import { QueryKey } from '../../../data/QueryKey';
import useNotification from '../../../hooks/useNotification';
import useUser from '../../../hooks/useUser';
import AuthService from '../../../services/auth.service';
import profileService from '../../../services/profile.service';
import { LoadingScreen, ModalFooter, RoutingModal } from '../../core';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import { firstName, lastName } from '../../../common/validations';

const schema = Yup.object().shape({
	title: Yup.string().nullable(),
	firstName,
	lastName,
	gender: Yup.string().required('Bitte wählen Sie ein Geschlecht aus'),
	disability: Yup.bool()
});

export const Person = () => {
	const { user } = useUser();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { setInfo, setError } = useNotification();

	const { isLoading, data: profile } = useQuery(
		[QueryKey.userProfile, user?.username],
		profileService.getProfile
	);

	const { mutate: mutatePerson, isLoading: isMutating } = useMutation(
		(values) => {
			const person = {};

			if (formik.initialValues.title !== formik.values.title) person.title = values.title;
			if (formik.initialValues.firstName !== formik.values.firstName)
				person.firstName = values.firstName;
			if (formik.initialValues.lastName !== formik.values.lastName)
				person.lastName = values.lastName;
			if (formik.initialValues.telPrivate !== formik.values.telPrivate)
				person.telPrivate = values.telPrivate;
			if (formik.initialValues.gender !== formik.values.gender) person.gender = values.gender;
			person.disability = !!values.disability;

			return AuthService.updateUserAsync({ person });
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QueryKey.userProfile);
				setInfo('Persönliche Daten wurden erfolgreich geändert.');
				navigate(-1);
			},
			onError: (error) => setError(getErrorMessage(error), 'updatePersonError')
		}
	);

	const formik = useFormik({
		initialValues: {
			title: profile.title,
			firstName: profile.firstName,
			lastName: profile.lastName,
			gender: profile.gender,
			disability: (profile.status & 4) !== 0
		},
		validationSchema: schema,
		onSubmit: mutatePerson
	});

	return (
		<RoutingModal title="Persönliche Daten ändern">
			<LoadingScreen isLoading={isLoading || isMutating}>
				{profile && (
					<Form noValidate onSubmit={formik.handleSubmit} aria-describedby="updatePersonError">
						<fieldset>
							<legend className="visually-hidden">Persönliche Daten ändern</legend>
							<Form.Group className="mb-3" controlId="person">
								<Row>
									<Col xs={4} md={4}>
										<Form.Group className="mb-3" controlId="person_title">
											<Form.Label>
												<strong>Titel</strong>
											</Form.Label>
											<Form.Select
												required
												autoComplete="honorific-prefix"
												name="title"
												value={formik.values.title}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											>
												<option value="">Bitte wählen</option>
												<option value="Dr.">Dr.</option>
												<option value="Prof.">Prof.</option>
												<option value="Prof. Dr.">Prof. Dr.</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col xs={8} md={8}>
										<Form.Group className="mb-3" controlId="person_firstName">
											<Form.Label>
												<strong>Vorname</strong>
											</Form.Label>
											<Form.Control
												required
												autoComplete="given-name"
												type="text"
												placeholder="Vorname bitte"
												name="firstName"
												value={formik.values.firstName}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isInvalid={formik.touched.firstName && formik.errors.firstName}
											/>
											<Form.Control.Feedback type="invalid">
												{formik.errors.firstName}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group className="mb-3" controlId="person_lastName">
									<Form.Label>
										<strong>Nachname</strong>
									</Form.Label>
									<Form.Control
										required
										autoComplete="family-name"
										type="text"
										placeholder="Nachname bitte"
										name="lastName"
										value={formik.values.lastName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isInvalid={formik.touched.lastName && formik.errors.lastName}
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.lastName}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-3" controlId="person_gender">
									<Form.Label>
										<strong>Geschlecht</strong>
									</Form.Label>
									<Form.Select
										required
										autoComplete="sex"
										name="gender"
										value={formik.values.gender}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isInvalid={formik.touched.gender && formik.errors.gender}
									>
										<option value="w">Weiblich</option>
										<option value="m">Männlich</option>
										<option value="d">Divers</option>
									</Form.Select>
									<Form.Control.Feedback type="invalid">
										{formik.errors.gender}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="m-0" controlId="person_disability">
									<Form.Label>
										<strong>Schwerbehinderung</strong> (freiwillig)
									</Form.Label>
									<Form.Check
										type="switch"
										label="Schwerbehinderung (freiwillige Angabe)"
										name="disability"
										checked={formik.values.disability}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</Form.Group>
							</Form.Group>
						</fieldset>

						<ModalFooter
							submitDisabled={!formik.isValid || !formik.dirty}
						/>
					</Form>
				)}
			</LoadingScreen>
		</RoutingModal>
	);
};
