import React, { useState, useMemo, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route, Navigate, Link, useLocation } from 'react-router-dom';
import './App.css';
import Navigation from './components/navigation';
import Confirmation from "./components/pages/confirmation";
import Extern from './components/pages/extern';
import Hints from './components/pages/hints';
import Privacy from './components/pages/privacy';
import Imprint from './components/pages/imprint';
import Contact from './components/pages/contact';
import Barrierfree from './components/pages/barrierfree';
import Faq from './components/pages/faq';
import Profile, {
	Statistics,
	Bookmarks,
	Enrollments,
	Institution,
	Interests,
	Mail,
	Password,
	Person,
	Settings
} from './components/profile';
import TrainingFeedback from './components/evaluation/trainingFeedback';
import { LoginModal, LoginPage } from './components/login';
import { ActivateUser, RegisterModal } from './components/register';
import { ResetPassword, UpdatePassword } from './components/resetPassword';
import { UserContext } from './contexts/user-context';
import { NotificationsContext } from './contexts/notifications-context';
import EventBus, { refreshUser } from './common/EventBus';
import {
	ScrollToTop,
	Protected,
	NotificationToaster,
	NoneStyledUnorderedList,
	Paragraph
} from './components/core';
import LocalStorageService from './services/localStorage.service';
import { ConferencesPage, LeadershipPage, FindPage, SchifPage } from './components/trainings';
import Offline from './components/pages/offline';
import useNetwork from './hooks/useNetwork';
import { SymposiumsPage } from './components/symposiums';
import { HomePage } from './components/homePage';
import styled from 'styled-components';
import Sitemap from './components/pages/sitemap';
import TrainingController from './components/training/TrainingController';


const FooterHeader = styled.h2`
	font-size: 2.5rem;
`;

const FooterSubHeader = styled.h3`
	font-size: 2rem;
`;

export default function App() {
	const [user, setUser] = useState(LocalStorageService.getUser());
	const userValue = useMemo(() => ({ user, setUser }), [user]);

	const [notifications, setNotifications] = useState([]);
	const notificationsValue = useMemo(() => ({ notifications, setNotifications }), [notifications]);

	// Notify on user update from api interceptors
	useEffect(() => {
		EventBus.on(refreshUser, () => {
			setUser(LocalStorageService.getUser());
		});
		return () => {
			EventBus.remove(refreshUser);
		};
	}, []);

	const location = useLocation();
	const background = location.state && location.state.backgroundLocation;

	const { isOnline } = useNetwork();

	if (!isOnline) return <Offline />;

	return (
		<>
			<UserContext.Provider value={userValue}>
				<NotificationsContext.Provider value={notificationsValue}>
					<Navigation />
					<Routes location={background || location}>
						<Route
							exact
							path="/find"
							element={
								<Protected>
									<FindPage />
								</Protected>
							}
						/>
						<Route
							exact
							path="/berufsschulen"
							element={
								<Protected>
									<FindPage />
								</Protected>
							}
						/>
						<Route exact path="/trainingFeedback/:eventId/:title" element={<Protected><TrainingFeedback /></Protected>}/>
						<Route exact path="/faq" element={<Faq />} />
						<Route exact path="/faq/:faqId" element={<Faq />} />
						<Route exact path="/confirmation" element={<Confirmation />} />
						<Route exact path="/confirmation?qr" element={<Confirmation />} />
						<Route exact path="/trainingFeedback" element={<TrainingFeedback />} />
						<Route exact path="/hints" element={<Hints />} />
						<Route exact path="/privacy" element={<Privacy />} />
						<Route exact path="/imprint" element={<Imprint />} />
						<Route exact path="/sitemap" element={<Sitemap />} />
						<Route exact path="/barrierfree" element={<Barrierfree />} />
						<Route exact path="/contact" element={<Contact />} />
						<Route
							exact
							path="/schif"
							element={
								<Protected>
									<SchifPage />
								</Protected>
							}
						/>
						<Route
							exact
							path="/leadership"
							element={
								<Protected>
									<LeadershipPage />
								</Protected>
							}
						/>
						<Route
							exact
							path="/extern"
							element={
								<Protected>
									<Extern />
								</Protected>
							}
						/>
						<Route
							exact
							path="/conferences"
							element={
								<Protected>
									<ConferencesPage />
								</Protected>
							}
						/>
						<Route
							exact
							path="/symposiums"
							element={
								<Protected>
									<SymposiumsPage />
								</Protected>
							}
						/>
						<Route
							exact
							path="/training/:id"
							element={
								<Protected>
									<TrainingController />
								</Protected>
							}
						/>
						<Route
							exact
							path="/profile"
							element={
								<Protected>
									<Profile />
								</Protected>
							}
						>
							<Route index element={<Navigate to="settings" />}></Route>
							<Route exact path="settings" element={<Settings />}>
								<Route exact path="person" element={<Person />} />
								<Route exact path="interests" element={<Interests />} />
								<Route exact path="mail" element={<Mail />} />
								<Route exact path="password" element={<Password />} />
								<Route exact path="institution" element={<Institution />} />
							</Route>
							<Route exact path="enrollments" element={<Enrollments />}></Route>
							<Route exact path="bookmarks" element={<Bookmarks />}></Route>
							<Route exact path="statistics" element={<Statistics />}></Route>
						</Route>
						<Route exact path="/login" element={<LoginPage />} />
						<Route exact path="/resetPassword" element={<ResetPassword />} />
						<Route exact path="/updatePassword/:key" element={<UpdatePassword />} />
						<Route exact path="/activate/:key" element={<ActivateUser />} />
						<Route exact path="/" element={<HomePage />} />
						<Route exact path="*" element={<Navigate replace to="/" />} />
					</Routes>
					{background && (
						<Routes>
							<Route exact path="/training/:id" element={<TrainingController isModal={true} />} />
							<Route exact path="/login" element={<LoginModal />} />
							<Route exact path="/register" element={<RegisterModal />} />
						</Routes>
					)}

					<footer className="App-Footer" aria-label="Fußzeile">
						<Container>
							<Row>
								<Col sm>
									<FooterHeader aria-label="Fußzeile">
										<Paragraph>FORTBILDUNG</Paragraph>
										<Paragraph>BERLIN</Paragraph>
									</FooterHeader>
								</Col>
								<Col sm>
									<FooterSubHeader>Infos</FooterSubHeader>
									<NoneStyledUnorderedList>
										<li key="faq">
											<Link to={`faq/0`}>Fragen und Antworten</Link>
										</li>
										<li key="hints">
											<Link to={`hints`}>Hinweise zur Benutzung</Link>
										</li>
										<li key="antrag_freistellung">
											<a
												href="/pdf/antrag_freistellung.pdf"
												title="Freistellungsantrag"
												target="Blank"
											>
												Freistellungsantrag
											</a>
										</li>
										<li key="Antrag_Betreuungskosten_Fortbildung">
											<a
												href="/pdf/Antrag_Betreuungskosten_Fortbildung.pdf"
												title="Antrag_Betreuungskosten_Fortbildung"
												target="Blank"
											>
												Erstattung von Betreuungskosten
											</a>
										</li>
										<li key="contact">
											<Link to={`contact`}>Ansprechpartner/-innen</Link>
										</li>
										<li key="barrierfree">
											<Link to={`barrierfree`}>Erklärung zur digitalen Barrierefreiheit</Link>
										</li>
										<li key="privacy">
											<Link to={`privacy`}>Datenschutzerklärung</Link>
										</li>
										<li key="imprint">
											<Link to={`imprint`}>Impressum</Link>
										</li>
										<li key="sitemap">
											<Link to={`sitemap`}>Sitemap</Link>
										</li>
									</NoneStyledUnorderedList>
								</Col>
								<Col sm>
									<FooterSubHeader>Kooperationen</FooterSubHeader>
									<NoneStyledUnorderedList>
										<li key="LISUM">
											<a href="https://lisum.berlin-brandenburg.de/lisum" target="LISUM">
												LISUM
											</a>
										</li>
										<li key="SFBB">
											<a
												href="https://sfbb.berlin-brandenburg.de/"
												target="SFBB"
											>
												SFBB
											</a>
										</li>
										<li key="VHS">
											<a href="https://www.berlin.de/vhs/" target="VHS">
												VHS
											</a>
										</li>
									</NoneStyledUnorderedList>
								</Col>
							</Row>
							<Row>
								<Col>
									<hr />© Senatsverwaltung für Bildung, Jugend und Familie Berlin{' '}
									{new Date().getFullYear()}
								</Col>
							</Row>
						</Container>
					</footer>

					<NotificationToaster></NotificationToaster>
					<ScrollToTop />
				</NotificationsContext.Provider>
			</UserContext.Provider>
		</>
	);
}
