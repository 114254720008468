import parse from 'html-react-parser';
import { Accordion } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { getErrorMessage } from '../../common';
import { QueryKey } from '../../data/QueryKey';
import TrainingsService from '../../services/trainings.service';
import {
	CardList,
	FlexItem,
	GroupHeader,
	LoadingScreen,
	NoneStyledOrderedList,
	Paragraph,
	ResponsiveHorizontalFlex
} from '../core';
import { TrainingCard } from '../trainings';

export default function Symposiums() {
	const {
		isLoading,
		error,
		data: symposiums
	} = useQuery(QueryKey.symposiums, TrainingsService.getSymposiums);

	const getColumn = (header, trainings) => {
		if (!trainings || trainings.length === 0) return;

		return (
			<FlexItem style={{ minWidth: '250px' }} as="section" aria-label={header}>
				<GroupHeader variant="info">
					<h4 style={{ fontSize: 'var(--bs-body-font-size)' }}>{header}</h4>
				</GroupHeader>

				<CardList>
					{trainings?.map((training) => (
						<TrainingCard key={training.id} training={training} level="5" />
					))}
				</CardList>
			</FlexItem>
		);
	};

	const getTrainings = (trainings) => {
		if (!trainings || trainings.length === 0)
			return <span style={{ display: 'block' }}>Aktuell keine Veranstaltungen vorhanden</span>;

		return (
			<ResponsiveHorizontalFlex>
				{getColumn(
					'Einführung',
					trainings.filter((x) => x.type === 'Einführungsveranstaltung')
				)}
				{getColumn(
					'Vormittags',
					trainings.filter((x) => x.startTime < '12:00' && x.type === 'Workshop')
				)}
				{getColumn(
					'Nachmittags',
					trainings.filter((x) => x.startTime >= '12:00' && x.type === 'Workshop')
				)}
			</ResponsiveHorizontalFlex>
		);
	};

	const getSymposium = (symposium) => {
		return (
			<Accordion.Item key={symposium.id} eventKey={symposium.id} as="li">
				<Accordion.Header as="h3">
					<span style={{ fontSize: '1.3rem' }}>
						{symposium.title} - {symposium.dates}
					</span>
				</Accordion.Header>
				<Accordion.Body as="section" aria-label={'Fachtag: ' + symposium.title}>
					<Paragraph>{parse(symposium.content)}</Paragraph>
					<Paragraph className="pb-5">
						<strong>Zielgruppe:</strong> {symposium.targetGroup}
					</Paragraph>
					{getTrainings(symposium.trainings)}
				</Accordion.Body>
			</Accordion.Item>
		);
	};

	if (error) return 'Beim Laden ist der folgende Fehler aufgetreten: ' + getErrorMessage(error);

	return (
		<section aria-label="Ergebnisliste">
			<LoadingScreen isLoading={isLoading}>
				{symposiums && symposiums.length !== 0 ? (
					<Accordion as={NoneStyledOrderedList}>{symposiums?.map(getSymposium)}</Accordion>
				) : (
					<h5>Aktuell keine Fachtage geplant</h5>
				)}
			</LoadingScreen>
		</section>
	);
}
