import React, { useEffect } from 'react';
import {useQuery} from "react-query";
import { Image, Nav, Tab } from 'react-bootstrap';
import { MdOutlineQueryStats } from "react-icons/md";
import { BsBookmark } from "react-icons/bs";

import '../pages/allPages.css';
import logoHead from '../pages/img/profile.jpg';
import { Title } from '../pages/Title';
import useUser from '../../hooks/useUser';
import { Link, Outlet, useLocation } from 'react-router-dom';
import EvaluationService from "../../services/evaluation.service";

export default function Profile() {
	const { isLoggedIn } = useUser();
	const location = useLocation();
	const { isLoading, data } = useQuery('IsDozent', EvaluationService.getCheckDozent);

	useEffect(() => {
		document.title = 'Profil | Fortbildung Berlin';
	}, []);

	if (isLoading)  return 'Daten werden geladen...';

	if (isLoggedIn) {
		return (
			<>
				<header>
					<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
					<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
				</header>
				<main
					style={{
						paddingLeft: '0.3rem',
						paddingRight: '0.3rem',
						paddingTop: '1rem',
						paddingBottom: '1rem'
					}}
				>
					<Tab.Container activeKey={location.pathname.split('/', 3).join('/')}>
						<Nav as="ul" variant="tabs" className="mb-3">
							<Nav.Item as="li">
								<Nav.Link eventKey="/profile/settings" as={Link} to="settings">
									Profil
								</Nav.Link>
							</Nav.Item>
							<Nav.Item as="li">
								<Nav.Link eventKey="/profile/enrollments" as={Link} to="enrollments">
									Meine Fortbildungen
								</Nav.Link>
							</Nav.Item>
							<Nav.Item as="li">
								<Nav.Link eventKey="/profile/bookmarks" as={Link} to="bookmarks">
									<BsBookmark
										title="Meine durch Bookmarks markierten Fortbildungen"
										aria-label="Meine durch Bookmarks markierten Fortbildungen"
										style={{ fontSize: '1.2em'}}
									/>
								</Nav.Link>
							</Nav.Item>
							{
								data.isDozent && (
								<Nav.Item as="li">
									<Nav.Link eventKey="/profile/statistics" as={Link} to="statistics">
										<MdOutlineQueryStats
											title="Feedbacks zu meinen angebotenen Fortbildungen"
											aria-label="Feedbacks zu meinen angebotenen Fortbildungen"
											style={{fontSize: '1.2em'}}
										/>
									</Nav.Link>
								</Nav.Item>
							)}
						</Nav>
						<Tab.Content>
							<Outlet></Outlet>
						</Tab.Content>
					</Tab.Container>
				</main>
			</>
		);
	} else return <p>Bitte loggen Sie sich erst ein.</p>;
}
