import { useQuery } from 'react-query';
import useUser from './useUser';
import EvaluationService from "../services/evaluation.service";

/**
 * Custom Hook for statistics.
 *
 * @author Lutz Westphal
 *
 *
 * Returns:
 *  - enrollments: The enrollments for the user
 */
export default function useFeedbacks() {
	const { user, isLoggedIn } = useUser();
	const { data: feedbacks, isLoading } = useQuery(
		['feedbacks', user?.username],
		EvaluationService.getFeedbacks,
		{
			enabled: isLoggedIn
		}
	);
	return { feedbacks, isLoading };
}
