import React from 'react';
import { BookmarkItem } from './BookmarkItem';
import styled from 'styled-components';
import { NoneStyledOrderedList } from '../../core';

const BookmarkListHeader = styled.h2`
	margin-top: 1em;
	margin-bottom: 0;
	border-left: 2.5mm solid;
	border-color: #0080ff;
	padding-left: 1.5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: white;
	font-size: 110%;
	font-weight: bold;
`;

export const BookmarkList = ({ bookmarks, title }) => {
	let count = 1;

	if (Object.keys(bookmarks).length === 0) return;

	return (
		<>
			<BookmarkListHeader>{title}</BookmarkListHeader>
			<NoneStyledOrderedList>
				{bookmarks?.map((bookmark) => (
					<BookmarkItem bookmark={bookmark} key={count} count={count++} />
				))}
			</NoneStyledOrderedList>
		</>
	);
};
