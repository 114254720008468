import { useQuery } from 'react-query';
import useUser from './useUser';
import EvaluationService from "../services/evaluation.service";

/**
 * Custom Hook for statistics.
 *
 * @author Lutz Westphal
 *
 *
 * Returns:
 *  - enrollments: The enrollments for the user
 */
export default function useRegistrationCounts() {
    const { user, isLoggedIn } = useUser();
    const { data: registrations, isLoading  } = useQuery(
        ['RegistrationCounts', user?.username],
        EvaluationService.getRegistrationCounts,
        {
            enabled: isLoggedIn
        }
    );
    return { registrations, isLoading };
}