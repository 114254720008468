/**
 * Function for calcute the sum of eventtimes
 *
 * @author Lutz Westphal
 *
 * Params:
 *  - string: event formated string of begin an end time
 *
 * Returns: Sum od daytimes
 */
export function eventDayTimeSum(dates) {
	let sum = 0;

	if (dates)
		dates.map(
			(event) =>
				(sum +=
					Math.abs(
						parseInt(event.timeEnd.split(':')[0]) - parseInt(event.timeBegin.split(':')[0])
					) *
						60 +
					parseInt(event.timeEnd.split(':')[1] - parseInt(event.timeBegin.split(':')[1])))
		);
	return sum;
}

/**
 * Function for calcute the duration of events
 *
 * @author Lutz Westphal
 *
 * Params:
 *  - events:
 *
 * Returns: Duration
 */
export const calcEventDuration = (events) => {
	// evens musst have a minimumlenght from 28 to be valid (es gibt alte Veranstaltungen OHNE TERMIN!!!!)
	const dates = [];
	if (events.length !== 0 && events.length >= 28) {
		events.split(';').forEach((event) => {
			if (event)
				dates.push({
					timeBegin: event.split(',')[2],
					timeEnd: event.split(',')[3]
				});
		});
		return eventDayTimeSum(dates);
	} else return 0;
};
