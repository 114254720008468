import api from './api';
import LocalStorageService from './localStorage.service';

class AuthService {
	login(username, password) {
		return api.post('/auth/login', { username, password }).then((response) => {
			if (response.data.accessToken) {
				LocalStorageService.setUser(response.data);
			}
			return response.data;
		});
	}

	logout() {
		LocalStorageService.removeUser();
	}

	async registerAsync(user) {
		const { data } = await api.post('/auth/register', user);
		return data;
	}

	async resetPasswordAsync(username, captchaValue) {
		const { data } = await api.post('/auth/reset-password', {
			username,
			captchaValue
		});
		return data;
	}

	async updatePasswordAsync(password, resetKey) {
		const { data } = await api.post('/auth/update-password', {
			password,
			resetKey
		});
		return data;
	}

	async activateAsync(key) {
		const { data } = await api.post(`/auth/activate/${key}`);
		return data;
	}

	async updateUserAsync(userData) {
		const { data } = await api.patch(`/auth/update-user`, userData);
		return data;
	}
}
export default new AuthService();
