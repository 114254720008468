import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import logoHead from './img/barrier-free.png';
import { Title, SubTitle } from './Title';
import { HorizontalDivider, Paragraph, ParagraphWithSpace } from '../core';

const Barrierfree = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		document.title = 'Digitale Barrierefreiheit | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<Image
					style={{ padding: '2rem 1rem 2rem 1rem' }}
					src={logoHead}
					alt="Logo im Kopf der Seite"
					fluid
				/>
			</header>

			<main className="px-4">
				<SubTitle title="Erklärung zur digitalen Barrierefreiheit" />
				<HorizontalDivider />
				<ParagraphWithSpace>
					Diese Erklärung der digitalen Barrierefreiheit gilt für die unter{' '}
					<a href="https://fortbildungen.berlin">fortbildungen.berlin</a> veröffentlichte Webseite
					der Fortbildung Berlin.
				</ParagraphWithSpace>
				<ParagraphWithSpace>
					Die Senatsverwaltung für Bildung, Jugend und Familie ist als öffentliche Stelle im Sinne
					der Richtlinie (EU) 2016/2102 des Europäischen Parlaments und des Rates bemüht, die
					Internetseite auch für diverse Endgeräte barrierefrei zugänglich zu machen.
				</ParagraphWithSpace>
				<ParagraphWithSpace>
					Rechtsgrundlage sind die Bestimmungen des Behindertengleichstellungsgesetzes des Bundes
					(BGG) sowie der Barrierefreien-Informationstechnik-Verordnung (BITV 2.0) zur Umsetzung der
					Richtlinie (EU) 2016/2102 in der jeweils aktuellen Fassung.
				</ParagraphWithSpace>
				<ParagraphWithSpace>
					Die Anforderungen der digitalen Barrierefreiheit ergeben sich aus den §§ 3 Absätze 1 bis 4 und 4 der
					BITV 2.0 auf Grundlage von § 12d BGG.
				</ParagraphWithSpace>
				<Paragraph>
					Die letzte externe Prüfung zur digitalen Barrierefreiheit der Webseite durch die Firma Aeir wurde im
					Zeitraum 16.08. bis 05.09.2022 durchgeführt und bescheinigt:
				</Paragraph>
				<Paragraph>
					„Das Webangebot fortbildungen.berlin ist in Teilen schon gut zugänglich. An einigen
					Stellen gibt es noch ein paar Hürden, die überwunden werden müssen. Diese Hürden stammen
					zum Teil aus redaktionellen Fehlern, da Texte, Überschriften und Bilder nicht korrekt
					ausgezeichnet und beschriftet wurden. Weitere Hürden entstehen durch die Seitenstruktur.
					Um diese strukturellen Hürden aus dem Weg zu räumen, müssen Formulare und Bedienelemente
					angepasst werden.“
				</Paragraph>
				<ParagraphWithSpace>
					Inzwischen wurden die Anforderungen größtenteils umgesetzt. Zusätzlich zu den
					redaktionellen Verbesserungen wurde auch die Seitenstruktur, sowie zahlreiche Formulare
					überarbeitet. Somit sind die größten digitalen Barrieren verschwunden. Für die verbliebenen Hürden
					wird im Moment nach Lösungen gesucht.
				</ParagraphWithSpace>
				<section aria-label="Digitale Barrieren melden">
					<h3>Digitale Barrieren melden</h3>
					<ParagraphWithSpace>
						Sind Ihnen Mängel beim barrierefreien Zugang zu Inhalten auf der Webseite
						fortbildungen.berlin aufgefallen? Oder haben Sie Fragen und Anregungen zum Thema
						digitale Barrierefreiheit? Dann freuen wir uns über Ihre Rückmeldung.
					</ParagraphWithSpace>
					<Paragraph className="px-3">Name: Ruth Schaefer-Franke (II E 6.2)</Paragraph>
					<Paragraph className="px-3">
						E-Mail:{' '}
						<a href="mailto:ruth.schaefer-franke@senbjf.berlin.de?subject=Barrieren%20auf%20fortbildungen.berlin%20melden">
							ruth.schaefer-franke@senbjf.berlin.de
						</a>
					</Paragraph>
					<ParagraphWithSpace className="px-3">Telefon: +49 30 902275696</ParagraphWithSpace>
				</section>
				<section aria-label="Durchsetzungsverfahren">
					<h3>Durchsetzungsverfahren</h3>
					<ParagraphWithSpace>
						Sollte auch nach Kontaktaufnahme zu oben genannter Stelle keine zufriedenstellende
						Lösung gefunden werden, können Sie sich an die Berliner Landesbeauftragten für digitale
						Barrierefreiheit wenden. Informationen dazu erhalten Sie{' '}
						<a
							href="https://www.berlin.de/moderne-verwaltung/barrierefreie-it/anlaufstellen/landesbeauftragte/artikel.988070.php#beschwerden"
							target="_blank"
							rel="noopener noreferrer"
						>
							hier
						</a>
						.{' '}
					</ParagraphWithSpace>
				</section>
				<Paragraph className="pb-5">
					Diese Erklärung zur digitalen Barrierefreiheit wurde zuletzt am 06.09.2023 aktualisiert.
				</Paragraph>
			</main>
		</>
	);
};

export default Barrierfree;
