import { RiCheckboxCircleFill } from 'react-icons/ri';
import { GiCancel } from 'react-icons/gi';
import { MdQueryBuilder } from 'react-icons/md';
import { IconContext } from 'react-icons';

import useEnrollment from '../../hooks/useEnrollment';
import colors from '../../common/colors';

export function TrainingFeedback({ trainingId }) {
	const { enrollment } = useEnrollment(trainingId);

	// Keine Buchung
	if (!enrollment) return <></>;
	// Hat Feeback zur Anwesenheit abgegeben
	else if (!enrollment.isEvaluationPossible)
		return (
			<IconContext.Provider value={{ color: colors.successIcon }}>
				<RiCheckboxCircleFill title="Besucht mit Feedback" />
			</IconContext.Provider>
		);
	// War anwesend
	else if (enrollment.wasPresent)
		return (
			<IconContext.Provider value={{ color: colors.warningIcon }}>
				<RiCheckboxCircleFill title="Besucht ohne Feedback" />
			</IconContext.Provider>
		);
	// Buchung bestätigt
	else if (enrollment.isConfirmed)
		return (
			<IconContext.Provider value={{}}>
				<RiCheckboxCircleFill title="Bestätigt" />
			</IconContext.Provider>
		);
	// Abgelehnt
	else if (enrollment.isRejected)
		return (
			<IconContext.Provider value={{ color: colors.errorIcon }}>
				<GiCancel title="Abgelehnt" />
			</IconContext.Provider>
		);
	// Abgesagt von SenBJF
	else if (enrollment.isCancelledByManagement)
		return (
			<IconContext.Provider value={{ color: colors.errorIcon }}>
				<GiCancel title="Abgesagt von SenBJF" />
			</IconContext.Provider>
		);
	// Abgesagt vom Benutzer
	else if (enrollment.isCancelledByUser)
		return (
			<IconContext.Provider value={{ color: colors.errorIcon }}>
				<GiCancel title="Abgesagt von Ihnen" />
			</IconContext.Provider>
		);
	// Auf Warteliste
	else if (enrollment.isWaiting)
		return (
			<IconContext.Provider value={{ color: colors.warningIcon }}>
				<MdQueryBuilder title="Warteliste" />
			</IconContext.Provider>
		);
	// Angemeldet
	else
		return (
			<IconContext.Provider value={{}}>
				<MdQueryBuilder title="Angemeldet" />
			</IconContext.Provider>
		);
}
