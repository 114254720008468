import React from 'react';
import colors from '../../common/colors';

const Title = (props) => {
	const align = props.align === undefined ? 'center' : props.align;
	return (
		<h1
			style={{
				textAlign: align,
				marginTop: '1.5rem',
				marginBottom: '1.5rem',
				marginLeft: '0.5rem',
				marginRight: '0.5rem',
				color: colors.primaryHeader
			}}
		>
			{props.title}
		</h1>
	);
};

const SubTitle = (props) => {
	const align = props.align === undefined ? 'center' : props.align;
	return (
		<h2
			style={{
				textAlign: align,
				marginTop: '20px',
				color: colors.primarySubHeader,
				fontSize: '2.5rem'
			}}
		>
			{props.title}
		</h2>
	);
};

export { SubTitle, Title };
