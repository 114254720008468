import React, { useEffect, useState } from 'react';
import { Card, Modal, Button, Image, Container, Row, Col } from 'react-bootstrap';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import './allPages.css';
import logoHead from './img/externFotowand.jpg';
import { Title, SubTitle } from './Title';
import { ContentDescription } from '../trainings/';
import parse from 'html-react-parser';
import { GrZoomIn } from 'react-icons/gr';
import { getErrorMessage } from '../../common';
import ExternService from '../../services/extern.service';
import { Paragraph, ParagraphWithSpace } from '../core';

const queryClient = new QueryClient();

export default function MyExtern() {
	
	useEffect(() => {
		document.title = 'Extern | Fortbildung Berlin';
	}, []);
	
	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />

				<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
			</header>
			<main>
				<SubTitle title="Externe Fortbildungen" />

				<ContentDescription>
					<Paragraph>
						Externe Fortbildungen sind Veranstaltungen für das pädagogische Personal, die von der
						Senatsverwaltung für Bildung, Jugend und Familie nicht als offizielle
						Fortbildungsveranstaltungen anerkannt werden, da sie nicht den Kriterien der Fortbildung
						Berlin entsprechen.
					</Paragraph>
				</ContentDescription>

				<div
					style={{
						padding: '1rem'
					}}
				>
					<QueryClientProvider client={queryClient}>
						<EventExtern />
					</QueryClientProvider>
				</div>
			</main>
		</>
	);
}

function extractDomainAndPath(url) {
	// Entferne Protokolle wie http://, https://, etc.
	const cleanUrl = url.replace(/^(https?:\/\/)+/, '');

	try {
		const parsedUrl = new URL('https://' + cleanUrl); // Füge ein Protokoll hinzu, um URL-Parsing zu ermöglichen
		return `https://${parsedUrl.host}${parsedUrl.pathname}${parsedUrl.search}`;
	} catch (error) {
		console.error('Invalid URL:', error);
		return null;
	}
}

function EventExtern() {
	const { isLoading, error, data } = useQuery('repoData', ExternService.getConferences);

	if (isLoading) return 'Daten werden geladen...';
	if (error) return 'Beim Laden ist der folgende Fehler aufgetreteb: ' + getErrorMessage(error);

	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'space-around',
				alignItems: 'flex-start',
				alignContent: 'space-between',
				textAlign: 'left'
			}}
		>
			{data.map((myModel) => (
				<Card
					bg={'light'}
					key={myModel.veranstaltungen_extern_id}
					text={'dark'}
					style={{
						maxWidth: 		'23rem',
						minWidth:		'23rem',
						height: '23rem',
						borderWidth: 	'2px',
						flexGrow: 		'1',
						marginRight: 	'10px',
						marginLeft: 	'10px',
					}}
					className="shadow-sm  mb-4 bg-white rounded"
				>
					<Card.Header
						style={{
							minHeight: '6.5rem',
							color: 'rgb(168, 0, 0)',
							hyphens: 'auto',
							fontSize: 'large'
						}}
					>
						{myModel.veranstaltungen_extern_veranstalter}
					</Card.Header>
					<Card.Body>
						<Card.Title
							style={{
								fontSize: 'large',
								hyphens: 'auto'
							}}
						>
							{myModel.veranstaltungen_extern_thema}
						</Card.Title>
						<Card.Text>
							<Paragraph>Zeitraum:</Paragraph>
							{myModel.veranstaltungen_extern_datum_von.split('-').reverse().join('.')} bis{' '}
							{myModel.veranstaltungen_extern_datum_bis.split('-').reverse().join('.')}
						</Card.Text>
					</Card.Body>
					<Card.Footer>
						<Extern
							title={myModel.veranstaltungen_extern_thema}
							datefrom={myModel.veranstaltungen_extern_datum_von}
							dateuntil={myModel.veranstaltungen_extern_datum_bis}
							location={myModel.veranstaltungen_extern_ort}
							link={extractDomainAndPath(myModel.veranstaltungen_extern_veranstalter_link)}
							content={myModel.veranstaltungen_extern_termin_text}
						/>
					</Card.Footer>
				</Card>
			))}
		</div>
	);
}

function Extern(props) {
	const [show, setShow] = useState(false);
	// const [like, setLike] = useState(false);
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);
	// const handleLike = () => { setLike(true); setShow(false) };
	// const handleNoLike = () => { setLike(false); };

	return (
		<>
			<Container fluid>
				<Row>
					{/* <Col style={{ textAlign: 'left' }}>
                        <button
                            type='button'
                            className='link-button'
                            onClick={handleNoLike}
                        >
                            {(like === true) ? <IconContext.Provider value={{ color: "green" }}>< FaShoppingCart /></IconContext.Provider> : ' '}
                        </button>
                    </Col> */}
					<Col xs={6}></Col>
					<Col style={{ textAlign: 'right' }}>
						<button type="button" className="link-button" onClick={handleShow}>
							<GrZoomIn alt="Detailansicht Symbol" />
						</button>
					</Col>
				</Row>
			</Container>

			<Modal
				size="lg"
				show={show}
				scrollable={true}
				onHide={() => setShow(false)}
				dialogClassName="modal-90w"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header closeButton closeVariant="white">
					<Modal.Title id="example-custom-modal-styling-title">{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{parse(props.content)}
					<p>
						<strong>Zeitraum:</strong> {props.datefrom.split('-').reverse().join('.')} bis{' '}
						{props.dateuntil.split('-').reverse().join('.')}
					</p>
					<p>
						<strong>Link:</strong>{' '}
						<a href={props.link} target={props.title}>
							{props.link}
						</a>
					</p>
					<Paragraph>
						<strong>Veranstaltungsort:</strong>
					</Paragraph>
					<ParagraphWithSpace>{parse(props.location)}</ParagraphWithSpace>
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row>
							{/* <Col style={{ textAlign: 'left' }}>
                                <Button variant="success" onClick={handleLike}>
                                    Buchen
                                </Button>
                            </Col> */}
							<Col style={{ textAlign: 'right' }}>
								<Button variant="secondary" onClick={handleClose}>
									Schließen
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	);
}
