import { useMutation, useQueryClient } from 'react-query';
import EvaluationService from "../services/evaluation.service";

export default function useUpdateEventConference() {
    const queryClient = useQueryClient();

    const { mutateAsync: updateEventConference, isLoading, isError, error } = useMutation(
        ({ eventId, url, code }) => EvaluationService.updateEventConference(eventId, url, code),
        {
            onSuccess: () => {
                // Angenommen, der Key der Query, die den Link-Status hält, ist 'eventConference'
                // Sie sollten diesen Query-Key entsprechend anpassen, damit er zu dem passt, der die relevanten Daten abruft
                queryClient.invalidateQueries('RegistrationCounts');
            },
            // onError usw. können hier ebenfalls konfiguriert werden
        }
    );

    return { updateEventConference, isLoading, isError, error };
}

