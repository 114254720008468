import { useQuery } from 'react-query';
import EnrollmentsService from '../services/enrollments.service';
import useUser from './useUser';

/**
 * Custom Hook for enrollments.
 *
 * @author Janik Hilser
 *
 *
 * Returns:
 *  - enrollments: The enrollments for the user
 */
export default function useEnrollments() {
	const { user, isLoggedIn } = useUser();

	const { data: enrollments, isLoading } = useQuery(
		['enrollments', user?.username],
		EnrollmentsService.getAll,
		{
			enabled: isLoggedIn
		}
	);

	return { enrollments, isLoading };
}
