import { useQuery } from 'react-query';

import { QueryKey } from '../../data/QueryKey';
import RegisterService from '../../services/register.service';
import { LoadingScreen } from '../core';
import { MultiSelector } from '../core/multiSelector';

export function SubjectSelector({ initValues, onChange }) {
	const { data: allSubjects, isLoading: subjectsIsLoading } = useQuery({
		queryKey: QueryKey.subjects,
		queryFn: RegisterService.getSubjects
	});

	const options = allSubjects?.map((item) => {
		return {
			label: item.name.length > 35 ? item.name.substring(0, 35) + '...' : item.name,
			value: item.id
		};
	});

	return (
		<LoadingScreen isLoading={subjectsIsLoading}>
			<MultiSelector
				options={options}
				initValues={initValues}
				onChange={onChange}
				inDialog={true}
				label="Interesse an folgenden Themen"
				controlId="subjects"
				className='mb-3'
			/>
		</LoadingScreen>
	);
}
