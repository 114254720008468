import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useUpdateEventConference from '../../../hooks/useUpdateEventConference';

const ConferenceLinkModal = ({ show, onHide, id, url, code, displayNumber, title }) => {
    const { updateEventConference, isLoading } = useUpdateEventConference();

    const formik = useFormik({
        initialValues: {
            id,
            url,
            code,
        },
        validationSchema: Yup.object({
            url: Yup.string()
                .nullable(true)
                .url('Ungültige URL'),
            code: Yup.string()
                .nullable(true)
        }),
        onSubmit: async (values) => {
            // Speichern mit möglicherweise zurückgesetzten Werten
            try {
                await updateEventConference({
                    eventId: values.id,
                    url: values.url,
                    code: values.code
                });
                onHide(); // Verstecken des Modals nach der Operation
            } catch (err) {
                console.error('Fehler beim Speichern der Daten:', err);
            }
        },
    });

    const handleResetAndSave = () => {
        // Sicherheitsabfrage, um versehentliches Löschen zu vermeiden
        const isConfirmed = window.confirm('Sind Sie sicher, dass Sie den Eintrag löschen möchten? Durch das Löschen werden die URL und der Code entfernt.');

        if (isConfirmed) {
            // Setzen Sie hier die Logik zum Zurücksetzen der Felder, aber behalten Sie die ID bei
            formik.setValues({ ...formik.values, url: '', code: '' });
            formik.submitForm(); // Programmatisches Einreichen des Formulars nach dem Zurücksetzen
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Konferenzlink zu {displayNumber} angeben</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {title}
                    </p>
                    <Form.Group controlId="formId">
                        <Form.Control
                            type="hidden"
                            name="id"
                            value={formik.values.id}
                        />
                    </Form.Group>
                    <Form.Group controlId="formUrl">
                        <Form.Label style={{ fontWeight: 'bold' }}>URL:</Form.Label>
                        <Form.Control
                            type="text"
                            name="url"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.url}
                            isInvalid={formik.touched.url && !!formik.errors.url}
                        />
                        {formik.touched.url && formik.errors.url && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.url}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <br />
                    <Form.Group controlId="formCode">
                        <Form.Label style={{ fontWeight: 'bold' }}>Code:</Form.Label>
                        <Form.Control
                            type="text"
                            name="code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.code}
                            isInvalid={formik.touched.code && !!formik.errors.code}
                        />
                        {formik.touched.code && formik.errors.code && (
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.code}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleResetAndSave} disabled={isLoading}>
                        Löschen
                    </Button>
                    <Button variant="secondary" onClick={onHide}>
                        Abbrechen
                    </Button>
                    <div style={{ flex: '1 1 auto' }} />
                    <Button variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? 'Speichern...' : 'Speichern'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ConferenceLinkModal;
