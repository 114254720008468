class LocalStorageService {
	getUser() {
		return JSON.parse(localStorage.getItem('user'));
	}

	setUser(user) {
		localStorage.setItem('user', JSON.stringify(user));
	}

	removeUser() {
		localStorage.removeItem('user');
	}

	getLocalAccessToken() {
		const currentUser = JSON.parse(localStorage.getItem('user'));
		return currentUser?.accessToken;
	}

	updateLocalAccessToken(token) {
		const user = JSON.parse(localStorage.getItem('user'));
		user.accessToken = token;
		localStorage.setItem('user', JSON.stringify(user));
	}
}
export default new LocalStorageService();
