import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import colors from '../../common/colors';

export const ResponsiveHorizontalFlex = styled.div`
	display: flex;
	flex-flow: row wrap;
	gap: 10px;
`;

export const FlexItem = styled.div`
	flex: 1 1 min-content;
`;

export const GroupHeader = styled(Alert)`
	margin: 15px;
	text-align: center;
`;

export const CardList = styled.ol`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: flex-start;
	align-content: space-between;
	text-align: left;
	min-height: 150px;
	padding: 0;
	list-style-type: none;
`;

export const NoneStyledOrderedList = styled.ol`
	padding: 0;
	list-style-type: none;
`;

export const NoneStyledUnorderedList = styled.ul`
	padding: 0;
	list-style-type: none;
`;

export const Paragraph = styled.p`
	margin-bottom: 0;
`;

export const ParagraphWithSpace = styled.p``;

export const HorizontalDivider = styled.hr`
	color: ${colors.primaryDivider};
`

export const ModalHeader = styled.h2`
	font-size: 1.5rem;
`;

