import React from 'react';
import { LoadingScreen, Wizard, WizardStep } from '../core';
import AuthService from '../../services/auth.service';
import useNotification from '../../hooks/useNotification';
import { useMutation } from 'react-query';
import { getErrorMessage } from '../../common';
import { Personal, personalSchema } from './Personal';
import Work, { workSchema } from './Work';
import Access, { accessSchema } from './Access';

export default function Register({ onRegistered }) {
	const { setInfo, setError } = useNotification();

	const { mutate: register, isLoading } = useMutation(
		async (values) => {
			await AuthService.registerAsync({
				title: values.title,
				firstName: values.firstName,
				lastName: values.lastName,
				gender: values.gender,
				disability: values.disability,
				subjects: values.subjects,
				institution: values.institutions,
				conditions: values.conditions,
				email: values.email,
				password: values.password,
			});
		},
		{
			onSuccess: () => {
				onRegistered();
				setInfo(
					'Sie wurden erfolgreich registriert und haben nun eine E-Mail mit einem Bestätigungslink erhalten.'
				);
			},

			onError: (error) => {
				setError(getErrorMessage(error));
			}
		}
	);

	return (
		<LoadingScreen isLoading={isLoading}>
			<Wizard
				initialValues={{
					title: '',
					firstName: '',
					lastName: '',
					gender: 'w',
					disability: false,
					subjects: [],
					institution: '',
					conditions: false
				}}
				submitText="Registrieren"
				onSubmit={register}
			>
				<WizardStep validationSchema={personalSchema}>
					<Personal />
				</WizardStep>
				<WizardStep validationSchema={workSchema}>
					<Work />
				</WizardStep>
				<WizardStep validationSchema={accessSchema}>
					<Access />
				</WizardStep>
			</Wizard>
		</LoadingScreen>
	);
}
