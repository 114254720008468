import { Navigate, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';

/**
 * Base component for to wrap a protected route. This redirects to login if user not logged in.
 *
 * @author Janik Hilser
 * @version 1.0.0
 */
export function Protected({ children }) {
	const { isLoggedIn } = useUser();
	const location = useLocation();

	if (!isLoggedIn) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}
