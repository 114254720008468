import { RoutingModal } from '../core';
import { DefaultRegister } from '.';

export function RegisterModal() {
	return (
		<RoutingModal title=" Registrierung fortbildungen.berlin">
			<DefaultRegister></DefaultRegister>
		</RoutingModal>
	);
}
