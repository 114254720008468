import { MultiSelector } from '../core';

export function RegionSelector({ initValues, onChange, className }) {
	const regions = [
		{
			label: 'Alle Regionen',
			value: 0
		},
		{
			label: 'Mitte',
			value: 1
		},
		{
			label: 'Friedrichshain-Kreuzberg',
			value: 2
		},
		{
			label: 'Pankow',
			value: 3
		},
		{
			label: 'Charlottenburg-Wilmersdorf',
			value: 4
		},
		{
			label: 'Spandau',
			value: 5
		},
		{
			label: 'Steglitz-Zehlendorf',
			value: 6
		},
		{
			label: 'Tempelhof-Schöneberg',
			value: 7
		},
		{
			label: 'Neukölln',
			value: 8
		},
		{
			label: 'Treptow-Köpenick',
			value: 9
		},
		{
			label: 'Marzahn-Hellersdorf',
			value: 10
		},
		{
			label: 'Lichtenberg',
			value: 11
		},
		{
			label: 'Reinickendorf',
			value: 12
		},
		{
			label: 'Berufliche Schulen',
			value: 13
		},
		{
			label: 'Überregional',
			value: 14
		}
	];

	return (
		<MultiSelector
			options={regions}
			initValues={initValues}
			onChange={onChange}
			label="Regionenauswahl"
			controlId="regions"
			className={className}
			visuallyHidden={true}
		/>
	);
}
