import { useQuery } from 'react-query';
import useUser from './useUser';
import EvaluationService from "../services/evaluation.service";

/**
 * Custom Hook for Questions.
 *
 * @author Lutz Westphal
 *
 *
 * Returns:
 *  - feedback questions:
 */
export default function useFeedbackQuestions() {
	const { user, isLoggedIn } = useUser();

	const { data: questions, isLoading } = useQuery(
		['questions', user?.username],
		EvaluationService.getFormData,
		{
			enabled: isLoggedIn
		}
	);

	return { questions, isLoading };
}
