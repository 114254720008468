import { useContext, useEffect, useState } from 'react';
import { getErrorMessage } from '../common';

import { UserContext } from '../contexts/user-context';
import AuthService from '../services/auth.service';

/**
 * Custom Hook for user context.
 *
 * @author Janik Hilser
 *
 * Returns:
 *  - user: The actual user
 *  - isLoggedIn: A bool which representate the login state
 *  - login: A method to login
 *  - logout: A method to logout
 */
export default function useUser() {
	const { user, setUser } = useContext(UserContext);
	const [isLoggedIn, setIsLoggedIn] = useState(!!user);

	useEffect(() => {
		setIsLoggedIn(!!user);
	}, [user]);

	const login = (username, password) => {
		return AuthService.login(username, password).then(
			(data) => {
				setUser(data);
				return Promise.resolve();
			},
			(error) => {
				const message = getErrorMessage(error);
				setUser();
				return Promise.reject(message);
			}
		);
	};

	const logout = () => {
		AuthService.logout();
		setUser();
	};

	return { user, isLoggedIn, login, logout };
}
