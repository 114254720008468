import { Field } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { InstitutionSelector, SubjectSelector } from '../domain.core';

export const workSchema = Yup.object().shape({
	institutions: Yup.number().required('Bitte wählen Sie eine Bildungseinrichtung aus')
});

export default function Work() {
	return (
		<fieldset>
			<legend className="visually-hidden">Arbeitsspezifische Daten</legend>
			<Field name="subjects">
				{({ field, form, meta }) => (
					<SubjectSelector
						{...field}
						onChange={(subjects) => {
							field.onChange({ target: { value: subjects, name: field.name } });
						}}
						initValues={field.value}
					></SubjectSelector>
				)}
			</Field>
			<Form.Group className="mb-3">
				<InstitutionSelector
					title="Bildungseinrichtung wählen"
					ariaDescribedBy="institutionHelpBlock"
				/>
				<Form.Text id="institutionHelpBlock">
					Falls Ihre Bildungseinrichtung nicht vorhanden ist, wenden Sie sich bitte an{' '}
					<a href="mailto:rf@senbjf.berlin.de">unseren Kontakt</a>.
				</Form.Text>
			</Form.Group>
		</fieldset>
	);
}
