import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import setupInterceptors from './services/setupInterceptors';
import { TopLevelErrorBoundary } from './common/';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnmount: false,
			refetchOnReconnect: false,
			retry: false,
			staleTime: 5 * 60 * 1000
		}
	}
});

const ReactQueryDevtoolsProduction = React.lazy(() =>
	import('react-query/devtools/development').then((d) => ({
		default: d.ReactQueryDevtools
	}))
);

setupInterceptors();

const container = document.getElementById('root');
container.className = "App-Content";
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<Router>
			<QueryClientProvider client={queryClient}>
				<TopLevelErrorBoundary>
					<App/>
				</TopLevelErrorBoundary>
				<ReactQueryDevtools initialIsOpen={false} />
				{process.env.REACT_APP_API_URL === 'https://dev.backend.fortbildungen.berlin' && (
					<ReactQueryDevtoolsProduction initialIsOpen={false} />
				)}
			</QueryClientProvider>
		</Router>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
