import React from 'react';
import { MdOutlineStar, MdStarHalf, MdOutlineStarBorder } from "react-icons/md";

const transform = (value, from = 4, to = 4) => {
    if(value===0) return 0;
    if(to===from) return value;
    return((value*to)/from);
}

const StarDisplay = ({
                         value,
                         startOf= 4,
                         outOf = 4,
                         starStyle = { color: 'black', fontSize: '1.25em', display: 'inline-block' } }) => {

    if(value===0) return <div>nicht bewertet </div>
    if(startOf<outOf) value = transform(value, startOf, outOf);

    const fullStars = Math.floor(value);
    const halfStar = (value % 1 >= 0.5) ? 1 : 0;
    const emptyStars = outOf - fullStars - halfStar;
    // const displayRatingCount = ratingCount!==0 ? `(${ratingCount})` : '';

    const fullStarElement = index => <MdOutlineStar key={`full_${index}`} style={starStyle}/>;
    const halfStarElement = <MdStarHalf key="half" style={starStyle}/>;
    const emptyStarElement = index => <MdOutlineStarBorder key={`empty_${index}`} style={starStyle} />;

    return (
        <div
            style={{ display: 'inline-block', marginLeft: '0em'}}
            title={`${value.toFixed(2)} von ${outOf} Sternen erreicht.`}
            aria-label={`${value.toFixed(2)} von ${outOf} Sternen erreicht.`}
        >
            {Array(fullStars).fill(null).map((_, index) => fullStarElement(index))}
            {halfStar ? halfStarElement : null}
            {Array(emptyStars).fill(null).map((_, index) => emptyStarElement(index))}
        </div>
    );
};


export default StarDisplay;
