import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { QueryKey } from '../../data/QueryKey';
import TrainingsService from '../../services/trainings.service';
import { TraingInProgressView } from './TraingInProgressView';
import { TrainingErrorView } from './TrainingErrorView';
import { TrainingView } from './TrainingView';

export default function TrainingController({ isModal = false }) {
	const { id } = useParams();

	const { data, isFetching, error } = useQuery(
		[QueryKey.training, id],
		() => TrainingsService.getTraining(id),
		{
			placeholderData: TrainingsService.TrainingPlaceholder
		}
	);

	if (error) return <TrainingErrorView isModal={isModal} error={error}></TrainingErrorView>;

	if (data && data.inProgress) return <TraingInProgressView isModal={isModal} text={data.inProgress} displayNumber={data.displayNumber} />;

	return <TrainingView isModal={isModal} data={data} isLoading={isFetching}></TrainingView>;
}


