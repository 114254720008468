import { Image } from 'react-bootstrap';
import { useQuery } from 'react-query';

import TrainingsService from '../../services/trainings.service';
import { Title, SubTitle } from '../pages/Title';
import logoHead from '../../images/gespraech.jpg';
import { ContentDescription } from './';
import Trainings from './trainings';
import { QueryKey } from '../../data/QueryKey';
import { Paragraph } from '../core';
import { useEffect } from 'react';

export function LeadershipPage() {
	const {
		isLoading,
		error,
		data: trainings
	} = useQuery(QueryKey.leadership, TrainingsService.getLeaderships);

	useEffect(() => {
		document.title = 'Leadership.lab | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />

				<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
			</header>

			<main>
				<SubTitle title="Leadership.lab" align="center" />

				<ContentDescription>
					<Paragraph>
						Das Leadership.lab bietet Führungskräften aus dem Bildungsbereich die Möglichkeit zum
						fachlich-professionellen Austausch sowie der persönlichen Weiterentwicklung mit und
						durch interne und externe Fachleute.
					</Paragraph>
				</ContentDescription>

				<div style={{ padding: '1rem' }}>
					<Trainings trainings={trainings} isLoading={isLoading} error={error} />
				</div>
			</main>
		</>
	);
}
