import api from './api';

class ProfileService {
	async getProfile() {
		const { data } = await api.get(`/fortbildungenonlinerest/customerprofile`);
		return data;
	}

	async updateProfile() {
		return await api.post();
	}

	async getSubjects() {
		const { data } = await api.get(`/fortbildungenonlinerest/subjects`);
		return data;
	}

	async getCert(id) {
		const { data } = await api.get(`/pdf/certificate?id=${id}`);
		return data;
	}

	async getOverview(schoolyear) {
		const { data } = await api.get(`/pdf/overviewtrainings?schoolyear=${schoolyear}`);
		return data;
	}

	async getBookmarkoverview() {
		const { data } = await api.get(`/pdf/bookmarkoverview`);
		return data;
	}

	// getCert(id) {
	//     axios(`http://backend.fortbildung-regional.de.test/pdf/certificate?id=${id}`,{
	//         method: 'GET',
	//         responseType: 'blob',//Force to receive data in a Blob Format
	//         withCredentials: true
	//     }).then(response => {
	//     //Create a Blob from the PDF Stream
	//         const file = new Blob(
	//             [response.data],
	//             {type: 'application/pdf'});
	//     //Build a URL from the file
	//         const fileURL = URL.createObjectURL(file);
	//     //Open the URL on new Window
	//         window.open(fileURL);
	//     })
	//     .catch(error => {
	//         console.log(error);
	//     })
	// }

	async requestRegistrationEmail() {
		const { data } = await api.get(`/auth/request-registration-email`);
		return data;
	}
}
export default new ProfileService();
