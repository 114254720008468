import styled from 'styled-components';

export const BookmarkItemHeader = styled.h3`
	font-size: var(--bs-body-font-size);
	font-weight: bold;
	margin-top: 0.18rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: var(--bs-link-color);
`;
