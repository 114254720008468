import React, { useEffect } from 'react';
// import { useState } from "react";
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Image, Accordion } from 'react-bootstrap';
import logoHead from './img/faq.jpg';
import { Title, SubTitle } from './Title';
import { FaqData } from '../../data/faqData';
import { HorizontalDivider, NoneStyledOrderedList } from '../core';

const Faq = () => {
	const { faqId } = useParams();

	// useEffect(() => {
	//     window.scrollTo(0,200+faqId*100)
	// });

	useEffect(() => {
		document.title = 'FAQ | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
			</header>

			<main
				style={{
					paddingLeft: '1rem',
					paddingRight: '1rem',
					marginBottom: '2rem',
					marginTop: '1rem'
				}}
			>
				<SubTitle title="FAQ - Fragen und Antworten" />
				<HorizontalDivider style={{ marginBottom: '2.5rem' }} />
				<Faqs id={faqId}>{FaqData}</Faqs>
			</main>
		</>
	);
};

const Faqs = (props) => {
	// const [id, setId]   = useState(props.id);
	return (
		<>
			<Accordion as={NoneStyledOrderedList}>
				{props.children.map((question) => (
					<Accordion.Item key={question.id} eventKey={question.id} as="li">
						<Accordion.Header as="h3">
							<span style={{ fontSize: '1.3rem' }}>
								{question.id + 1}. {question.title}
							</span>
						</Accordion.Header>
						<Accordion.Body>{parse(question.content)}</Accordion.Body>
					</Accordion.Item>
				))}
			</Accordion>
		</>
	);
};
export default Faq;
