/**
 * Function for is started state
 *
 * @author Janik Hilser
 *
 * Params:
 *  - training: The training object from server
 *
 * Returns: The object of the first event
 */

export function getFirstEvent(training) {
	if (!training?.dates) return;

	const sortedDates = training.dates.sort(
		(eventA, eventB) =>
			new Date(`${eventA.date} ${eventA.timeBegin}`) -
			new Date(`${eventB.date} ${eventB.timeBegin}`)
	);
	return sortedDates[0];
}

/**
 * Function for is started state
 *
 * @author Janik Hilser
 *
 * Params:
 *  - training: The training object from server
 *
 * Returns: A boolean which indicates the started state
 */

export function isStarted(training) {
	const firstEvent = getFirstEvent(training);

	if (firstEvent !== undefined) {
		// console.log(new Date(`${firstEvent.date} ${firstEvent.timeBegin}`).getTime())
		// console.log(Date.now())
		return new Date(`${firstEvent.date} ${firstEvent.timeBegin}`).getTime() < Date.now();
	}
	return 0;
}
