import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import TrainingsService from '../../services/trainings.service';
import { Title, SubTitle } from '../pages/Title';
import Trainings from './trainings';
import { ContentDescription } from './';
import { QueryKey } from '../../data/QueryKey';
import { RegionSelector } from '../domain.core';
import styled from 'styled-components';
import { Paragraph, ResponsiveHorizontalFlex } from '../core';
import { isMobile } from 'react-device-detect';
import HeadLogo from "../core/headLogo";
import myLogo from "../pages/img/regionalConference.png";

const RegionSelectorHeader = styled.h3`
	flex-grow: 0;
	padding-right: 5px;
`;

const StyledRegionSelector = styled(RegionSelector)`
	${isMobile ? 'max-width: 15rem;' : 'max-width: 20rem; width: 20rem;'}
	flex-grow: 1;
`;

export function ConferencesPage() {
	const [regions, setRegions] = useState([]);

	const {
		isLoading,
		error,
		data: trainings
	} = useQuery([QueryKey.conferences, regions], () => TrainingsService.getConferences(regions));

	useEffect(() => {
		document.title = 'Regionalkonferenzen | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<HeadLogo
					logo={myLogo}
					logoText={'Bild ein Konferenz'}
					text="Konferenzen in Regionen"
					colorBackground="#F1EEED"
					color="#a80000"
					height={400}
					posLogo={-40}
					posText={+35}
					heightLogo={150}
					textSize={5}
					fontWeight={900}
				/>
			</header>
			<main>
				<SubTitle title="Regionalkonferenzen" align="center" />
				<ContentDescription>
					<Paragraph>
						Regionalkonferenzen sind für Fachbereichsleitungen, Fachleitungen und
						Fachkonferenzvorsitzende verpflichtend.
					</Paragraph>
					<Paragraph>
						Die Einladungen erreichen Sie über Ihre Schulleitung. Bitte melden Sie sich auch für
						diese Veranstaltungen im Vorfeld an. <br />
						In Ausnahmefällen ist eine nachträgliche Anmeldung (bis 14 Tage nach Termin) möglich.
					</Paragraph>
				</ContentDescription>
				<div style={{ padding: '1rem' }}>
					<ResponsiveHorizontalFlex
						as="section"
						aria-label="Region wählen"
						style={{ paddingBottom: '1rem' }}
					>
						<RegionSelectorHeader>Region:</RegionSelectorHeader>
						<StyledRegionSelector onChange={setRegions} initValues={regions} />
					</ResponsiveHorizontalFlex>
					<Trainings trainings={trainings} isLoading={isLoading} error={error} />
				</div>
			</main>
		</>
	);
}
