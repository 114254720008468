import { useQuery } from 'react-query';
import EnrollmentsService from '../services/enrollments.service';
import useUser from './useUser';

/**
 * Custom Hook for bookmarks.
 *
 * @author Janik Hilser,Lutz Westphal
 *
 *
 * Returns:
 *  - enrollments: The enrollments for the user
 */
export default function useBookmarks() {
	const { user, isLoggedIn } = useUser();

	const { data: bookmarks, isLoading } = useQuery(
		['bookmarks', user?.username],
		EnrollmentsService.getBookmarks,
		{
			enabled: isLoggedIn
		}
	);

	return { bookmarks, isLoading };
}
