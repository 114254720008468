import api from './api';

class EvaluationService {

    async getFormData() {
        const { data } = await api.get(`/evaluation/deliver-form-data`);
        return data;
    }

    async saveFeedback(feedbackData) {
        const { data } = await api.post(`/evaluation/save-answers`, feedbackData);
        return data;
    }

    getCheckDozent = async () => {
        const { data } = await api.get(`/evaluation/check-dozent`);
        return data;
    };

    getFeedbacks = async () => {
        const { data } = await api.get(`/evaluation/summary`);
        return data;
    };

    getRegistrationCounts = async () => {
        const { data } = await api.get(`/evaluation/registration-counts`);
        return data;
    };

    updateEventConference = async (eventId, url, code) => {
        const { data } = await api.patch(`/evaluation/set-event-conference`, {
            eventId,
            url,
            code
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return data;
    };

}

export default new EvaluationService();