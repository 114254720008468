import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { getErrorMessage } from '../../common';
import useNotification from '../../hooks/useNotification';
import AuthService from '../../services/auth.service';
import { LoadingScreen } from '../core';
import { Title } from '../pages/Title';

export function ActivateUser() {
	const navigate = useNavigate();
	const { setInfo } = useNotification();
	const { key } = useParams();

	const activate = useMutation(() => AuthService.activateAsync(key), {
		onSuccess: () => {
			setInfo('Der/die Benutzer/-in wurde registriert.');
			navigate('/login');
		}
	});

	useEffect(() => {
		activate.mutate();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Container className="justify-content-md-center p-3" style={{ maxWidth: '500px' }}>
				<Title title="BENUTZER/-IN AKTIVIEREN" />

				<LoadingScreen isLoading={activate.isLoading}>
					<div style={{ minHeight: '300px' }}>
						{activate.error &&
							'Beim Aktivieren ist der folgende Fehler aufgetreten: ' +
								getErrorMessage(activate.error)}
					</div>
				</LoadingScreen>
			</Container>
		</>
	);
}
