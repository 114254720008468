import ProfileService from '../../../services/profile.service';
import { useMutation, useQuery } from 'react-query';
import ProfileItem from './ProfileItem';
import { QueryKey } from '../../../data/QueryKey';
import useUser from '../../../hooks/useUser';
import useNotification from '../../../hooks/useNotification';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ProfileHeader } from './ProfileHeader';
import { LoadingScreen, Paragraph } from '../../core';
import { Outlet } from 'react-router-dom';

export const Settings = () => {
	const { user } = useUser();

	const { isLoading, data: profile } = useQuery(
		[QueryKey.userProfile, user?.username],
		ProfileService.getProfile
	);

	const { mutate: requestRegistrationEmail } = useMutation(
		ProfileService.requestRegistrationEmail,
		{
			onSuccess: () =>
				setInfo('Es wurde eine E-Mail an ' + profile.email + ' zur Bestätigung gesendet.'),
			onError: () => setError('Some run in trouble')
		}
	);
	const { setInfo, setError } = useNotification();

	return (
		<LoadingScreen isLoading={isLoading}>
			{profile && (
				<>
					<Container fluid>
						<Row as="section" aria-label="Registrierung">
							<Col xs={12} md={8} style={{ marginBottom: '1rem' }}>
								<ProfileHeader>Registrierung</ProfileHeader>
								<Paragraph>{registerStatus[profile.registerStatus]}</Paragraph>
							</Col>
							<Col style={{ marginTop: 'auto', marginBottom: 'auto' }}>
								{profile.registerStatus === 2 || profile.registerStatus === 5 ? (
									<Button
										className="float-begin"
										size="sm"
										aria-label="Neu anfordern"
										variant="outline-success"
										name="send"
										id="send"
										onClick={requestRegistrationEmail}
									>
										Neu anfordern
									</Button>
								) : (
									''
								)}
							</Col>
						</Row>
					</Container>
					<hr />
					<ProfileItem
						key="person"
						title="Persönliche Daten"
						text={name(profile)}
						to={isPublicEmployee(profile) ? '' : 'person'}
						info={
							isPublicEmployee(profile)
								? 'Bei Unstimmigkeit der persönlichen Daten wenden Sie sich bitte an Ihre Schulleitung.'
								: ''
						}
					></ProfileItem>
					<ProfileItem
						key="interests"
						title="Interessen"
						text={subjectList(profile)}
						to="interests"
						info="Wählen Sie wenigstens ein Thema aus, z.B. Ihre Unterrichtsfächer. Mehr als drei Themen sollten nicht gewählt werden. Diese Auswahl entscheidet darüber, welche Angebote Ihnen angezeigt werden."
					></ProfileItem>
					<ProfileItem key="mail" title="E-Mail-Adresse" text={profile.email} to="mail" />
					<ProfileItem
						key="password"
						title="Passwort"
						text="***********"
						to="password"
						hint="Zum Ändern Ihres Passwortes müssen Sie ihr altes Passwort eingeben."
					></ProfileItem>
					<ProfileItem
						key="institution"
						title="Bildungseinrichtung"
						text={
							profile.institution.einrichtungen_name +
							' (' +
							profile.institution.einrichtungen_nummer +
							')'
						}
						to={isPublicEmployee(profile) ? '' : 'institution'}
						info={
							isPublicEmployee(profile)
								? 'Bei Unstimmigkeit der Einrichtung wenden Sie sich bitte an Ihre Schulleitung.'
								: ''
						}
						showButton={profile.registerStatus < 4}
					/>
					<Outlet></Outlet>
				</>
			)}
		</LoadingScreen>
	);
};

const isPublicEmployee = (profile) => {
	return profile.registerStatus < 4;
};

const name = (customerProfile) => {
	let gender = ' ';
	const title = customerProfile.title ? customerProfile.title + ' ' : '';

	switch (customerProfile.gender) {
		case 'm':
			gender = 'Herr ';
			break;
		case 'w':
			gender = 'Frau ';
			break;

		default:
			break;
	}

	return gender + title + customerProfile.firstName + ' ' + customerProfile.lastName + ' (TN-ID: ' + customerProfile.id + ')';
};

const subjectList = (customerProfile) => {
	return customerProfile.subjects === null
		? 'Keine Interessen angegeben, das sollten Sie gleich ändern.'
		: customerProfile.subjects?.map((item) => item.text).join(', ');
};

const registerStatus = {
	1: 'Sie sind als pädagogisches Personal einer öffentlichen Schule vollständig registriert.',
	2: 'Sie sind als pädagogisches Personal einer öffentlichen Schule registriert. Um sich zu Fortbildungen anmelden zu können, bestätigen Sie Ihre dienstliche E-Mail-Adresse (siehe E-Mail). Falls keine E-Mail zu Bestätigung Ihrer Registrierung vorhanden ist, klicken Sie auf "Neu anfordern". ',
	3: 'Sie sind als pädagogisches Personal einer öffentlichen Schule registriert. Um sich zu Fortbildungen anmelden zu können, erhalten Sie in den kommenden Tagen eine E-Mail von der Verwaltung zur Bestätigung der Aktivierung Ihres Accounts.',
	4: 'Sie sind als pädagogisches Personal vollständig registriert. Alle Ihre Anmeldungen zu Fortbildungen werden grundsätzlich zunächst auf die Warteliste gesetzt. Nach Maßgabe freier Plätze werden Zusagen erteilt.',
	5: 'Sie sind als pädagogisches Personal vollständig registriert. Um sich zu Fortbildungen anmelden zu können, bestätigen Sie Ihre dienstliche E-Mail-Adresse (siehe E-Mail). Falls keine E-Mail zur Bestätigung Ihrer Registrierung vorhanden ist, klicken Sie auf "Neu anfordern". ',
	6: 'Sie sind als pädagogisches Personal registriert. Um sich zu Fortbildungen anmelden zu können, erhalten Sie in den kommenden Tagen eine E-Mail von der Verwaltung zur Bestätigung der Aktivierung Ihres Accounts.'
};
