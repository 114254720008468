import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../common';
import { ModalHeader } from '../core';

export function TrainingErrorView({ error, isModal }) {

    const text = 'Beim Laden der Fortbildung ist der folgende Fehler aufgetreten: ' + getErrorMessage(error);

    const navigate = useNavigate();

	const handleHide = () => {
		navigate(-1);
	};

	if (isModal)
		return (
			<Modal
				size="lg"
				show={true}
				scrollable={true}
				onHide={handleHide}
				dialogClassName="modal-98w"
			>
				<Modal.Header closeButton closeLabel="Schließen" closeVariant="white">
					<Modal.Title id="example-custom-modal-styling-title">
						<ModalHeader>Fehler</ModalHeader>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>{text}</Modal.Body>
			</Modal>
		);

	return text;
}
