import React from 'react';
import { Modal } from 'react-bootstrap';
import { BsFillPersonFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

export function RoutingModal({ children, title, size, contentClassName }) {
	const navigate = useNavigate();

	const handleHide = () => {
		navigate(-1, );
	};

	return (
		<>
			<Modal
				show={true}
				scrollable={true}
				onHide={handleHide}
				size={size}
				centered
				contentClassName={contentClassName}
			>
				<Modal.Header closeButton closeLabel="Schließen" closeVariant="white">
					<Modal.Title>
						<BsFillPersonFill /> {title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className={contentClassName}>{children}</Modal.Body>
			</Modal>
		</>
	);
}
