import { useContext } from 'react';
import { error, info, warning } from '../components/core';
import { NotificationsContext } from '../contexts/notifications-context';

/**
 * Custom Hook for notifications.
 *
 * @author Janik Hilser
 *
 * Returns:
 *  - notifications: The list of actual notifications
 *  - setInfo: Sets a new info
 *  - setWarning: Sets a new warning
 *  - setError: Sets a new error
 *  - remove: Removes an notification by createDate
 *  - clear: Clears the list
 */
export default function useNotification() {
	const { notifications, setNotifications } = useContext(NotificationsContext);

	const clear = () => {
		setNotifications([]);
	};

	const remove = (createDate) => {
		setNotifications(notifications.filter((x) => x.createDate !== createDate));
	};

	const removeAfterDelay = (createDate) => {
		setTimeout(() => remove(createDate), 20000);
	};

	const setInfo = (message, id) => {
		const createDate = Date.now();
		setNotifications((existing) => [
			...existing,
			{
				createDate,
				type: info,
				message,
				id
			}
		]);
		removeAfterDelay(createDate);
	};

	const setWarning = (message, id) => {
		const createDate = Date.now();
		setNotifications((existing) => [
			...existing,
			{
				createDate,
				type: warning,
				message,
				id
			}
		]);
		removeAfterDelay(createDate);
	};

	const setError = (message, id) => {
		const createDate = Date.now();
		setNotifications((existing) => [
			...existing,
			{
				createDate,
				type: error,
				message,
				id
			}
		]);
		removeAfterDelay(createDate);
	};

	return { notifications, setInfo, setWarning, setError, remove, clear };
}
