import React from 'react';
import {Accordion} from 'react-bootstrap';
import useFeedback from '../../../hooks/useFeedback';
import useFeedbackQuestions from "../../../hooks/useFeedbackQuestions";
import useRegistrationCounts from "../../../hooks/useRegistrationCounts";
import {LoadingScreen} from '../../core';
import {RenderFeedbackBody, RenderFeedbackHeader} from "./feedbackItems";
import './customAccordionStyle.css';
import BeforeEventData from "./beforeEventData";
import styled from "styled-components";
import StatisticInfo from "./statisticInfo";

const EnrollmentListHeader = styled.h2`
	margin-top: 1em;
	margin-bottom: 0;
	border-left: 2.5mm solid;
	border-color: #0080ff;
	padding-left: 1.5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: white;
	font-size: 110%;
	font-weight: bold;
`;



export const Statistics = () => {
	const {isLoading: loadingFeedback, feedbacks} = useFeedback();
	const {questions} = useFeedbackQuestions();
	const {isLoading: loadingRegistrations, registrations} = useRegistrationCounts();

	let countFeedBacks = true;
	let countRegistrations = true;

	if (loadingFeedback || loadingRegistrations) {
			return (
				<LoadingScreen isLoading={loadingFeedback || !feedbacks}>
					<p style={{ textAlign: 'center', marginTop: '2em', marginBottom: '2em', color: 'darkred', fontSize: '3em' }}>
						Daten werden geladen...
					</p>
				</LoadingScreen>
			);
	}

	let sortedFeedbackKeys = [];
	if (Object.keys(feedbacks).length <= 1) {
		countFeedBacks = false;
	} else {
		// Erstellen einer Kopie der Schlüssel von feedbacks, ohne das erste Element
		const feedbackKeys = Object.keys(feedbacks).slice(1);

		// Sortierung der Schlüssel basierend auf dem Datum
		sortedFeedbackKeys = feedbackKeys.sort((a, b) => {
			const dateA = new Date(feedbacks[a].trainingsData.dates[0].date);
			const dateB = new Date(feedbacks[b].trainingsData.dates[0].date);
			return dateB - dateA; // Für absteigende Sortierung
		});

		// füge Zusammenfassung hinzu
		sortedFeedbackKeys.unshift('0');
	}
	if (Object.keys(registrations).length === 0) {
		countRegistrations = false;
	}

	/**
	 * Darstellung von Informationen und Feedbacks
	 */

	if(countFeedBacks && countRegistrations) {
		return (
			<>
				<StatisticInfo/>

				<EnrollmentListHeader  style={{marginTop: '3rem'}}>Anstehende Fortbildungen</EnrollmentListHeader>
				<BeforeEventData eventsData={registrations} />

				<EnrollmentListHeader style={{marginTop: '3rem'}}>Übersicht der Feedbacks</EnrollmentListHeader>
				<Accordion>
					{sortedFeedbackKeys.map((key, index) => (
						<Accordion.Item eventKey={index} key={key}>
							<Accordion.Header>
								<RenderFeedbackHeader feedbackData={feedbacks[key]} />
							</Accordion.Header>
							<Accordion.Body>
								<RenderFeedbackBody feedbackData={feedbacks[key]} questions={questions} />
							</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>
			</>
		);
	} else if(countFeedBacks) {
		return (
			<>
				<StatisticInfo/>

				<EnrollmentListHeader style={{marginTop: '3rem'}}>Übersicht der Feedbacks</EnrollmentListHeader>
				<Accordion>
					{sortedFeedbackKeys.map((key, index) => (
						<Accordion.Item eventKey={index} key={key}>
							<Accordion.Header>
								<RenderFeedbackHeader feedbackData={feedbacks[key]} />
							</Accordion.Header>
							<Accordion.Body>
								<RenderFeedbackBody feedbackData={feedbacks[key]} questions={questions} />
							</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>
			</>
		);
	} else if(countRegistrations) {
		return (
			<>
				<StatisticInfo/>

				<EnrollmentListHeader  style={{marginTop: '3rem'}}>Anstehende Fortbildungen</EnrollmentListHeader>
				<BeforeEventData eventsData={registrations} />
			</>
		);
	} else {
		return (
			<p style={{textAlign: 'center', marginTop: '2em', marginBottom: '2em'}}>
				Es gibt keine Feedback oder Anmeldungen zu Ihren Fortbildungen!
			</p>
		);
	}
};