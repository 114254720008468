/**
 * Function for http error formatting
 *
 * @author Janik Hilser
 *
 * Params:
 *  - error: The error of the request
 *
 * Returns: The message of the error object
 */

export function getErrorMessage(error) {
	return (
		(error.response && error.response.data && error.response.data.message) ||
		error.message ||
		error.toString()
	);
}
