export const styles = {
    h3: {
        color: 'black',
        fontSize: '1em',
        fontWeight: 'bold',
    },
    h4: {
        color: 'darkred',
        fontSize: '1em',
        textDecoration: 'none',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%', // Volle Breite
        marginBottom: '0rem',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        width: '56%',
    },
    cell: {
        marginBottom: '0.5rem', // Verringert von 0.5rem
        hyphens: 'auto',
        header: {
            color: 'black',
            fontWeight: '600',
        }
    },
    rightAlignedColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '43%',
        alignItems: 'flex-end', // Rechtsbündige Ausrichtung der Inhalte
    },
    // Medienabfragen könnten hier integriert werden
    '@media (max-width: 768px)': {
        row: {
            flexDirection: 'column',
        },
        column: {
            width: '100%', // Auf kleinen Bildschirmen nimmt jede Spalte die volle Breite ein
        },
        rightAlignedColumn: {
            alignItems: 'flex-start', // Vermeiden von Rechtsausrichtung auf kleinen Bildschirmen
        },
    },
};