import { Field } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { firstName, lastName } from '../../common/validations';

export const personalSchema = Yup.object().shape({
	title: Yup.string().nullable(),
	firstName,
	lastName,
	gender: Yup.string().required('Bitte wählen Sie ein Geschlecht aus'),
	disability: Yup.bool()
});

export const Personal = () => {
	return (
		<fieldset>
			<legend className="visually-hidden">Persönliche Daten</legend>
			<Row className="mb-3">
				<Col xs={12} sm={3}>
					<Field name="title">
						{({ field, form, meta }) => (
							<Form.Group className="my-2" controlId={field.name}>
								<Form.Label>
									<strong>Anrede</strong>
								</Form.Label>
								<Form.Select {...field} autoComplete="honorific-prefix">
									<option></option>
									<option>Dr.</option>
									<option>Prof.</option>
									<option>Prof. Dr.</option>
								</Form.Select>
							</Form.Group>
						)}
					</Field>
				</Col>

				<Col xs={12} sm={4}>
					<Field name="firstName">
						{({ field, form, meta }) => (
							<Form.Group className="my-2" controlId={field.name}>
								<Form.Label>
									<strong>Vorname</strong>
								</Form.Label>
								<Form.Control
									{...field}
									required
									autoComplete="given-name"
									type="text"
									placeholder="Vorname"
									isInvalid={!!meta.error}
								></Form.Control>
								<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
							</Form.Group>
						)}
					</Field>
				</Col>

				<Col xs={12} sm={5}>
					<Field name="lastName">
						{({ field, form, meta }) => (
							<Form.Group className="my-2" controlId={field.name}>
								<Form.Label>
									<strong>Nachname</strong>
								</Form.Label>
								<Form.Control
									{...field}
									required
									autoComplete="family-name"
									type="text"
									placeholder="Nachname"
									isInvalid={!!meta.error}
								/>
								<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
							</Form.Group>
						)}
					</Field>
				</Col>
			</Row>

			<Field name="gender">
				{({ field, form, meta }) => (
					<Form.Group className="mb-3" controlId={field.name}>
						<Form.Label>
							<strong>Geschlecht</strong>
						</Form.Label>
						<Form.Select {...field} required autoComplete="sex">
							<option value="w">Weiblich</option>
							<option value="m">Männlich</option>
							<option value="d">Divers</option>
						</Form.Select>
					</Form.Group>
				)}
			</Field>

			<Field name="disability">
				{({ field, form, meta }) => (
					<Form.Group className="mb-3" controlId={field.name}>
						<Form.Check
							{...field}
							defaultChecked={field.value}
							type="switch"
							label={<strong>Schwerbehinderung (freiwillige Angabe)</strong>}
						/>
					</Form.Group>
				)}
			</Field>
		</fieldset>
	);
};
