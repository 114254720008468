import { useState, useEffect } from 'react';

/**
 * Custom Hook for network concerns.
 *
 * @author Janik Hilser
 *
 *
 * Returns:
 *  - isOnline: A state which the online state
 */
export default function useNetwork() {
	const [isOnline, setNetwork] = useState(window.navigator.onLine);

	const updateNetwork = () => {
		setNetwork(window.navigator.onLine);
	};

	useEffect(() => {
		window.addEventListener('offline', updateNetwork);
		window.addEventListener('online', updateNetwork);

		return () => {
			window.removeEventListener('offline', updateNetwork);
			window.removeEventListener('online', updateNetwork);
		};
	});

	return { isOnline };
}
