import { Form } from 'react-bootstrap';
import RegisterService from '../../services/register.service';
import { LayerSelector } from '../core';

export function InstitutionSelector({ title, ariaDescribedBy }) {
	const layers = [
		{
			id: 'institutionDistricts',
			dataFn: RegisterService.getInstitutionDistricts,
			dataKey: () => ['institution-districts'],
			displayFormatterFn: (item) => item.bezeichnung,
			valueFormatterFn: (item) => item.id,
			description: 'Bezirke',
			errorMessage: 'Wählen Sie bitte einen Bezirk aus'
		},
		{
			id: 'institutionTypes',
			dataFn: (selectedValues) => RegisterService.getInstitutionTypes(selectedValues[0]),
			dataKey: (selectedValues) => ['institution-types', selectedValues[0]],
			displayFormatterFn: (item) => item.name,
			valueFormatterFn: (item) => item.id,
			description: 'Schultypen',
			errorMessage: 'Wählen Sie bitte einen Typ aus'
		},
		{
			id: 'institutions',
			dataFn: (selectedValues) =>
				RegisterService.getInstitutions(selectedValues[0], selectedValues[1]),
			dataKey: (selectedValues) => ['institutions', selectedValues[0], selectedValues[1]],
			displayFormatterFn: (item) => item.name + ' (' + item.number + ')',
			valueFormatterFn: (item) => item.id,
			description: 'Einrichtungen',
			errorMessage: 'Wählen Sie bitte eine Einrichtung aus'
		}
	];

	return (
		<>
			<Form.Label htmlFor="institutionSelector">
				<strong>{title}</strong>
			</Form.Label>
			<LayerSelector
				id="institutionSelector"
				layers={layers}
				ariaDescribedBy={ariaDescribedBy}
			></LayerSelector>
		</>
	);
}
