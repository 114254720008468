import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Paragraph } from '../../core';
import { ProfileHeader } from './ProfileHeader';

const ProfileItem = ({ title, text, info, to }) => {
	return (
		<>
			<Container fluid>
				<Row as="section" aria-label={title}>
					<Col xs={12} md={8} style={{ marginBottom: '1rem' }}>
						<ProfileHeader>{title}</ProfileHeader>
						<Paragraph>
							<strong>{text}</strong>
						</Paragraph>
						<Paragraph>{info}</Paragraph>
					</Col>
					{to && (
						<Col style={{ marginTop: 'auto', marginBottom: 'auto' }}>
							<Button
								size="sm"
								as={Link}
								to={to}
								aria-description={title}
								variant="outline-success"
							>
								&nbsp;&nbsp;&nbsp;&nbsp;Ändern&nbsp;&nbsp;&nbsp;&nbsp;
							</Button>
						</Col>
					)}
				</Row>
			</Container>

			<hr />
		</>
	);
};

export default ProfileItem;
