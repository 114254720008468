import React from "react";
import {styles} from "./Styles";
import StarDisplay from "../../evaluation/starDisplay";
import {Badge, Stack} from "react-bootstrap";
import {
    calculateAverage,
    convertToGermanDate,
    activity,
    registrationColor
} from "./statistikTools"
import {Link, useLocation} from "react-router-dom";


export const RenderFeedbackHeader = (props) => {
    const location = useLocation();
    const rating = calculateAverage(JSON.stringify(props.feedbackData.averages));
    const ratingCount = props.feedbackData.countEvaluated;
    const ratingActivity = activity(props.feedbackData.countEvaluated, props.feedbackData.present);
    const registrationActivity = activity(props.feedbackData.trainingsData.registrationCount, props.feedbackData.trainingsData.max);

    // Falls trainingsData ein String ist handelt es sich um die Zusammenfassung
    if (typeof props.feedbackData.trainingsData === 'string') {
        return (
            <div style={styles.row}>
                <div style={styles.column}>
                    <div style={styles.cell.header}>Gesamtfeedback aller Fortbildungen</div>
                    <div style={styles.cell}></div>
                </div>
                <div style={styles.rightAlignedColumn}>
                    <div style={styles.cell}>
                        <StarDisplay
                            value={rating}
                            ratingCount={ratingCount}
                            startOf={4}
                            outOf={4}
                        />
                    </div>
                    <div style={styles.cell}>
                        <Badge
                            bg="dark"
                            title={`Für alle Fortbildungen haben Sie ${props.feedbackData.countEvaluated} Feedbacks von den ${props.feedbackData.present} anwesenden Teilnehmenden erhalten`}
                            aria-label={`Sie haben insgesamt ${props.feedbackData.countEvaluated} Feedbacks von den ${props.feedbackData.present} anwesenden Teilnehmenden erhalten`}
                        >
                            {ratingActivity}
                        </Badge>&nbsp;
                    </div>
                </div>
            </div>
        );
    }
    else if (typeof props.feedbackData.trainingsData === 'object') {
        const { bg, text } = registrationColor(props.feedbackData.trainingsData.registrationCount, props.feedbackData.trainingsData.max);
        return (
            <>
                <div style={styles.row}>
                    <div style={styles.column}>
                        <div style={styles.cell.header}>
                            <Link
                                to={`/training/${props.feedbackData.trainingsData.id}`}
                                state={{backgroundLocation: location}}
                            >
                                Feedback {props.feedbackData.trainingsData.displayNumber}
                            </Link>
                        </div>
                        <div style={styles.cell}>
                            {props.feedbackData.trainingsData.title}<br />
                            {convertToGermanDate(props.feedbackData.trainingsData.dates[0].date)}
                        </div>
                    </div>
                    <div style={styles.rightAlignedColumn}>
                    <div style={styles.cell}>
                            <StarDisplay value={rating} />
                        </div>
                        <div style={styles.cell}>
                            <Stack direction="horizontal" gap={0}>
                                <Badge
                                    bg={bg}
                                    text={text}
                                    style={{marginRight: '0.5em'}}
                                    title={`${props.feedbackData.trainingsData.registrationCount} von maximal ${props.feedbackData.trainingsData.max} Teilnehmenden angemeldet`}
                                    aria-label={`${props.feedbackData.trainingsData.registrationCount} von maximal ${props.feedbackData.trainingsData.max} Teilnehmenden angemeldet`}
                                >
                                    {registrationActivity}
                                </Badge>
                                <Badge
                                    bg={'dark'}
                                    title={`${props.feedbackData.countEvaluated} Feedback von ${props.feedbackData.present} anwesenden Teilnehmenden erhalten`}
                                    aria-label={`${props.feedbackData.countEvaluated} Feedback von ${props.feedbackData.present} anwesenden Teilnehmenden erhalten`}
                                >
                                    {ratingActivity}
                                </Badge>
                            </Stack>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    // Falls trainingsData nicht erkannt wird oder null ist
    else {
        return <p>Trainingsdaten sind nicht verfügbar.</p>;
    }
};

export const RenderFeedbackBody = (props) => {
    return (
        <>
            <h3 style={styles.h3}>
                {(typeof props.feedbackData.trainingsData === 'object') ? 'Fortbildung am ' + convertToGermanDate(props.feedbackData.trainingsData.dates[0].date) : 'Alle Fortbildungen'}
            </h3>
            <div style={{paddingLeft: '0em', paddingRight: '0em'}}>
                {Object.values(props.questions).map(({headline, questions}) => (
                    <div key={headline}>
                        <h4 style={styles.h4}>{headline}</h4> {/* Display the headline */}
                        <ul style={{fontSize: '1em'}}>
                            {questions.map(({id, question}) => (
                                <li key={id}>
                                    <span style={{hyphens: 'auto'}}>
                                        {question}
                                    </span>:<br />
                                    <StarDisplay
                                        value={props.feedbackData.averages[id]}
                                        startOf={4}
                                        outOf={4}
                                        starStyle={{ color: 'black', fontSize: '1.25em', display: 'inline-block' }}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
                <h4 style={styles.h4}>Textrückmeldungen</h4>
                <div style={{paddingLeft: '1em', paddingRight: '0.5em', hyphens: 'auto'}}>
                    {props.feedbackData.text.length === 0 ? 'Keine vorhanden' : props.feedbackData.text}
                </div>
            </div>
        </>
    );
};