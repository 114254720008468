import { Field, Formik } from 'formik';
import { useEffect } from 'react';
import { Button, Container, Stack, Row, Col, Form } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import AuthService from '../../services/auth.service';
import { LoadingScreen } from '../core';
import { Title } from '../pages/Title';
import * as Yup from 'yup';
import { password, passwordConfirm } from '../../common/validations';

export const schema = Yup.object().shape({
	newPassword: password,
	passwordRepetition: passwordConfirm('newPassword')
});

export function UpdatePassword() {
	const navigate = useNavigate();
	const { key } = useParams();

	const updatePassword = useMutation(
		(values) => AuthService.updatePasswordAsync(values.newPassword, key),
		{
			onSuccess: () => navigate('/login')
		}
	);

	useEffect(() => {
		document.title = 'Passwort ändern | Fortbildung Berlin';
	}, []);

	return (
		<>
			<Container as="main" className="justify-content-md-center p-3" style={{ maxWidth: '500px' }}>
				<Title title="PASSWORT ERSTELLEN" />

				{updatePassword.isError ? (
					<p>{updatePassword.error.response.data.message}</p>
				) : (
					<>
						<p>Bitte erstellen Sie ein neues Passwort, welches den Anforderungen entspricht.</p>
						<LoadingScreen isLoading={updatePassword.isLoading}>
							<Formik
								onSubmit={updatePassword.mutate}
								validationSchema={schema}
								initialValues={{ newPassword: '', passwordRepetition: '' }}
								validateOnMount={true}
							>
								{({ handleSubmit, isValid }) => (
									<Form noValidate onSubmit={handleSubmit}>
										<Stack>
											<Field name="newPassword">
												{({ field, form, meta }) => (
													<Form.Group className="mb-3" controlId={field.name}>
														<Form.Label>
															<strong>Passwort</strong>
														</Form.Label>
														<Form.Control
															{...field}
															required
															autoComplete="new-password"
															type="password"
															placeholder="Passwort"
															isInvalid={!!meta.error}
															aria-describedby="passwordHelpBlock"
														/>
														<Form.Control.Feedback type="invalid">
															{meta.error}
														</Form.Control.Feedback>
													</Form.Group>
												)}
											</Field>
											<Field name="passwordRepetition">
												{({ field, form, meta }) => (
													<Form.Group className="mb-3" controlId={field.name}>
														<Form.Label>
															<strong>Passwort wiederholen</strong>
														</Form.Label>
														<Form.Control
															{...field}
															required
															autoComplete="new-password"
															type="password"
															placeholder="Passwort wiederholen"
															isInvalid={!!meta.error}
														/>
														<Form.Control.Feedback type="invalid">
															{meta.error}
														</Form.Control.Feedback>
													</Form.Group>
												)}
											</Field>
											<Form.Text id="passwordHelpBlock" muted>
												Ihr Passwort muss Klein- und Großbuchstaben, Zahlen und Sonderzeichen
												enthalten und mindestens 8 Zeichen lang sein. Erlaubte Sonderzeichen sind
												nur: <strong>! @ # $ % ^ & * </strong>
											</Form.Text>

											<Row className="justify-content-center">
												<Col xs={12} sm="auto">
													<Button
														type="submit"
														disabled={!isValid}
														variant="success ms-auto my-3"
														style={{ width: 'inherit' }}
													>
														Passwort erstellen
													</Button>
												</Col>
											</Row>
										</Stack>
									</Form>
								)}
							</Formik>
						</LoadingScreen>
					</>
				)}
			</Container>
		</>
	);
}
