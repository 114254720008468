import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../common';
import { QueryKey } from '../../../data/QueryKey';
import useNotification from '../../../hooks/useNotification';
import useUser from '../../../hooks/useUser';
import AuthService from '../../../services/auth.service';
import ProfileService from '../../../services/profile.service';
import { LoadingScreen, ModalFooter, RoutingModal } from '../../core';
import { SubjectSelector } from '../../domain.core';

export const Interests = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { setInfo, setError } = useNotification();

	const { isLoading, data: profile } = useQuery(
		[QueryKey.userProfile, user?.username],
		ProfileService.getProfile
	);

	const { mutate: mutateSubjects, isLoading: isMutating } = useMutation(
		(values) => {
			return AuthService.updateUserAsync({ subjects: values.subjects });
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QueryKey.userProfile);
				queryClient.invalidateQueries(QueryKey.home);
				queryClient.invalidateQueries(QueryKey.conferences);
				setInfo(
					'Ab sofort werden Ihnen Fortbildungen zu den von Ihnen gewählten Themen angezeigt. Sie können in der erweiterten Suche "Alle Themen" wählen, um diesen Service temporär zu deaktivieren.'
				);
				navigate(-1);
			},
			onError: (error) => setError(getErrorMessage(error), 'updateInterestsError')
		}
	);

	return (
		<RoutingModal title="Persönliche Daten ändern" contentClassName="overflow-visible">
			<LoadingScreen isLoading={isLoading || isMutating}>
				{profile && (
					<Formik
						onSubmit={mutateSubjects}
						initialValues={{
							subjects: profile.subjects?.map((x) => x.id)
						}}
					>
						{({ handleSubmit, values, handleChange, dirty, isValid }) => (
							<form noValidate onSubmit={handleSubmit} aria-describedby="updateInterestsError">
								<SubjectSelector
									onChange={(subjects) => {
										handleChange({ target: { value: subjects, name: 'subjects' } });
									}}
									initValues={values.subjects}
								></SubjectSelector>

								<ModalFooter submitDisabled={!isValid || !dirty} />
							</form>
						)}
					</Formik>
				)}
			</LoadingScreen>
		</RoutingModal>
	);
};
