import { isStarted } from '../../../common';
import { EnrollmentPossible } from '../../../data/enrollmentPossibleType';
import useEnrollment from '../../../hooks/useEnrollment';

export function useActions(training) {
	const { enrollment, enroll, cancel, reenroll, isLoading } = useEnrollment(training?.id);

	const trainingActions = {
		loading: [
			{
				enabled: false,
				text: 'Laden...',
				type: 'primary'
			}
		],
		enrollmentNotPossible: [
			{
				enabled: false,
				text: 'Keine Anmeldung möglich',
				type: 'primary'
			}
		],
		enrollmentPossible: [
			{
				enabled: true,
				text: 'Anmelden',
				type: 'primary',
				action: () => enroll(false)
			}
		],
		queuePossible: [
			{
				enabled: true,
				text: 'Auf Warteliste setzen',
				type: 'primary',
				action: () => enroll(true)
			}
		],
		cancelledByManagement: [
			{
				enabled: false,
				text: 'Abgesagt',
				type: 'danger'
			}
		],
		cancelledByUserAndEnrollmentNotPossible: [
			{
				enabled: false,
				text: 'Abgemeldet',
				type: 'danger'
			}
		],
		cancelledByUserAndEnrollmentPossible: [
			{
				enabled: false,
				text: 'Abgemeldet',
				type: 'danger'
			},
			{
				enabled: true,
				text: 'Absage zurücknehmen',
				type: 'primary',
				action: () => reenroll(false)
			}
		],
		cancelledByUserAndQueuePossible: [
			{
				enabled: false,
				text: 'Abgemeldet',
				type: 'danger'
			},
			{
				enabled: true,
				text: 'Auf Warteliste setzen',
				type: 'primary',
				action: () => reenroll(true)
			}
		],
		rejected: [
			{
				enabled: false,
				text: 'Abgelehnt',
				type: 'danger'
			}
		],
		waiting: [
			{
				enabled: false,
				text: 'Auf Warteliste',
				type: 'success'
			}
		],
		waitingAndCancellationPossible: [
			{
				enabled: false,
				text: 'Auf Warteliste',
				type: 'success'
			},
			{
				enabled: true,
				text: 'Absagen',
				type: 'danger',
				action: cancel
			}
		],
		enrolled: [
			{
				enabled: false,
				text: 'Angemeldet',
				type: 'success'
			}
		],
		enrolledAndCancellationPossible: [
			{
				enabled: false,
				text: 'Angemeldet',
				type: 'success'
			},
			{
				enabled: true,
				text: 'Absagen',
				type: 'danger',
				action: cancel
			}
		],
		confirmed: [
			{
				enabled: false,
				text: 'Zugesagt',
				type: 'success'
			}
		],
		confirmedAndCancellationPossible: [
			{
				enabled: false,
				text: 'Zugesagt',
				type: 'success'
			},
			{
				enabled: true,
				text: 'Absagen',
				type: 'danger',
				action: cancel
			}
		]
	};

	// Readonly
	if (training?.isReadonly) return;

	// Laden
	if (!training || training.id === 0 || isLoading) return trainingActions[trainingStates.loading];

	// Keine Anmeldung vorhanden
	if (!enrollment) {
		if (training.enrollmentPossible === EnrollmentPossible.yes)
			return trainingActions[trainingStates.enrollmentPossible];
		else if (training.enrollmentPossible === EnrollmentPossible.queue)
			return trainingActions[trainingStates.queuePossible];
		else if (training.enrollmentPossible === EnrollmentPossible.no)
			return trainingActions[trainingStates.enrollmentNotPossible];
	}
	// Bestätigt  (change by Lutz and Olaf)
	else if (enrollment.isConfirmed) {
		if (isStarted(training)) return trainingActions[trainingStates.confirmed];
		else if (enrollment.isCancelledByUser)
			return trainingActions[trainingStates.cancelledByUserAndEnrollmentPossible];
		else return trainingActions[trainingStates.confirmedAndCancellationPossible];
	}

	// Abgesagt von der SenBJF
	else if (enrollment.isCancelledByManagement)
		return trainingActions[trainingStates.isCancelledByManagement];
	// Abgesagt vom Benutzer
	else if (enrollment.isCancelledByUser) {
		if (training.enrollmentPossible === EnrollmentPossible.yes)
			return trainingActions[trainingStates.cancelledByUserAndEnrollmentPossible];
		else if (training.enrollmentPossible === EnrollmentPossible.queue)
			return trainingActions[trainingStates.cancelledByUserAndQueuePossible];
		else if (training.enrollmentPossible === EnrollmentPossible.no)
			return trainingActions[trainingStates.cancelledByUserAndEnrollmentNotPossible];
	}
	// Abgelehnt
	else if (enrollment.isRejected) return trainingActions[trainingStates.rejected];
	// Warteliste
	else if (enrollment.isWaiting) {
		if (isStarted(training)) return trainingActions[trainingStates.waiting];
		else return trainingActions[trainingStates.waitingAndCancellationPossible];
	}

	// Angemeldet (change by Lutz and Olaf)
	else {
		if (isStarted(training)) return trainingActions[trainingStates.enrolled];
		else if (enrollment.isCancelledByUser)
			return trainingActions[trainingStates.cancelledByUserAndEnrollmentPossible];
		else return trainingActions[trainingStates.enrolledAndCancellationPossible];
	}
}

export const trainingStates = {
	loading: 'loading',
	enrollmentNotPossible: 'enrollmentNotPossible',
	enrollmentPossible: 'enrollmentPossible',
	queuePossible: 'queuePossible',
	cancelledByManagement: 'cancelledByManagement',
	cancelledByUserAndEnrollmentNotPossible: 'cancelledByUserAndEnrollmentNotPossible',
	cancelledByUserAndEnrollmentPossible: 'cancelledByUserAndEnrollmentPossible',
	cancelledByUserAndQueuePossible: 'cancelledByUserAndQueuePossible',
	rejected: 'rejected',
	waiting: 'waiting',
	waitingAndCancellationPossible: 'waitingAndCancellationPossible',
	enrolled: 'enrolled',
	enrolledAndCancellationPossible: 'enrolledAndCancellationPossible',
	confirmed: 'confirmed',
	confirmedAndCancellationPossible: 'confirmedAndCancellationPossible'
};
