import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image, Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';
import ProfileService from '../../services/profile.service';
import TrainingsService from '../../services/trainings.service';
import { Title } from '../pages/Title';
import logoHead from '../pages/img/search.jpg';
import logoBerufHead from '../pages/img/searchBeruf.jpg';
import Trainings from './trainings';
import useUser from '../../hooks/useUser';
import { Search } from './search';
import { QueryKey } from '../../data/QueryKey';
import { isMobile } from 'react-device-detect';
import colors from '../../common/colors';
import { HorizontalDivider } from '../core';

const hash = require('object-hash');

export function FindPage() {
	const isBerufsschulenRoute = location.pathname.includes('/berufsschulen');
	const { user } = useUser();
	const [searchText, setSearchText] = useState('');
	const [searchData, setSearchData] = useState(isBerufsschulenRoute ? { regionen_id: [13] } : {});
	const [searchDataHash, setSearchDataHash] = useState(hash(searchData));
	const [title, setTitle] = useState('FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS');
	const [subtitle, setSubtitle] = useState('');

	useEffect(() => {
		document.title = isBerufsschulenRoute ? 'Suche | Fortbildung Berufliche Schulen' : 'Suche | Fortbildung Berlin';
		setTitle(isBerufsschulenRoute ? 'FORTBILDUNGSANGEBOT FÜR BERUFLICHE UND ZENTRAL VERWALTETE SCHULEN' : 'FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS');

		const generateEmail = () => {
			const part1 = 'Petra.Gerlach';
			const part2 = 'senbjf';
			const part3 = 'berlin';
			const part4 = 'de';
			return `${part1}@${part2}.${part3}.${part4}`;
		};

		setSubtitle(isBerufsschulenRoute ? `Das vorliegende Angebot wird verantwortet von IV B RF 23 ${generateEmail()}.` : '');

		const newSearchData = { ...searchData };
		if (isBerufsschulenRoute) {
			newSearchData.regionen_id = [13];
		} else {
			delete newSearchData.regionen_id;
		}
		setSearchData(newSearchData);
		setSearchDataHash(hash(newSearchData));
		// eslint-disable-next-line
	}, [isBerufsschulenRoute]);

	const { isLoading: isLoadingProfile, data: customerProfile } = useQuery(
		[QueryKey.userProfile, user?.username],
		ProfileService.getProfile
	);

	const {
		isFetching: isFetchingTrainings,
		error,
		data: trainings
	} = useQuery([QueryKey.home, searchDataHash], () => TrainingsService.getBySearch(searchData), {
		enabled: !isLoadingProfile && !!searchDataHash,
		onError: (error) => {
			console.log(error);
		}
	});

	const handleSearchDataChanged = ({ searchData }) => {
		if (isBerufsschulenRoute) {
			if (!searchData.regionen_id) {
				searchData.regionen_id = [13];
			} else if (!searchData.regionen_id.includes(13)) {
				searchData.regionen_id.push(13);
			}
		}
		setSearchData(searchData);
		setSearchDataHash(hash(searchData));
	};

	useEffect(() => {
		const trainingsCount = trainings ? Object.keys(trainings).length : 0;

		let profileInterests = '';
		if (!isLoadingProfile && !searchData.alleThemen && customerProfile) {
			profileInterests = customerProfile.subjects
				? `Ihre berücksichtigten Interessen sind: ${customerProfile.subjects.map((item) => item.text).join(', ')}`
				: 'Es wurden keine Interessen im Profil gewählt!';
		}

		let searchTextContent = 'Klicken Sie auf Suche, wählen Sie einen Suchbegriff und/oder benutzen Sie die erweiterte Suche.';

		if (trainingsCount && !isFetchingTrainings) {
			searchTextContent = trainingsCount === 0
				? 'Keine Fortbildungen gefunden, neue Suche?'
				: `Es wurden ${trainingsCount} ${trainingsCount === 1 ? 'Fortbildung' : 'Fortbildungen'} gefunden, neue Suche? ${profileInterests}`;
		}

		setSearchText(searchTextContent);
	}, [trainings, isFetchingTrainings, customerProfile, isLoadingProfile, searchData.alleThemen]);

	return (
		<>
			<header>
				<Title title={title} />
				<Image src={isBerufsschulenRoute ? logoBerufHead : logoHead} alt="Logo im Kopf der Seite" fluid />
				<h2
					style={{
						textAlign: 'center',
						marginTop: '1em',
						color: colors.primarySubHeader,
						fontSize: '1.25em'
					}}
				>
					{subtitle}
				</h2>
			</header>

			<main
				style={{
					paddingLeft: '1rem',
					paddingRight: '1rem',
					marginTop: '2rem',
					marginBottom: '1rem'
				}}
			>
				<Row as="section" aria-label="Suche">
					<Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
						<div style={{ fontSize: '1em', whiteSpace: 'pre-line' }} role="alert">
							{searchText}
							{!!isBerufsschulenRoute && (
								<>
									<br />
									Für die Suche nach Fortbildungen z. B. der allgemeinbildenden Fächer u. v. m. nutzen Sie bitte die <Link to="/find">Hauptsuche</Link>.
								</>
							)}
						</div>
						{!!customerProfile && (
							<Search
								onSearchDataChanged={handleSearchDataChanged}
								startSearch={!!customerProfile?.subjects}
								regionSelection={!isBerufsschulenRoute}
							></Search>
						)}
					</Col>
					{!isMobile ? (
						<Col as="aside" id="search_instruction" xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
							<h3
								style={{
									color: colors.primarySubHeader,
									marginTop: '0rem',
									fontSize: '1.25rem'
								}}
							>
								Suche nutzen:
							</h3>
							<ol>
								<li>
									Klicken Sie auf Suchen und Sie erhalten nur Fortbildungen, die Ihre angegebenen
									Interessen berücksichtigen.
								</li>
								<li>
									Geben Sie Suchbegriffe an und Sie erhalten nur Fortbildungen, die diese
									Suchbegriffe berücksichtigen.
								</li>
								<li>
									Geben Sie Suchbegriffe an <strong>und</strong> schränken Sie die Suche auf Ihre
									Interessen ein, indem Sie in der erweiterten Suche &quot;Persönliche Interessen
									berücksichtigen&quot; auswählen.
								</li>
							</ol>
						</Col>
					) : (
						''
					)}
				</Row>
				<Row>
					<Col>
						<HorizontalDivider style={{ marginBottom: '2em' }} />
						<Trainings trainings={trainings} isLoading={isFetchingTrainings} error={error} />
					</Col>
				</Row>
			</main>
		</>
	);
}