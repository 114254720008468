/**
 * Function for date formatting
 *
 * @author Janik Hilser
 *
 * Params:
 *  - date: A date which will be formatted to the desired string
 *
 * Returns: The formatted date string with our format settings
 */
export function getFormattedDateString(date) {
	const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
	return date.toLocaleDateString('de-DE', options);
}
