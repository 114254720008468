import axiosInstance from './api';
import LocalStorageService from './localStorage.service';
import EventBus, { refreshUser } from '../common/EventBus';
import AuthService from './auth.service';

const setup = () => {
	axiosInstance.interceptors.request.use(
		(config) => {
			const token = LocalStorageService.getLocalAccessToken();
			if (token) {
				config.headers.Authorization = 'Bearer ' + token;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.response.use(
		(res) => {
			return res;
		},
		async (err) => {
			const originalConfig = err.config;
			if (
				originalConfig.url !== '/auth/login' &&
				originalConfig.url !== '/auth/refresh-token' &&
				err.response &&
				LocalStorageService.getUser()
			) {
				// Access Token was expired
				if (err.response.status === 401 && !originalConfig._retry) {
					originalConfig._retry = true;
					try {
						const rs = await axiosInstance.post('/auth/refresh-token');
						const { accessToken } = rs.data;
						LocalStorageService.updateLocalAccessToken(accessToken);
						EventBus.dispatch(refreshUser);
						return axiosInstance(originalConfig);
					} catch (_error) {
						return Promise.reject(_error);
					}
				}
			} else if (originalConfig.url === '/auth/refresh-token' && err.response.status === 401) {
				// Refresh Token was expired
				AuthService.logout();
				EventBus.dispatch(refreshUser);
			}
			return Promise.reject(err);
		}
	);
};
export default setup;
