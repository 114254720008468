import { RoutingModal } from '../core';
import { DefaultLogin } from './defaultLogin';

export function LoginModal() {
	return (
		<RoutingModal title=" Login fortbildungen.berlin">
			<DefaultLogin></DefaultLogin>
		</RoutingModal>
	);
}
