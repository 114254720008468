import Accordion from 'react-bootstrap/Accordion';
import { GiVideoConference } from 'react-icons/gi';
import React from "react";
import styled from "styled-components";
import {Badge, Stack} from "react-bootstrap";
import {activity, registrationColor} from "./statistikTools";
import StarDisplay from "../../evaluation/starDisplay";

const ListItem = styled.li`
	margin-top: 1em;
	margin-bottom: 1em;
`;

const StatisticInfo = () => {

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header><span style={{fontWeight: 'bold'}}>Hinweise zur Übersicht der anstehenden Fortbildungen:</span></Accordion.Header>
                <Accordion.Body style={{hyphen: 'auto'}}>
                    In dieser Sektion finden Sie eine Übersicht der bevorstehenden Fortbildungen.<br />
                    Für jede Fortbildung werden Ihnen folgende Informationen bereitgestellt:
                    <ol>
                        <ListItem>
                            Termin der Fortbildung: <br />
                            Der genaue Zeitpunkt, wann die Fortbildung stattfinden wird.
                        </ListItem>
                        <ListItem>
                            Konferenzlink-Status bei Online- oder Hybrid-Fortbildungen:<br />
                            <GiVideoConference style={{fontSize: '2rem', marginRight: '0.5rem', color: '#dc3545'}}/> : Der Link zu einem Konferenzraum ist noch nicht hinterlegt. Bitte fügen Sie einen Link hinzu. <br />
                            <GiVideoConference style={{fontSize: '2rem', marginRight: '0.5rem', color: 'green'}}/> : Der Link ist bereits angegeben und alles ist bereit für die Teilnahme. <br />
                            Um den Link hinzuzufügen oder zu ändern, nutzen Sie bitte die bereitgestellten Buttons neben dem Status-Icon.
                        </ListItem>
                        <ListItem>
                            <Badge
                                bg="warning"
                                text="dark"
                                title={'Fortbildung ist noch in der Bearbeitung, daher nicht online buchbar!'}
                                aria-label={'Fortbildung ist noch in der Bearbeitung, daher nicht online buchbar!'}
                            >
                                !
                            </Badge> &nbsp;
                            Ein Warnhinweis signalisiert, dass die Fortbildung derzeit von der Verwaltung bearbeitet wird und daher momentan nicht online angezeigt wird.
                        </ListItem>
                        <ListItem>
                            Aktuelle Anzahl der Anmeldungen<br />
                            <Badge
                                bg={registrationColor(4, 20).bg}
                                style={{ marginRight: '0.5em' }}
                                title="4 von maximal 20 Teilnehmenden angemeldet"
                                aria-label="4 von maximal 20 Teilnehmenden angemeldet"
                            >
                                {activity(4, 20)}
                            </Badge>
                            Ein Hinweissymbol zeigt die aktuelle Anzahl der Anmeldungen im Vergleich zur maximalen Teilnehmendenanzahl an. <br />
                            Zum Beispiel haben sich bereits 4 von maximal 20 Teilnehmenden angemeldet.
                        </ListItem>
                    </ol>

                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
                <Accordion.Header><span style={{fontWeight: 'bold'}}>Hinweise zur Übersicht der Feedbacks</span></Accordion.Header>
                <Accordion.Body>
                    In dieser Sektion erhalten Sie eine Übersicht über das Feedback zu Ihren Fortbildungen.
                    <ol>
                        <ListItem>
                            Das Feedback wird mithilfe des weitverbreiteten Sternebewertungssystems angegeben. <br />
                            Zum Beispiel zeigt das Element <StarDisplay value={2.75} startOf={4} outOf={4} /> an, dass die durchschnittliche Bewertung bei 2,75 liegt,
                            Dies entspricht einer Darstellung von 2,5 Sternen. Der exakte Durchschnittswert von 2,75 wird sichtbar, sobald der Mauszeiger kurz über den Sternen verweilt.
                        </ListItem>
                        <ListItem>
                            Das Gesamtfeedback repräsentiert den Durchschnittswert aller Bewertungen zu Ihren Fortbildungen.
                            Um die Sternebewertungen für die einzelnen Aspekte, basierend auf deren Durchschnittswerten,
                            einzusehen, klicken oder tippen Sie bitte auf einen leeren Bereich innerhalb der jeweiligen Feedbacksektion.
                        </ListItem>
                        <ListItem>
                            Für eine statistische Auswertung wird die Anzahl der angemeldeten Teilnehmenden im Verhältnis zur maximalen Teilnehmendenanzahl in einem gelben Badge dargestellt.
                            Zudem wird die Anzahl der abgegebenen Feedbacks im Verhältnis zur Gesamtzahl der teilnehmenden Personen in einem schwarzen Badge angezeigt.<br />
                            <p>Beispiel:
                                <Stack direction="horizontal" gap={0}>
                                    <Badge
                                        bg={registrationColor(27, 20).bg}
                                        title={"27 Feedbacks von 20 Anwesenden erhalten"}
                                        aria-label={"27 Feedback von 20 Anwesenden erhalten"}
                                        style={{marginRight: '0.5em'}}
                                    >
                                        {activity(27, 20)}
                                    </Badge>
                                    <Badge
                                        bg={'dark'}
                                        title={"9 Feedbacks von 15 Anwesenden erhalten"}
                                        aria-label={"9 Feedbacks von 15 Anwesenden erhalten"}
                                    >
                                        {activity(9, 15)}
                                    </Badge>
                                </Stack>
                                <p>
                                    Das erste Badge färbt sich gelb, wenn die Anzahl der Anmeldungen (27) die vorgegebene Maximalkapazität (20) übersteigt. <br />
                                    Ist die Anzahl der Anmeldungen geringer als die maximale Teilnehmendenzahl, wird das Badge grün dargestellt.
                                </p>
                                <p>
                                    Das zweite Badge ist stets schwarz und zeigt das Verhältnis der abgegebenen Feedbacks zur Anzahl der Anwesenden.
                                </p>
                                <p>
                                <strong>Hinweis:</strong> Es kann vorübergehend vorkommen, dass Feedbacks bereits abgegeben werden, bevor die Anwesenheit offiziell durch die Verwaltung bestätigt wurde.
                                    In solchen Fällen könnte die Zahl der abgegebenen Feedbacks vorübergehend die Anzahl der tatsächlich Anwesenden übersteigen.
                                </p>
                            </p>
                        </ListItem>
                    </ol>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default StatisticInfo;