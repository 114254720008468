import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { EnrollmentPossible } from '../../data/enrollmentPossibleType';
import parse from 'html-react-parser';

import TrainingDetailView from './TrainingDetailView';
import { TrainingButtons } from './trainingActions/trainingButtons';
import colors from '../../common/colors';
import { LoadingScreen, ModalHeader, Paragraph } from '../core';

export default function TrainingModal({ data, isLoading }) {
	const navigate = useNavigate();
	const anmeldungenText = data.registrationCount === 1 ? "Anmeldung" : "Anmeldungen";

	const handleHide = () => {
		navigate(-1);
	};

	return (
		<>
			<Modal
				size="lg"
				show={true}
				scrollable={true}
				onHide={handleHide}
				dialogClassName="modal-98w"
			>
				<Modal.Header closeButton closeLabel="Schließen" closeVariant="white">
					<Modal.Title id="example-custom-modal-styling-title">
						<ModalHeader>{data?.title ? parse(data?.title) : ''}</ModalHeader>
						<Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							{data?.displayNumber}
							<span style={{ fontSize: '60%' }}>
								{data.registrationCount > 0 ?  `${data.registrationCount} ${anmeldungenText}` : ''}
							</span>
						</Paragraph>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<LoadingScreen isLoading={isLoading}>
						<TrainingDetailView data={data}></TrainingDetailView>
					</LoadingScreen>
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row>
							{data?.enrollmentPossible === EnrollmentPossible.notActiv && (
								<Col>
									<p style={{ color: colors.errorText }}>
										Die Aktivierung Ihres Accounts seitens der Verwaltung steht noch aus
									</p>
								</Col>
							)}
							<TrainingButtons
								training={data}
								as={Col}
								xs={12}
								sm="auto"
								className="px-1"
							></TrainingButtons>
							<Col></Col>
							<Col xs={12} sm="auto" className="px-1">
								<Button variant="secondary my-1 w-100" onClick={handleHide}>
									Schließen
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	);
}
