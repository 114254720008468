import { useEffect } from "react";

const center = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	textAlign: 'center'
};

export default function Offline() {

	useEffect(() => {
		document.title = 'Fehler | Fortbildung Berlin';
	}, []);

	return (
		<>
			<div style={center}>
				<h2>Sie sind offline.</h2>
				<p>
					Bitte stellen Sie eine funktionierende Internetverbindung her, um Fortbildungen Berlin
					verwenden zu können
				</p>
			</div>
		</>
	);
}
