import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FaArrowUp } from 'react-icons/fa';
import colors from '../../common/colors';

const Button = styled.button`
	background-color: transparent;
	position: fixed;
	bottom: 0;
	right: 0;
	cursor: pointer;
	border: none;
	font-size: 2em;
`;

export function ScrollToTop() {
	const [isVisible, setIsVisible] = useState(false);

	// Top: 0 takes us all the way back to the top of the page
	// Behavior: smooth keeps it smooth!
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	useEffect(() => {
		// Button is displayed after scrolling for 500 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 500) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener('scroll', toggleVisibility);

		return () => window.removeEventListener('scroll', toggleVisibility);
	}, []);

	// scroll-to-top classes: fixed, bottom:0, right:0
	return (
		<Button>
			{isVisible && (
				<div onClick={scrollToTop}>
					<IconContext.Provider
						value={{
							color: colors.secondaryButtonBackground,
							verticalAlign: 'middle'
						}}
					>
						<FaArrowUp alt="Zurück zum Seitenanfang" />
					</IconContext.Provider>
				</div>
			)}
		</Button>
	);
}
