import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import colors from '../../common/colors';
import { EnrollmentPossible } from '../../data/enrollmentPossibleType';
import { LoadingScreen } from '../core';

import { SubTitle, Title } from '../pages/Title';
import { TrainingButtons } from './trainingActions/trainingButtons';
import TrainingDetailView from './TrainingDetailView';

export default function TrainingPage({ data, isLoading }) {
	useEffect(() => {
		document.title = data?.title + ' | Fortbildung Berlin';
	}, [data]);

	const title = data?.title + ' / ' + data?.displayNumber;

	return (
		<Container className="justify-content-md-center p-3">
			<Title title="FORTBILDUNG" />
			<SubTitle title={title}></SubTitle>
			<LoadingScreen isLoading={isLoading}>
					<TrainingDetailView data={data}></TrainingDetailView>
					{data?.enrollmentPossible === EnrollmentPossible.notActiv && (
						<Row>
							<p style={{ color: colors.errorText }}>
								Die Aktivierung seitens der Verwaltung steht noch aus
							</p>
						</Row>
					)}
					<Row className="mx-0">
						<TrainingButtons
							training={data}
							as={Col}
							xs={12}
							sm="auto"
							className="px-1"
						></TrainingButtons>
					</Row>
				</LoadingScreen>
		</Container>
	);
}
