import { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import { Title } from '../pages/Title';
import { DefaultLogin } from './defaultLogin';

export function LoginPage() {
	
	useEffect(() => {
		document.title = 'Login | Fortbildung Berlin';
	}, []);
	
	return (
		<>
			<Container className="justify-content-md-center p-3" style={{ maxWidth: '500px' }}>
				<Title title="LOGIN" />
				<DefaultLogin />
			</Container>
		</>
	);
}
