import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import parse from 'html-react-parser';
import { Image, Accordion } from 'react-bootstrap';
import TrainingsService from '../../services/trainings.service';
import logoHead from './img/contact.jpg';
import { Title, SubTitle } from './Title';
import { HorizontalDivider, NoneStyledUnorderedList } from '../core';

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	
	useEffect(() => {
		document.title = 'Ansprechpartner/-innen | Fortbildung Berlin';
	}, []);
	
	const { data: contactPersons, isLoading: loadingContactPersons } = useQuery(
		'contactPersons',
		TrainingsService.getContactPersons
	);
	let district = 0;

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
			</header>

			<main
				style={{
					paddingLeft: '1rem',
					paddingRight: '1rem',
					marginBottom: '1rem',
					marginTop: '1rem'
				}}
			>
				<SubTitle title="Ansprechpartner/-innen der Regionen" />
				<HorizontalDivider style={{ marginBottom: '2.5rem' }} />
				<Accordion as={NoneStyledUnorderedList}>
					{!loadingContactPersons &&
						contactPersons.map((region) => (
							<Accordion.Item key={district++} eventKey={district++} as="li">
								<Accordion.Header>
									<span style={{ fontSize: '1.3rem' }}>{region.district}</span>
								</Accordion.Header>
								<Accordion.Body>{parse(region.content)}</Accordion.Body>
							</Accordion.Item>
						))}
				</Accordion>
			</main>
		</>
	);
};
export default Contact;
